import { Component, OnInit, ViewChild } from '@angular/core';
import { of, Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { AppPlugin, AppPluginConfig, CmnIfPermDirective, DeleteDialogComponent, PluginConfigService, PluginService } from "hcl-lib";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { mergeMap } from "rxjs/operators";import {
  PluginConfigDetails,
  PluginConfigDetailsDialogComponent
} from "../plugin-config-update-dialog/plugin-config-details-dialog.component";
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-plugin-configs',
    templateUrl: './plugin-configs.component.html',
    styleUrls: ['./plugin-configs.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, MatToolbarModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, TranslateModule, CmnIfPermDirective]
})
export class PluginConfigsComponent implements OnInit {

  subscriptions: Subscription = new Subscription()

  pluginConfigDataSource = new MatTableDataSource<{
    pluginName: string
    config: AppPluginConfig
  }>()
  columnsToDisplay = ['plugin', 'actions']

  plugins: AppPlugin[] = []

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  constructor(
    private pluginService: PluginService,
    private pluginConfigService: PluginConfigService,
    private translateService: TranslateService,
    private matDialog: MatDialog
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.pluginConfigDataSource.sort = this.sort
    this.subscriptions.add(
      this.pluginService.getPlugins().subscribe(plugins => {
        this.plugins = plugins
        this.retrievePluginConfigs()
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrievePluginConfigs(): void {
    this.subscriptions.add(
      this.pluginConfigService.getPluginConfigs(
        this.paginator.pageIndex, this.paginator.pageSize
      ).subscribe(paginatedData => {
        this.paginator.length = paginatedData.count
        this.pluginConfigDataSource.data = paginatedData.data.map(pluginConfig => { return {
          pluginName: this.getPluginNames([pluginConfig.pluginId as string]),
          config: pluginConfig
        }})
      })
    )
  }

  getPluginNames(pluginIds: string[]): string {
    return pluginIds.filter(pluginId => this.plugins.some(plugin => plugin.id == pluginId))
      .map(pluginId => this.plugins.find(plugin => plugin.id == pluginId)?.name)
      .map(pluginName => this.translateService.instant(`PLUGINS.${pluginName?.toUpperCase()}.NAME`))
      .join(", ")
  }

  handlePage() {
    this.retrievePluginConfigs()
  }

  doDelete(pluginConfig: AppPluginConfig) {
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("PLUGIN.CONFIG.DELETE.TITLE"),
      content: this.translateService.instant("PLUGIN.CONFIG.DELETE.CONTENT"),
    }
    config.panelClass = "humecloud-dialog-container"
    const configDeleteDialogRef = this.matDialog.open(DeleteDialogComponent, config)
    this.subscriptions.add(
      configDeleteDialogRef.afterClosed().pipe(
        mergeMap(value => {
          if (value) {
            return this.pluginConfigService.deletePluginConfig(pluginConfig.id as string)
          }
          return of(true)
        })
      ).subscribe(_ => {
        this.retrievePluginConfigs()
      })
    )
  }

  doCreate(): void {
    const createDialogRef = this.matDialog.open(PluginConfigDetailsDialogComponent,
      { data: {
          create: true,
          config : {
            config : { }
          }
        } as PluginConfigDetails
      }
    )
    this.subscriptions.add(
      createDialogRef.afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }

  doUpdate(pluginConfig: AppPluginConfig) {
    const updateDialogRef = this.matDialog.open(PluginConfigDetailsDialogComponent,
      { data: {
          create: false,
          config : pluginConfig
        } as PluginConfigDetails
      }
    )
    this.subscriptions.add(
      updateDialogRef.afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }
}
