import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { CollectionUtil, SimpleAppUtil } from 'hcl-lib';
import { Subscription } from "rxjs";

@Directive({
    selector: '[cmnIfSimpleApp]',
    standalone: true
})
export class CmnIfSimpleAppDirective implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  private hasView = false;
  private appNames: Set<string> = new Set<string>();
  private evaluatedOnce = false

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private simpleAppUtil: SimpleAppUtil
  ) {
    // do nothing
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  set cmnIfSimpleApp(newAppNames: string[]) {
    let newAppNamesSet = new Set<string>();
    if (newAppNames && newAppNames.length > 0) {
      for (let newAppName of newAppNames) {
        newAppNamesSet.add(newAppName);
      }
    }

    if (this.evaluatedOnce && CollectionUtil.setsEqual(newAppNamesSet, this.appNames)) {
      return;
    }
    this.evaluatedOnce = true
    this.appNames = newAppNamesSet;
    this.updateVisiblity();
  }

  private updateVisiblity() {
    let appNames = this.appNames;
    let simpleAppName = this.simpleAppUtil.getSimpleAppName()
    let showElt = (simpleAppName == null) || appNames.has(simpleAppName)
    if (showElt && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!showElt && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
