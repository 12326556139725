<h1 mat-dialog-title>{{ 'DEVICE.DEVICE_ACTION.CREATE' | translate }}</h1>
<form [formGroup]="deviceActionForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="device-action-dialog__content">
    <mat-form-field>
      <mat-label>{{ 'DEVICE.DEVICE_ACTION.TYPE.LABEL' | translate }}</mat-label>
      <mat-select formControlName="type" (valueChange)="selectType($event)">
        <mat-option *ngFor="let type of DeviceActionTypes | enumToArray" [value]="getDeviceActionType(type)">
          {{ 'DEVICE.DEVICE_ACTION.TYPE.' + type | uppercase | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container [ngSwitch]="deviceActionForm.value.type">
      <ng-container *ngSwitchDefault>
        <!-- nothing to do -->
      </ng-container>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
    <button mat-raised-button color="accent" [disabled]="!deviceActionForm.valid" type="submit">
      <mat-icon>done</mat-icon>
      {{ 'VALIDATE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
