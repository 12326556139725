<h1 mat-dialog-title>{{ "DEVICE.MIGRATE.TITLE" | translate }}</h1>
<mat-dialog-content>
  <div>
    <span>{{ "DEVICE.MIGRATE.CURRENT_DEVICE" | translate }}</span>
    <span class="device-migrate-dialog__desc-value"><b>{{ device.name }}</b></span>
  </div>
  <div *ngIf="currentCustomer">
    <span>{{ "DEVICE.MIGRATE.CURRENT_CUSTOMER" | translate }}</span>
    <span class="device-migrate-dialog__desc-value"><b>{{ currentCustomer.name }}<span
          *ngIf="currentCustomer.displayName"> / {{
          currentCustomer.displayName }}</span></b></span>
  </div>
  <form [formGroup]="deviceMigrateForm">
    <app-customer-recent-search-select class="device-migrate-dialog__customer-recent-search-select"
      [placeholder]="'DEVICE.MIGRATE.SELECT_CUSTOMER_PLACEHOLDER' | translate"
      (selectedCustomerChange)="onSelectedCustomer($event)">
    </app-customer-recent-search-select>
  </form>
  <div class="device-migrate-dialog__warning">{{ "DEVICE.MIGRATE.WARNING.CONTENT" | translate }}
    <ul>
      <li>{{ "DEVICE.MIGRATE.WARNING.SCHEDULE" | translate }}</li>
      <li>{{ "DEVICE.MIGRATE.WARNING.TAGS" | translate }}</li>
      <li>{{ "DEVICE.MIGRATE.WARNING.SVD" | translate }}</li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button">
    {{ "CANCEL" | translate }}
  </button>
  <button mat-raised-button color="primary" [disabled]="!deviceMigrateForm.valid" (click)="confirm()">
    {{ "VALIDATE" | translate }}
  </button>
</mat-dialog-actions>