import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { InvocationResult } from 'hcl-lib'
import { environment } from 'projects/hcl-portal/src/environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { EmailDto, SentEmailDto } from "../../interfaces/dto/email-dto"
import { Email } from "../../interfaces/email"

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private httpClient: HttpClient
  ) {
    // do nothing
  }

  sendEmail(email: Email): Observable<SentEmailDto> {
    const emailDto = email as EmailDto
    return this.httpClient.post(environment.cmnEmailApiBaseUrl + '/emails', emailDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
