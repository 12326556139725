import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Router } from "@angular/router";

export interface HclRequestMessage {
  id: string
  action: string
  data: any
  source?: any
}

export interface HclResponseMessage {
  id: string
  action: string
  data: any
}

export interface HclMicroFrontendStatusMessage {
  sendResponse: boolean
  dest: any
  response: HclResponseMessage
  responseSent: boolean
  actionError: boolean
  actionErrorMsg?: string
}

@Injectable({
  providedIn: 'root'
})
export class MicroFrontendService {

  constructor(private router: Router) {
    // do nothing
  }

  getMicrofrontendApiPipeline(): Observable<HclMicroFrontendStatusMessage> {
    return fromEvent<MessageEvent>(window,"message").pipe(
      filter(msg => msg.data),
      map(msg => {
        const msgWithSrc = msg.data
        msgWithSrc.source = msg.source
        return msgWithSrc
      }),
      filter(msg => msg.action && !!msg.id),
      map(msg => {
        const responseAction: HclMicroFrontendStatusMessage = {
          sendResponse: false,
          dest: msg.source,
          response: {
            id: msg.id,
            action: msg.action,
            data: {}
          },
          responseSent: false,
          actionError: false
        }

        try {
          if (msg.action == "navigateTo") {
            this.router.navigateByUrl(msg.data.url)
          }
        } catch (exception) {
          console.error("Unable to perform " + msg.action + " action response for iframe message "
            + msg.id, exception);
          responseAction.actionError = true
          responseAction.actionErrorMsg = exception as string
        }

        return responseAction
      }),
      tap(responseAction => {
        if (responseAction.sendResponse) {
          try {
            responseAction.dest.postMessage(responseAction.response, "*");
            responseAction.responseSent = true
          } catch (exception) {
            console.error("Unable to send response for iframe message " + responseAction.response.id, exception);
          }
        }
        return responseAction
      }))
  }
}
