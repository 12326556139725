<div class="event-list">
  <div class="event-list__header">
    <span class="event-list__header__title">
      {{ "EVENT_LIST.TITLE" | translate }}
    </span>
    <span class="fill-remaining-space"></span>
  </div>
  <div class="event-list__body">
    <div class="event-list__body__sidebar">
      <div class="event-list__body__sidebar_buttons">
        <button class="event-list__body__sidebar_buttons_add" mat-raised-button routerLinkActive="router-link-active"
          [routerLink]="['/event-user-preference']">
          {{ "EVENT.ACTIONS" | translate }}
        </button>
      </div>
      <div class="event-list__body__sidebar">
        <form class="event-list__body__sidebar__search" [formGroup]="eventSearch" (change)="filterChange($event)">
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>{{ "SEARCH" | translate }}</mat-label>
            <input matInput formControlName="eventName">
          </mat-form-field>
          <div class="form-content">
            <label>{{ "EVENT_LIST.FILTER.SERVICE" | translate }}</label>
            <searchable-select formControlName="service" [placeholder]="'EVENT_LIST.FILTER.SERVICE' | translate"
              (selectionChanged)="filterChange($event)" [multipleSelect]="true" [showSelectArrow]="true"
              [stickyPlaceholder]="false">
            </searchable-select>
          </div>
          <div class="form-content">
            <label>{{ 'EVENT.QUERY_SCOPE.HEADER' | translate }}</label>
            <mat-select [(value)]="selectedScope" (selectionChange)="onQueryScopeChange($event)">
              <mat-option *ngFor="let scope of QueryScopes" [value]="scope">
                {{ ("EVENT.QUERY_SCOPE." + scope) | translate }}
              </mat-option>
            </mat-select>
          </div>
        </form>
      </div>
    </div>
    <mat-table [dataSource]="eventDataSource" matSort class="event-list__body__table humecloud-list">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.NAME" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.label }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="eventKey">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.TYPE" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.eventKey }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.CUSTOMER_NAME" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.customerName$ | async }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="severity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.SEVERITY.LABEL" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">
          <span [class]="'dot dot-' + event.severity">
            {{ "EVENT.SEVERITY." + event.severity | translate }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="service">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.SERVICE" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.service }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "EVENT.AT" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let event">{{ event.at | date : "medium" }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let event">
          <button mat-button color="primary" (click)="onRead(event)">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let event; columns: columnsToDisplay" [hidden]="isLoadingResults"></mat-row>
    </mat-table>
    <div *ngIf="isLoadingResults" class="event-list__body__spinner">
      <mat-spinner color="primary" mode="indeterminate" [diameter]="50">
      </mat-spinner>
    </div>
  </div>
  <footer>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="retrieveEvents()"></mat-paginator>
  </footer>
</div>