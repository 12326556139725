import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { HumecloudState } from './index';

@Injectable()
export class ConfigActions {
  static CONFIG_GET = '[Config] Get';
  static CONFIG_UPDATE = '[Config] Update';

  constructor(
    private store: Store<HumecloudState>
  ) {
    // do nothing
  }

  getConfig() {
    this.store.dispatch(getConfig({}));
  }

  updateConfig(config: any): void {
    this.store.dispatch(updateConfig({
      payload: config
    }));
  }
}

export const getConfig = createAction(
  ConfigActions.CONFIG_GET,
  props<{ payload?: any; }>()
);
export const updateConfig = createAction(
  ConfigActions.CONFIG_UPDATE,
  props<{ payload: any; }>()
);
