import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HclLibModule } from 'hcl-lib';
import { HumecloudCommonModule } from '../common/humecloud-common.module';

@NgModule({
  imports: [
    HumecloudCommonModule,
    HclLibModule,
    CommonModule,
    RouterModule,
  ],
  exports: []
})
export class HumecanvaModule {
  constructor() {
    // do nothing
  }
}