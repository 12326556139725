import { Component } from "@angular/core";
import { MediaListWidgetType } from "../../../Layout/Components/widget/media-list/media-list-widget-type"
import { MatTabsModule } from "@angular/material/tabs";
import { MediaListWidget } from "../../../Layout/Components/widget/media-list/media-list-widget.component";
import { CdkDropListGroup } from "@angular/cdk/drag-drop";
import { TitleCasePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { CmnIfPermDirective } from "hcl-lib";

@Component({
    selector: 'app-media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.scss'],
    standalone: true,
    imports: [TitleCasePipe, MatTabsModule, CdkDropListGroup, MediaListWidget, TranslateModule, CmnIfPermDirective]
})
export class MediaListComponent {

  readonly MediaListWidgetType = MediaListWidgetType

  constructor() {
    // do nothing
  }
}
