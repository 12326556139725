import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KeycloakService, ScopeService, MicroFrontendService } from 'hcl-lib';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterModule, NgIf]
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'Common - Portail';

  scopeSet = false
  userApiIdSet = false
  subscription: Subscription = new Subscription()

  constructor(
    private scopeService: ScopeService,
    private keycloakService: KeycloakService,
    private microfrontendService: MicroFrontendService
  ) { }

  ngOnInit(): void {
    this.userApiIdSet = this.keycloakService.getApiUserId() != undefined
    this.initializeScope()
    this.initMicrofrontends()
  }

  initializeScope() {
    this.subscription.add(this.scopeService.getScopeObservable().pipe(
      takeWhile(_ => this.scopeSet == false)
    ).subscribe(scopeValue => {
      if (scopeValue) {
        this.scopeSet = true
      }
    }))
    this.scopeService.initializeScopeService()
  }

  initMicrofrontends() {
    this.subscription.add(this.microfrontendService.getMicrofrontendApiPipeline().subscribe())
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
