import { createReducer, on } from "@ngrx/store";
import { updateScope } from './scope.actions';

const INITIAL_STATE = {
  customerId: null,
  customer: null,
  customers: [],
}

const _scopeReducer = createReducer(
  INITIAL_STATE,
  on(updateScope, (state, { scope })  => Object.assign({}, state, { customerId : scope }))
);

export function scopeReducer(state: any, action: any) {
  return _scopeReducer(state, action);
}
