import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DeviceActionService } from "../../../../services/device-action/device-action.service";
import { ClearDataDeviceAction, DeviceAction, DeviceActionType, SendLogsDeviceAction, TunnelDeviceAction } from "../../../../interfaces/device-action";
import { NgFor, NgIf, NgSwitch, NgSwitchDefault, UpperCasePipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { EnumToArrayPipe } from "hcl-lib";

@Component({
    selector: 'app-device-action-dialog',
    templateUrl: './device-action-dialog.component.html',
    styleUrls: ['./device-action-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgSwitch, NgSwitchDefault, UpperCasePipe, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatIconModule, MatDialogModule, TranslateModule, EnumToArrayPipe]
})
export class DeviceActionDialogComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  deviceActionForm!: UntypedFormGroup
  currentActionType!: string

  DeviceActionTypes = DeviceActionType

  constructor(
    public dialogRef: MatDialogRef<DeviceActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deviceActionService: DeviceActionService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.deviceActionForm = this.formBuilder.group({
      deviceId: [this.data.deviceId],
    })
    this.resetType()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onSubmit() {
    let action: DeviceAction = this.deviceActionForm.value
    this.subscription.add(
      this.deviceActionService.createDeviceAction(action).subscribe(_ => {
        this.dialogRef.close()
      })
    )
  }

  resetType(): void {
    switch (this.currentActionType) {
      default: {
        // nothing to do
        break
      }
    }
    this.deviceActionForm.removeControl('type')
    this.deviceActionForm.addControl('type', new UntypedFormControl(null, Validators.required))
  }

  initForm(action: DeviceAction): void {
    this.resetType()
    switch (action.type) {
      default: {
        // nothing to do
        break
      }
    }
    this.currentActionType = action.type
    this.deviceActionForm.patchValue(action)
  }

  selectType(type: string): void {
    switch (type) {
      case DeviceActionType.CLEAR_DATA: {
        this.initForm(new ClearDataDeviceAction())
        break
      }
      case DeviceActionType.SEND_LOGS: {
        this.initForm(new SendLogsDeviceAction())
        break
      }
      case DeviceActionType.TUNNEL: {
        this.initForm(new TunnelDeviceAction())
        break
      }
      default: {
        // not supported
        break
      }
    }
  }

  getDeviceActionType(type: string): DeviceActionType {
    return this.DeviceActionTypes[type as keyof typeof DeviceActionType]
  }
}
