import {Component} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'password-create',
    templateUrl: './password-create.component.html',
    styleUrls: ['./password-create.component.scss'],
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, TranslateModule]
})
export class PasswordCreateComponent {

  password = "";
  confirmPassword = "";
  generateRandomPassword = false;

  isPasswordAcceptable() {
    return this.password.length > 0 && this.password === this.confirmPassword;
  }

  isRandomGeneratedPassword() {
    return this.generateRandomPassword
  }

  getPassword() {
    return this.password;
  }
}
