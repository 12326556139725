import { Injectable } from "@angular/core"
import {HttpShowError, InvocationResult, KeycloakService} from "hcl-lib"
import {Observable} from "rxjs"
import {HttpClient, HttpParams} from "@angular/common/http"
import {environment} from "../../../../environments/environment"
import {map} from "rxjs/operators"
import {NotificationRecipient} from "../../interfaces/notification-recipient"

@Injectable({
  providedIn: "root"
})
export class NotificationRecipientsService {
  constructor(
    private httpClient: HttpClient,
    private keycloakService: KeycloakService
  ) { }

  @HttpShowError()
  getUnreadNotificationsWithPaging(
    page: number,
    perPage: number,
  ): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())
    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/notification-recipients/unread/${this.keycloakService.getApiUserId()}`,
      { params: queryParams }
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getUnreadNotifications(
    eventId: string
  ): Observable<NotificationRecipient> {
    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/notification-recipients/unread/${this.keycloakService.getApiUserId()}/${eventId}`,
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult)?.data
      })
    )
  }

  @HttpShowError()
  readNotification(eventId: string): Observable<InvocationResult> {
    return this.httpClient.put(
      `${environment.cmnEventApiBaseUrl}/notification-recipients/read/${eventId}/${this.keycloakService.getApiUserId()}`, {}
    ).pipe(
      map((res: any) => {
        return res as InvocationResult
      })
    )
  }
}
