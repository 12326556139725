import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Device } from '../../../../interfaces/device';
import { DeviceStatus } from '../../../../interfaces/device-status';
import { DeviceService } from '../../../../services/device/device.service';
import { DecimalPipe, NgClass, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-device-content-status',
    templateUrl: './device-content-status.component.html',
    styleUrls: ['./device-content-status.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, DecimalPipe, MatTooltipModule, TranslateModule]
})
export class DeviceContentStatusComponent implements OnInit, OnDestroy {

  @Input() device!: Device

  subscriptions: Subscription = new Subscription()

  status!: DeviceStatus
  nbAssets!: number
  nbDownloadedAssets!: number

  constructor(
    private deviceService: DeviceService
  ) { }

  ngOnInit(): void {
    this.retrieveDeviceStatus()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDeviceStatus(): void {
    this.subscriptions.add(
      this.deviceService.getDeviceStatus(this.device.id).subscribe(status => {
        this.status = status
        this.nbAssets = this.status.assets.length
        this.nbDownloadedAssets = this.status.assets.filter(asset => asset.downloaded).length
      })
    )
  }
}
