<form class="device-kiosk" [formGroup]="kioskForm">

  <!-- kiosk application -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.APPLICATION" | translate }}</mat-label>
    <mat-select formControlName="application" matNativeControl required
      (selectionChange)="onDeviceKioskApplicationChanged($event)">
      <ng-container *ngFor="let application of KioskApplication | enumToArray">
        <ng-container *ngIf="application != KioskApplication.CUSTOM">
          <mat-option [value]="application" *cmnIfApp="[application.toLowerCase()]">
            {{ "DEVICE.KIOSK.APPLICATION." + application | translate }}
          </mat-option>
        </ng-container>
      </ng-container>
      <mat-option [value]="KioskApplication.CUSTOM">
        {{ "DEVICE.KIOSK.APPLICATION." + KioskApplication.CUSTOM | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- name -->
  <div class="device-kiosk_url">
    <mat-form-field>
      <mat-label>{{ "DEVICE_DETAILS.KIOSK.URL" | translate }}</mat-label>
      <input matInput formControlName="url" placeholder="Url" required>
    </mat-form-field>
    <mat-icon class="device-kiosk_url_copy-icon" (click)="copyUrlToClipboard()" #copiedTooltip="matTooltip"
      [matTooltip]="'DEVICE_DETAILS.KIOSK.ACTIONS.COPIED' | translate" matTooltipPosition="after"
      matTooltipDisabled="true">
      content_copy
    </mat-icon>
  </div>

  <!-- whitelist -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.WHITELIST" | translate }}</mat-label>
    <textarea matInput formControlName="whitelist" placeholder="Whitelist"></textarea>
  </mat-form-field>

  <!-- whitelistUrl -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.WHITELIST_URL" | translate }}</mat-label>
    <input matInput formControlName="whitelistUrl" placeholder="whitelistUrl">
  </mat-form-field>

  <!-- homeTime -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.HOME_TIME" | translate }}</mat-label>
    <input matInput type="number" formControlName="homeTime" placeholder="Temps avant retour à l'accueil" required>
  </mat-form-field>

  <!-- idleTime -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.IDLE_TIME" | translate }}</mat-label>
    <input matInput type="number" formControlName="idleTime" placeholder="Temps avant mise en veille" required>
  </mat-form-field>

  <!-- backgroundColor -->
  <div class="device-kiosk__color-picker">
    <input type="color" formControlName="backgroundColor" />
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.BACKGROUND_COLOR" | translate }}</mat-label>
  </div>

  <!-- foregroundColor -->
  <div class="device-kiosk__color-picker">
    <input type="color" formControlName="foregroundColor" />
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.FOREGROUND_COLOR" | translate }}</mat-label>
  </div>

  <!-- screenlabEnabled -->
  <mat-checkbox formControlName="screenlabEnabled">
    {{ "DEVICE_DETAILS.KIOSK.SCREENLAB_ENABLED" | translate }}
  </mat-checkbox>

  <!-- cacheFlushEnabled -->
  <mat-checkbox formControlName="cacheFlushEnabled">
    {{ "DEVICE_DETAILS.KIOSK.CACHE_FLUSH_ENABLED" | translate }}
  </mat-checkbox>

  <!-- virtualKeyboardEnabled -->
  <mat-checkbox formControlName="virtualKeyboardEnabled">
    {{ "DEVICE_DETAILS.KIOSK.VIRTUAL_KEYBOARD_ENABLED" | translate }}
  </mat-checkbox>

  <!-- rotationEnabled -->
  <mat-checkbox formControlName="rotationEnabled">
    {{ "DEVICE_DETAILS.KIOSK.ROTATION_ENABLED" | translate }} <!-- TODO -->
  </mat-checkbox>

  <!-- showNavigation -->
  <mat-checkbox formControlName="showNavigation">
    {{ "DEVICE_DETAILS.KIOSK.SHOW_NAVIGATION" | translate }}
  </mat-checkbox>

  <!-- navigationBarPosition -->
  <mat-form-field>
    <mat-label>{{ "DEVICE_DETAILS.KIOSK.NAVIGATION_BAR_POSITION" | translate }}</mat-label>
    <mat-select formControlName="navigationBarPosition" matNativeControl required>
      <mat-option *ngFor="let navigationBarPosition of navigationBarPositionKeys" [value]="navigationBarPosition">
        {{ "DEVICE.KIOSK.NAVIGATION_BAR_POSITION." + navigationBarPosition | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>