<div class="location">

  <div class="hcl-header">
    <span class="hcl-header-title">{{ 'LOCATION.LIST.TITLE' | translate }}</span>
  </div>

  <div class="hcl-body">
    <div class="hcl-sidebar">

      <button mat-button class="hcl-button-primary" *cmnIfPerm="['cmn:location:create']"
        (click)="openLocationCreateDialog()">
        <mat-icon>add</mat-icon>
        {{ 'LOCATION.LIST.ACTIONS.ADD_LOCATION' | translate }}
      </button>

      <form [formGroup]="locationSearch" class="location-list-sidebar__search">
        <div class="hcl-label">
          {{ 'LOCATION.LIST.ACTIONS.SEARCH' | translate }}
        </div>
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text" matInput formControlName="search" (keyup)="onSearch($event)">
        </mat-form-field>
      </form>

      <div class="location-list-sidebar__scope">
        <div class="hcl-label">
          {{ 'LOCATION.LIST.ACTIONS.QUERY_SCOPE' | translate | uppercase }}
        </div>
        <mat-select [(value)]="selectedScope"
          (selectionChange)="onQueryScopeChange($event)">
          <mat-option *ngFor="let scope of QueryScopes" [value]="scope">
            {{ ("LOCATION.LIST.QUERY_SCOPE." + scope) | translate }}
          </mat-option>
        </mat-select>
      </div>

      <div class="location-list-sidebar__types">
        <div class="hcl-label">
          {{ 'LOCATION.LIST.ACTIONS.FILTER_TYPES' | translate | uppercase }}
        </div>
        <mat-select [(value)]="selectedLocationTypes"
          (selectionChange)="onSelectedTypesChange($event)" multiple
          placeholder="{{ 'LOCATION.LIST.ACTIONS.FILTER_TYPES_PLACEHOLDER' | translate }}">
          <mat-option *ngFor="let locationType of locationTypes" [value]="locationType">
            {{ ("LOCATION.LOCATION_TYPE." + locationType) | translate }}
          </mat-option>
        </mat-select>
      </div>

      <div class="hcl-label">
        {{ 'LOCATION.LIST.ACTIONS.TAGS' | translate | uppercase }}
      </div>
      <ng-multiselect-dropdown name="tags" class="location-list-sidebar__tag"
        [placeholder]="'LOCATION.LIST.ACTIONS.TAGS' | translate" [settings]="dropdownTagsSettings" [data]="tags"
        (onSelect)="onTagSelected($event)" [(ngModel)]="tagsAdded" (onDeSelect)="onTagDeSelected($event)">
      </ng-multiselect-dropdown>
    </div>

    <div class="hcl-content">
      <mat-table [dataSource]="locationDataSource" matSort class="location_body_content_list humecloud-list">
        <!-- type -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOCATION.LIST.LABEL.TYPE' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <div class="location_body_content_list_location-type">
              <div class="location_body_content_list_location-type_text" [ngSwitch]="location.type">
                <ng-container *ngSwitchCase="LocationType.HOTEL">
                  <span>{{ 'LOCATION.LOCATION_TYPE.HOTEL' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.FLOOR">
                  <span>{{ 'LOCATION.LOCATION_TYPE.FLOOR' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.ROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.ROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.MEETING_ROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.MEETING_ROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.BEDROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.BEDROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.COMMON_AREA">
                  <span>{{ 'LOCATION.LOCATION_TYPE.COMMON_AREA' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.OTHER">
                  <span>{{ 'LOCATION.LOCATION_TYPE.OTHER' | translate }}</span>
                </ng-container>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOCATION.LIST.LABEL.NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <div class="location_body_content_list_item">
              <span class="location_body_content_list_item_name">
                {{ location.name }}
              </span>
              <span class="location_body_content_list_item_customer" *ngIf="selectedScope == 'CUSTOMER_AND_CHILDREN'">
                {{ locationCustomerNames[location.customerId] }}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ancestorLocations -->
        <ng-container matColumnDef="ancestors">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOCATION.LIST.LABEL.LOCATIONS' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <div class="location_body_content_list_item">
              <div class="location_body_content_list_item_info">
                <span class="location_body_content_list_item_location">
                  <span *ngFor="let ancestorLocationId of location.ancestorLocationIds">
                    {{ locationCustomerNames[ancestorLocationId] }}<br />
                  </span>
                </span>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let location">
            <button mat-icon-button [routerLink]="['/locations/', location.id]">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button *cmnIfPerm="['cmn:location:delete']" (click)="doDelete($event, location)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay" [routerLink]="['/locations/', row.id]"></mat-row>
      </mat-table>
    </div>
  </div>
  <footer>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage()"></mat-paginator>
  </footer>
</div>