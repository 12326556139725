import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Customer, HttpShowError, InvocationResult} from "hcl-lib"
import {SelfRegisterUserDto} from "projects/hcl-portal/src/app/common/interfaces/dto/self-register-user-dto"
import {environment} from "projects/hcl-portal/src/environments/environment"
import {Observable, of} from "rxjs"
import {catchError, map} from "rxjs/operators"
import {SelfUpdateUserDto} from "../../interfaces/dto/self-update-user-dto"
import {UserDto} from "../../interfaces/dto/user-dto"
import {User} from "../../interfaces/user"

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError()
  getUsersWithPaging(
    pageNumber: number,
    pageSize: number,
    search?: string,
    sort?: string,
    cid?: string
  ): Observable<InvocationResult> {
    let queryParams = new HttpParams()
      .set("perPage", pageSize.toString())
      .set("page", pageNumber.toString())
    if (search) {
      queryParams = queryParams.set("search", search)
    }
    if (sort) {
      queryParams = queryParams.set("sort", sort)
    }
    if (cid) {
      queryParams = queryParams.set("cid", cid)
    }
    return this.httpClient.get(environment.cmnUserApiBaseUrl + "/users", { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getUser(id: string, cid: string | undefined = undefined): Observable<User> {
    let queryParams = new HttpParams()
    if (cid) {
      queryParams = queryParams.set("cid", cid)
    }
    return this.httpClient.get(environment.cmnUserApiBaseUrl + "/users/" + id, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  addUser(
    user: User,
    password: string,
    generatePassword: boolean = false,
    notify: boolean = false
  ): Observable<User | null> {
    const userDto: UserDto = {
      email: user.email,
      customers: user.customers,
      password,
      ownerId: user.ownerdId,
      roles: user.roles,
      permissions: user.permissions,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      customerId: user.customerId
    }

    const queryParams = new HttpParams()
      .set("notify", notify.toString())
      .set("generatePassword", generatePassword)

    return this.httpClient.post(environment.cmnUserApiBaseUrl + "/users", userDto, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  importUser(user: User): Observable<User | null> {
    const userDto: UserDto = {
      email: user.email,
      customers: user.customers,
      ownerId: user.ownerdId,
      roles: user.roles,
      permissions: user.permissions,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      customerId: user.customerId
    }
    return this.httpClient.post(environment.cmnUserApiBaseUrl + "/users/import", userDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  selfRegister(user: User, customer: Customer): Observable<User> {
    const userDto: SelfRegisterUserDto = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      customer
    }
    return this.httpClient.post(environment.cmnUserApiBaseUrl + "/users/selfRegister", userDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  checkUserExistsInKeycloak(username: string): Observable<Boolean> {
    return this.httpClient.get(environment.cmnUserApiBaseUrl + "/auth/users/" + username).pipe(
      map(() => {
        return true
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 404) {
          console.log("User is not synchronized into keycloak yet")
        }
        return of(false)
      }))
  }

  @HttpShowError(true)
  updateUser(user: User, cid: string | undefined = undefined): Observable<User | null> {
    const userDto: UserDto = {
      email: user.email,
      customers: user.customers,
      roles: user.roles,
      permissions: user.permissions,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      customerId: user.customerId
    }
    let queryParams = new HttpParams()
    if (cid) {
      queryParams = queryParams.set("cid", cid)
    }
    return this.httpClient.put<User>(environment.cmnUserApiBaseUrl + "/users/" + user.id, userDto, { params: queryParams })
  }

  @HttpShowError(true)
  selfUpdate(user: User): Observable<User | null> {
    const userDto: SelfUpdateUserDto = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    }
    return this.httpClient.put<User>(environment.cmnUserApiBaseUrl + "/users/" + user.id + "/selfUpdate", userDto)
  }

  @HttpShowError(true)
  deleteUser(id: string, cid: string | undefined = undefined) {
    let queryParams = new HttpParams()
    if (cid) {
      queryParams = queryParams.set("cid", cid)
    }
    return this.httpClient.delete(environment.cmnUserApiBaseUrl + "/users/" + id, { params: queryParams })
  }
}
