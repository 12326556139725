import { NgFor, NgIf } from "@angular/common"
import { Component, AfterViewInit, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ReactiveFormsModule, FormGroup, FormControl } from "@angular/forms"
import { MatButtonModule } from "@angular/material/button"
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { MatDividerModule } from "@angular/material/divider"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatPaginatorModule, MatPaginator } from "@angular/material/paginator"
import { MatSelectModule } from "@angular/material/select"
import { MatSortModule, MatSort } from "@angular/material/sort"
import { MatTableModule, MatTableDataSource } from "@angular/material/table"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { CmnIfPermDirective, Tag, QueryScope, TargetType, TagService, ScopeService, SnackBarService, CustomerService, Customer } from "hcl-lib"
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown"
import { Subscription, debounceTime, asapScheduler, tap, mergeMap, from, Observable, map, distinct, catchError, EMPTY, toArray, of } from "rxjs"
import { TagDialogComponent } from "../../Layout/Components/widget/tag-dialog/tag-dialog.component"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatInputModule } from "@angular/material/input"

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, MatCheckboxModule, MatToolbarModule, MatButtonModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatIconModule, MatSelectModule, MatTableModule, MatTooltipModule, MatSortModule, MatPaginatorModule, NgMultiSelectDropDownModule, ReactiveFormsModule, TranslateModule, CmnIfPermDirective]
})
export class TagListComponent implements AfterViewInit, OnDestroy {

  subscription: Subscription = new Subscription()

  tagDataSource = new MatTableDataSource<Tag>()
  columnsToDisplay = ['system', 'name', 'targetTypes', 'actions']

  filterForm: FormGroup = new FormGroup({
    search: new FormControl(""),
    queryScope: new FormControl<QueryScope>(QueryScope.CUSTOMER),
    targetTypes: new FormControl([]),
    system: new FormControl(false)
  })

  tags!: Tag[]

  QueryScopes = [
    QueryScope.CUSTOMER,
    QueryScope.CUSTOMER_AND_ANCESTORS
  ]
  customerNames: { [customerId: string]: string } = {}

  TargetTypes = Object.keys(TargetType).map(
    targetType => ({
      id: TargetType[targetType as keyof typeof TargetType],
      name: this.translateService.instant('TAG.TARGET_TYPE.' + targetType)
    })
  )

  dropdownTargetTypesSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: 'id',
    textField: 'name',
    enableCheckAll: false,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  constructor(
    private tagService: TagService,
    private matDialog: MatDialog,
    private scopeService: ScopeService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private chRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.filterForm.valueChanges.pipe(
        debounceTime(350, asapScheduler)
      ).subscribe(_ => {
        this.paginator.firstPage()
        this.retrieveTags()
      })
    )
  }

  ngAfterViewInit(): void {
    this.tagDataSource.sort = this.sort
    this.retrieveTags()
  }

  retrieveTags(): void {
    const queryScope = this.filterForm.value.queryScope
    const targetTypes = this.filterForm.value.targetTypes.map((item: any) => item.id)
    const system = this.filterForm.value.system
    const search = this.filterForm.value.search
    this.subscription.add(
      this.tagService.getTagsWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        queryScope,
        targetTypes,
        system,
        search
      ).pipe(
        tap(invocationResult => {
          this.paginator.length = invocationResult.pagination?.count as number
          this.tagDataSource.data = invocationResult.data
        }),
        mergeMap(invocationResult => from(invocationResult.data) as Observable<Tag>),
        map((tag: Tag) => tag.customerId),
        distinct(),
        mergeMap(customerId => this.customerService.getCustomer(customerId!).pipe(
          catchError(_ => EMPTY)
        )),
        toArray()
      ).subscribe((customers: Customer[]) => {
        this.customerNames = customers.reduce((acc: { [customerId: string]: string }, currentValue) => {
          let customerName = currentValue.name
          if (currentValue.displayName) customerName = currentValue.displayName + " / " + currentValue.name
          acc[currentValue.id] = customerName
          return acc
        }, {})
        this.chRef.detectChanges()
      })
    )
  }

  doCreate(): void {
    var config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("TAG.LIST_COMPONENT.CREATE_TITLE"),
      action: this.translateService.instant("TAG.LIST_COMPONENT.CREATE_ACTION"),
    }
    var dialogRef = this.matDialog.open(TagDialogComponent, config)
    this.subscription.add(
      dialogRef.afterClosed().pipe(
        mergeMap((tag: Tag) => {
          if (tag) {
            return this.tagService.createTag(tag)
          }
          return of(null)
        })
      ).subscribe(createdTag => {
        if (createdTag) {
          this.snackBarService.snackBarSuccess('TAG.LIST_COMPONENT.CREATED')
          const tags = this.tagDataSource.data
          tags.push(createdTag)
          this.tagDataSource.data = tags
        }
      })
    )
  }

  doUpdate(tag: Tag): void {
    var config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("TAG.LIST_COMPONENT.EDIT_TITLE"),
      action: this.translateService.instant("TAG.LIST_COMPONENT.EDIT_ACTION"),
      tag: tag
    }
    var dialogRef = this.matDialog.open(TagDialogComponent, config)
    this.subscription.add(dialogRef.afterClosed().pipe(
      mergeMap((value: Tag) => {
        if (value) {
          var tagToUpdate = Object.assign({}, tag)
          tagToUpdate.name = value.name
          tagToUpdate.targetTypes = value.targetTypes
          tagToUpdate.system = value.system
          return this.tagService.updateTag(tagToUpdate)
        }
        return of(null)
      })
    ).subscribe(result => {
      if (result) {
        this.snackBarService.snackBarSuccess('TAG.LIST_COMPONENT.UPDATED')
        tag.name = result.name
        tag.targetTypes = result.targetTypes
        tag.system = result.system
      }
    }))
  }

  isEditable(tag: Tag): boolean {
    return this.scopeService.getScope() == tag.customerId
  }

  handlePage(): void {
    this.retrieveTags()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
