import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-contact-administrator',
    templateUrl: './contact-administrator.component.html',
    styleUrls: ['./contact-administrator.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class ContactAdministratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
