import { NgSwitch, NgSwitchCase } from "@angular/common"
import { Component, OnInit } from "@angular/core"
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap"
import {TranslateModule, TranslateService} from "@ngx-translate/core"

@Component({
    selector: "app-dots",
    templateUrl: "./dots.component.html",
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgbDropdownModule, TranslateModule]
})
export class DotsComponent implements OnInit {

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }
}
