import { Component } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { NgClass } from '@angular/common';
import { HeaderComponent } from '../Components/header/header.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '../Components/sidebar/sidebar.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoaderDecoratorComponent } from 'hcl-lib';

@Component({
    selector: 'app-base-layout',
    templateUrl: './base-layout.component.html',
    styleUrls: ['./base-layout.component.scss'],
    standalone: true,
    imports: [RouterModule, NgClass, LoadingBarModule, HeaderComponent, SidebarComponent, LoaderDecoratorComponent]
})
export class BaseLayoutComponent {

  constructor(public globals: ThemeOptions) {
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
}
