<mat-toolbar color="accent">
  <button mat-raised-button [routerLink]="['/events']">
    <mat-icon>arrow_back</mat-icon>{{ 'BACK' | translate }}
  </button>
</mat-toolbar>

<div class="event-user-header">
  <span class="event-user-header__title">
    {{ 'EVENT.USER_PREFERENCE.TITLE' | translate}}
  </span>
  <span class="fill-remaining-space"></span>
</div>

<div class="event-user-split">
  <div class="event-user-section">
    <div *ngIf="generalFieldsForm">
      <div class="event-user-fields">
        <div class="event-user-preference__body__sidebar_buttons">
          <button class="event-user-preference__body__sidebar_buttons_add" mat-raised-button
            routerLinkActive="router-link-active" (click)="onEdit()">
            {{ "EVENT.ACTIONS_CREATE" | translate }}
          </button>
        </div>
        <div class="event-user-fields__bloc">
          <div class="event-user-fields__bloc__title">{{ "EVENT.USER_PREFERENCE.GENERAL_INFORMATIONS" | translate }}
          </div>
          <form [formGroup]="generalFieldsForm" class="event-user-fields__bloc__customer-form">
            <mat-form-field>
              <!--<mat-label>{{ "EVENT_TYPE.EDIT.TRANSLATION_CODE" | translate }}</mat-label>-->
              <label>{{ "EVENT_TYPE.EDIT.TRANSLATION_CODE" | translate }}</label>
              <mat-select class="event-type-edit__translation-code_select" formControlName="language">
                <mat-option class="mat-option-translationCode" *ngFor="let translation of languages"
                  [value]="translation">
                  {{ "EVENT_TYPE.LANGUAGE." + translation | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <label>{{ "EVENT_TYPE.LIST.CHANNELS_ACTIVE" | translate }}</label>
              <searchable-select class="event-user-fields__bloc__customer-form__input" formControlName="channels"
                [showSelectArrow]="true" [placeholder]="'EVENT_TYPE.LIST.CHANNELS_ACTIVE' | translate"
                [multipleSelect]="true" [stickyPlaceholder]="false">
              </searchable-select>
            </div>
            <mat-form-field class="event-user-fields__bloc__customer-form__input">
              <input [placeholder]="'EVENT.USER_PREFERENCE.EMAIL' | translate" type="email"
                value="{{ generalFieldsForm.value.emailOverride }}" formControlName="emailOverride" matInput>
            </mat-form-field>
            <div class="event-user-fields__bloc__customer-form__action">
              <button mat-raised-button color="accent" [disabled]="generalFieldsForm.invalid" (click)="onSubmit()">
                <mat-icon>done</mat-icon>
                {{ "SAVE" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="event-type-section">
    <mat-table class="humecloud-list event-user-preference__body__table"
      [dataSource]="notificationUserPreferenceDataSource" matSort>
      <ng-container matColumnDef="key">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "EVENT.USER_PREFERENCE.EVENT_KEY" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let eventUserPreference">
          {{ eventUserPreference.eventKey }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="channels">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "EVENT_TYPE.LIST.CHANNELS" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let eventUserPreference">
          {{ eventUserPreference.channelsName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="targetCustomerIds">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "EVENT.USER_PREFERENCE.RESTRICTED_TO_CUSTOMERS" | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let eventUserPreference">
          {{ eventUserPreference.targetCustomersName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let eventUserPreference">
          <button mat-button color="primary" (click)="onEdit(eventUserPreference)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-button color="primary" (click)="onDelete(eventUserPreference)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <footer>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </footer>
  </div>
</div>