<h1 class="hcl-title" mat-dialog-title>
  {{ 'LOCATION.CREATE_DIALOG.TITLE' | translate }}
</h1>
<form [formGroup]="locationForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <!-- Name Field -->
    <mat-form-field>
      <mat-label>{{ 'LOCATION.FIELDS.NAME' | translate }}</mat-label>
      <input matInput formControlName="name" [placeholder]="'LOCATION.FIELDS.NAME' | translate" required>
    </mat-form-field>

    <!-- Description Field -->
    <mat-form-field>
      <mat-label>{{ 'LOCATION.FIELDS.DESCRIPTION' | translate }}</mat-label>
      <input matInput formControlName="description" [placeholder]="'LOCATION.FIELDS.DESCRIPTION' | translate">
    </mat-form-field>

    <!-- Type Field -->
    <mat-form-field>
      <mat-label>{{ 'LOCATION.FIELDS.TYPE' | translate }}</mat-label>
      <mat-select formControlName="type" required>
        <mat-option *ngFor="let locationType of locationTypes" [value]="locationType">
          {{ ("LOCATION.LOCATION_TYPE." + locationType) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Location Field -->
    <mat-form-field>
      <mat-label>{{ 'LOCATION.FIELDS.LOCATION' | translate }}</mat-label>
      <mat-select formControlName="locationId" (openedChange)="onOpenLocation($event)">
        <input #searchLocation matInput type="text" (input)="onSearchLocation($event)" autocomplete="off">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let location of filteredLocations" [value]="location.id">
          {{ location.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- tags -->
    <mat-form-field>
      <mat-label>{{ 'LOCATION.FIELDS.TAGS' | translate }}</mat-label>
      <mat-select formControlName="tagIds" multiple (openedChange)="onOpenLocationTag($event)">
        <input #searchLocationTag matInput type="text" (input)="onSearchLocationTag($event)" autocomplete="off">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let tag of filteredLocationTags" [value]="tag">{{ tag.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Additional Fields Based on Type -->
    <!-- Hotel Fields -->
    <ng-container *ngIf="locationForm.get('type')?.value === 'HOTEL'">
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.HOTEL.ADDRESS' | translate }}</mat-label>
        <input matInput formControlName="address" [placeholder]="'LOCATION.FIELDS.HOTEL.ADDRESS' | translate">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.HOTEL.CITY' | translate }}</mat-label>
        <input matInput formControlName="city" [placeholder]="'LOCATION.FIELDS.HOTEL.CITY' | translate">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.HOTEL.POSTAL_CODE' | translate }}</mat-label>
        <input matInput formControlName="postalCode" [placeholder]="'LOCATION.FIELDS.HOTEL.POSTAL_CODE' | translate">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.HOTEL.COUNTRY' | translate }}</mat-label>
        <input matInput formControlName="country" [placeholder]="'LOCATION.FIELDS.HOTEL.COUNTRY' | translate">
      </mat-form-field>
    </ng-container>

    <!-- Floor Fields -->
    <ng-container *ngIf="['FLOOR', 'ROOM', 'MEETING_ROOM', 'BEDROOM'].includes(locationForm.get('type')?.value)">
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.NUMBER' | translate }}</mat-label>
        <input matInput type="number" formControlName="number" [placeholder]="'LOCATION.FIELDS.NUMBER' | translate">
      </mat-form-field>
    </ng-container>

    <!-- Room, Meeting Room, Bedroom Fields -->
    <ng-container *ngIf="['ROOM', 'MEETING_ROOM', 'BEDROOM'].includes(locationForm.get('type')?.value)">
      <mat-checkbox formControlName="available">{{ 'LOCATION.FIELDS.AVAILABLE' | translate }}</mat-checkbox>
    </ng-container>

    <!-- Common Area Fields -->
    <ng-container *ngIf="locationForm.get('type')?.value === 'COMMON_AREA'">
      <!-- Nothing for the moment -->
    </ng-container>

    <!-- Other Fields -->
    <ng-container *ngIf="locationForm.get('type')?.value === 'OTHER'">
      <!-- Nothing for the moment -->
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="hcl-button" mat-button mat-dialog-close>
      {{ 'CANCEL' | translate }}
    </button>
    <button class="hcl-button-primary" mat-button [disabled]="!locationForm.valid" type="submit">
      <mat-icon>done</mat-icon>
      {{ 'VALIDATE' | translate }}
    </button>
  </mat-dialog-actions>
</form>

