import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort, MatSortModule } from "@angular/material/sort"
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"
import { Observable, Subscription } from "rxjs"
import { MatTableDataSource } from "@angular/material/table"
import { EventService } from "../../../services/event/event.service"
import { Event } from "../../../interfaces/event"
import { CustomerService, QueryScope } from "hcl-lib"
import { map } from "rxjs/operators"
import { MatDialog } from "@angular/material/dialog"
import { EventModalComponent } from "../event-modal/event-modal.component"
import { NotificationRecipientsService } from "../../../services/notifications/notification-recipients.service"
import { NotificationRecipient } from "../../../interfaces/notification-recipient"
import { RouterModule } from "@angular/router"
import { AsyncPipe, DatePipe, NgFor, NgIf } from "@angular/common"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { TranslateModule } from "@ngx-translate/core"
import { SearchableSelectComponent } from "../../../Layout/Components/widget/searchable-select/searchable-select.component"
import { MatSelectChange, MatSelectModule } from "@angular/material/select"
import { MatTableModule } from "@angular/material/table"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"

export interface EventWithCustomerName extends Event {
  customerName$: Observable<string>
  notificationRecipient$?: Observable<NotificationRecipient>
}

@Component({
    selector: "app-event-list",
    templateUrl: "./event-list.component.html",
    styleUrls: ["./event-list.component.scss"],
    standalone: true,
    imports: [RouterModule, NgFor, NgIf, AsyncPipe, DatePipe, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTableModule, MatSortModule, MatPaginatorModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, TranslateModule, SearchableSelectComponent]
})
export class EventListComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription()

  eventDataSource = new MatTableDataSource<EventWithCustomerName>()
  columnsToDisplay = ["name", "eventKey", "customerName", "severity", "service", "at", "actions"]
  isLoadingResults = false
  eventServices: { id: string, name: string, selected: boolean }[] = []
  selectedScope = QueryScope.CUSTOMER

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  QueryScopes = [
    QueryScope.CUSTOMER,
    QueryScope.CUSTOMER_AND_CHILDREN
  ]
  eventSearch: UntypedFormGroup = this.formBuilder.group({
    eventName: [],
    service: [this.eventServices]
  })

  constructor(
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private customerService: CustomerService,
    private notificationRecipientService: NotificationRecipientsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isLoadingResults = true
    this.sort.disableClear = true
    this.eventDataSource.sort = this.sort
  }

  ngAfterViewInit() {
    this.retrieveEvents()
    this.retrieveServices()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  retrieveEvents(): void {
    this.isLoadingResults = true
    this.subscriptions.add(
      this.eventService.getEventsWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.eventSearch.get("eventName")?.value,
        this.eventSearch.get("service")?.value,
        this.selectedScope
      ).pipe(
        map(invocationResult => ({
          ...invocationResult,
          data: invocationResult.data.map((it: any) =>
          ({
            ...it,
            customerName$: this.customerService.getCustomer(it.customerId).pipe(
              map(customer => customer.name)
            ),
            notificationRecipient$: this.notificationRecipientService.getUnreadNotifications(it.id)
          })
          )
        })),
      ).subscribe((invocationResult) => {
        this.paginator.length = invocationResult.pagination?.count as number
        this.eventDataSource = invocationResult.data
        this.isLoadingResults = false
      })
    )
  }

  filterChange(event: any): void {
    this.retrieveEvents()
  }

  retrieveServices() {
    this.subscriptions.add(
      this.eventService.getServices(0, 50).subscribe((invocationResult) => {
        // TODO manage pagination of services
        this.eventServices = invocationResult.data.map((it: string) => ({ id: it, name: it, selected: false }))
        this.eventSearch.patchValue({ service: this.eventServices })
      })
    )
  }

  onQueryScopeChange(event: MatSelectChange): void {
    this.selectedScope = event.value
    this.retrieveEvents()
  }

  onRead(row?: EventWithCustomerName) {
    const dialogRef = this.dialog.open(EventModalComponent, {
      data: { ...row }
    })
    this.subscriptions.add(dialogRef.afterClosed().subscribe())
  }
}
