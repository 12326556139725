import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DeviceService } from 'projects/hcl-portal/src/app/common/services/device/device.service';
import { Subscription } from 'rxjs';
import { Device } from "../../../interfaces/device";
import { DeviceStatus } from "../../../interfaces/device-status";
import { NgIf } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'app-device-monit-dialog',
    templateUrl: './device-monit-dialog.component.html',
    styleUrls: ['./device-monit-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogModule, MatButtonModule, TranslateModule]
})
export class DeviceMonitDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  device!: Device
  status!: DeviceStatus

  constructor(
    private deviceService: DeviceService,
    public dialogRef: MatDialogRef<DeviceMonitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    this.device = this.data.device
    this.retrieveDeviceStatus()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDeviceStatus(): void {
    this.subscriptions.add(
      this.deviceService.getDeviceStatus(this.device.id).subscribe(status => {
        this.status = status
      })
    )
  }
}
