export class PeripheralConfig {
  paymentTerminalType!: PaymentTerminalType
  stripeSdkConfig?: StripeSDKConfig
  stripeReaderId?: string
  verifoneReaderId?: string
}

export class StripeSDKConfig {
  stripeLocationId?: string
  stripeSDKConnectionMethod?: StripeSDKConnectionMethod
}

export enum PaymentTerminalType {
  NONE = "NONE",
  VERIFONE = "VERIFONE",
  STRIPE_CLOUD = "STRIPE_CLOUD",
  STRIPE_SDK = "STRIPE_SDK"
}

export enum StripeSDKConnectionMethod {
  USB = "USB",
  BLUETOOTH = "BLUETOOTH",
  TAPTOPAY = "TAPTOPAY"
}
