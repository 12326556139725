import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { LicenseTypeService } from '../../../../common/services/license-type/license-type.service';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AppPlugin, PluginService, License, LicenseService } from "hcl-lib";
import { RouterModule } from '@angular/router';
import { DatePipe, NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LicenseType } from '../../../interfaces/license-type';

@Component({
    selector: 'app-my-licenses',
    templateUrl: './my-licenses.component.html',
    styleUrls: ['./my-licenses.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, DatePipe, MatToolbarModule, MatTableModule, MatSortModule, MatPaginatorModule, MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule]
})
export class MyLicensesComponent implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  licenseDataSource = new MatTableDataSource<License>()
  columnsToDisplay = ['licenseType', 'start', 'end', 'quota', 'trial', 'plugins', 'actions']

  licenseTypeList: {[key: string]: LicenseType} = {}
  plugins: AppPlugin[] = []

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  constructor(
    private licenseService: LicenseService,
    private pluginService: PluginService,
    private licenseTypeService: LicenseTypeService,
    private translateService: TranslateService
  ) { }

  ngAfterViewInit(): void {
    this.licenseDataSource.sort = this.sort
    this.retrievePlugins()
    this.retrieveLicenses()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrievePlugins(): void {
    this.subscriptions.add(
      this.pluginService.getPlugins().subscribe(plugins => this.plugins = plugins)
    )
  }

  retrieveLicenses(): void {
    this.subscriptions.add(
      this.licenseService.getLicensesWithPaging(
        this.paginator.pageIndex, this.paginator.pageSize
      ).subscribe(invocationResult => {
        this.paginator.length = invocationResult.pagination?.count as number
        this.licenseDataSource.data = invocationResult.data
        this.retrieveLicenseTypes()
      })
    )
  }

  retrieveLicenseTypes(): void {
    const ltIdsToRetrieve: string[] = []
    this.licenseDataSource.data.forEach((license) => {
      if (license.licenseTypeId in this.licenseTypeList) {
        return
      }
      if (!ltIdsToRetrieve.includes(license.licenseTypeId)) {
        ltIdsToRetrieve.push(license.licenseTypeId)
      }
    })
    ltIdsToRetrieve.forEach((ltId) => {
      this.subscriptions.add(
        this.licenseTypeService.getLicenseType(ltId).subscribe((licenseType) => {
          this.licenseTypeList[ltId] = licenseType
        })
      )
    })
  }

  handlePage(e: any) {
    this.retrieveLicenses()
  }

  getPluginNames(pluginIds: string[]): string {
    return pluginIds.filter(pluginId => this.plugins.some(plugin => plugin.id == pluginId))
      .map(pluginId => this.plugins.find(plugin => plugin.id == pluginId)?.name)
      .map(pluginName => this.translateService.instant(`PLUGINS.${pluginName}.NAME`.toUpperCase()))
      .join(", ")
  }
}
