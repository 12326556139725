import { Component, OnDestroy } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CustomerService, KeycloakService, ScopeService } from 'hcl-lib';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Email } from "../../../interfaces/email";
import { EmailService } from "../../../services/email/email.service";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-contact-index',
    templateUrl: './contact-index.component.html',
    styleUrls: ['./contact-index.component.scss'],
    standalone: true,
    imports: [RouterModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatIconModule, TranslateModule]
})

export class ContactIndexComponent implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  emailForm!: UntypedFormGroup;

  constructor(
    private keycloakService: KeycloakService,
    private customerService: CustomerService,
    private scopeService: ScopeService,
    private formBuilder: UntypedFormBuilder,
    private emailService: EmailService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      customer: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: [''],
      serialNumber: [''],
      boughtDate: [''],
      subjectType: ['other', Validators.required],
      subject: ['', Validators.required],
      description: [''],
    });

    this.retrieveCustomerName()
    this.retrieveUserData()
  }

  retrieveCustomerName() {
    this.subscriptions.add(this.scopeService.getScopeObservable().pipe(
      switchMap(customerId => this.customerService.getCustomer(customerId as string)),
      map(customer => customer.name)
    ).subscribe(customerName => {
      this.emailForm.patchValue({ customer: customerName })
    }))
  }

  retrieveUserData(): void {
    this.emailForm.patchValue({
      name: this.keycloakService.getFullName(),
      email: this.keycloakService.getEmail(),
      phoneNumber: this.keycloakService.getPhoneNumber()
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sendMessage() {
    let formValue = this.emailForm.value;
    this.subscriptions.add(this.emailService.sendEmail({
      source: "contact@humecloud.com",
      destination: this.getDestinationForType(formValue.subjectType),
      replyTo: formValue.email,
      subject: "[" + formValue.subjectType + "] " + formValue.subject,
      htmlBody: this.getHtmlBody(formValue.customer, formValue.name,
        formValue.phoneNumber, formValue.serialNumber, formValue.boughtDate, formValue.subjectType, formValue.subject,
        formValue.description),
      textBody: this.getMessageBody(formValue.customer, formValue.name,
        formValue.phoneNumber, formValue.serialNumber, formValue.boughtDate, formValue.subjectType, formValue.subject,
        formValue.description)
    } as Email).subscribe(
      _ => this.showSnackbar(this.translateService.instant('CONTACT_INDEX_COMPONENT.MSG_SENT_SUCCESS')),
      _ => this.showSnackbar(this.translateService.instant('CONTACT_INDEX_COMPONENT.MSG_SENT_FAILED'))
    ))
  }

  getHtmlBody(customerName: string, name: string, phoneNumber: string, serialNumber: string, boughtDate: string,
    subjectType: string, subject: string, description: string) {
    return "Société: " + customerName + "<br/>" +
      "Nom: " + name + "<br/>" +
      "Téléphone: " + phoneNumber + "<br/>" +
      "N° de série du produit: " + serialNumber + "<br/>" +
      "Date d'achat: " + boughtDate + "<br/>" +
      "Type de demande: " + subjectType + "<br/>" +
      "Objet: " + subject + "<br/>" +
      "Description: " + description;
  }

  getMessageBody(customerName: string, name: string, phoneNumber: string, serialNumber: string, boughtDate: string,
    subjectType: string, subject: string, description: string) {
    return "Société: " + customerName + "\n" +
      "Nom: " + name + "\n" +
      "Téléphone: " + phoneNumber + "\n" +
      "N° de série du produit: " + serialNumber + "\n" +
      "Date d'achat: " + boughtDate + "\n" +
      "Type de demande: " + subjectType + "\n" +
      "Objet: " + subject + "\n" +
      "Description: " + description;
  }

  getDestinationForType(subjectType: string) {
    if (subjectType == "commercial") {
      return "commercial@humelab.com"
    } else if (subjectType == "creation"){
      return "crea@humelab.com"
    } else {
      return "sav@humelab.com"
    }
  }

  private showSnackbar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000
    });
  }
}
