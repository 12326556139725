import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'hcl-lib';
import { Subscription } from 'rxjs';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../../../../theme-options';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-user-box',
    templateUrl: './user-box.component.html',
    styleUrls: ['user-box.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, NgbDropdownModule, TranslateModule]
})
export class UserBoxComponent implements OnInit, OnDestroy {

  username!: String;
  subscription: Subscription = new Subscription();
  faUserCircle = faUserCircle;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    private router: Router,
    public globals: ThemeOptions,
    private keycloakService: KeycloakService
  ) { }

  ngOnInit() {
    this.retrieveUsername()
    this.keycloakService.getRefreshedToken().subscribe(() => {
      this.retrieveUsername()
    })
  }

  retrieveUsername() {
    this.username = this.keycloakService.getFullName();
    if (!this.username) {
      this.username = this.keycloakService.getUsername();
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  goToAccountPage() {
    this.router.navigate(["/me"]);
  }

  goToCustomerPage() {
    this.router.navigate(["customers", this.keycloakService.getCustomerIds()[0]])
  }

  goToMyPluginsPage() {
    this.router.navigate(["/my-plugin-configs"]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
