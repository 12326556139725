import { Location, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'hcl-lib';
import { User } from 'projects/hcl-portal/src/app/common/interfaces/user';
import { UserService } from 'projects/hcl-portal/src/app/common/services/user/user.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserFieldsComponent } from '../../../Layout/Components/widget/user/user-fields/user-fields.component';


@Component({
    selector: 'user-account-details',
    templateUrl: './user-account-details.component.html',
    styleUrls: ['./user-account-details.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, MatIconModule, TranslateModule, UserFieldsComponent]
})
export class UserAccountDetailsComponent implements OnInit, OnDestroy {

  user!: User;
  subscription: Subscription = new Subscription();
  title!: string
  action!: string

  constructor(
    private userService: UserService,
    private location: Location,
    private keycloakService: KeycloakService,
    private translateService: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeUserData();
    this.action = this.translateService.instant('USER_ACCOUNT_DETAILS_COMPONENT.ACTION')
    this.title = this.translateService.instant('USER_ACCOUNT_DETAILS_COMPONENT.TITLE') + " " + this.keycloakService.getUsername()
  }

  initializeUserData() {
    this.user = {
      id: this.keycloakService.getApiUserId(),
      email: this.keycloakService.getUsername(),
      customers: [],
      roles: [],
      permissions: [],
      customerId: "",
      firstName: this.keycloakService.getFirstName(),
      lastName: this.keycloakService.getLastName(),
      phoneNumber: this.keycloakService.getPhoneNumber()
    }
  }

  doUpdate($event: User) {
    this.subscription.add(this.userService.selfUpdate($event).pipe(
      map(_ => this.keycloakService.getToken(true))
    ).subscribe(_ => {
      this.goBack()
    }));
  }

  goBack() {
    this.location.back()
  }
}
