<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <ng-container *ngIf="whitelabelService.logoUrl$ | async as logoUrl; else humecloud">
      <img class="client-logo" [src]="logoUrl">
    </ng-container>
    <ng-template #humecloud>
      <div class="humecloud-logo">
        <img class="img-humecloud" src="/assets/images/icon-humecloud.svg">
        <h1>HUME<span>CLOUD</span></h1>
      </div>
    </ng-template>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="v-sidebar-menu vsm-default">
      <div class="vsm-list">
        <ng-container *cmnIfPerm="['cmn:apps-page:read']">
          <div class="vsm-item" *cmnIfSimpleApp="[]">
            <a routerLink="/user-apps" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}"
              class="vsm-link">
              <img class="vsm-icon applications-icon" src="/assets/images/icon-menu.svg">
              <span class="vsm-title">{{ 'SIDEBAR.APPS' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:screenlab']">
          <ng-container *cmnIfSimpleApp="['screenlab']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true" [cmnHideIfNoChildren]="'.vsm-list'">
              <div ngbAccordionItem id="screenlabMenu" *cmnIfApp="['screenlab']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-screenlab.svg">
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('screenlab')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <ng-container *ngIf="isMobile">
                          <div class="vsm-item"
                            *cmnIfPerm="['scr:media:read:image','scr:media:read:video','scr:media:read:pdf','scr:media:read:template'];op:'OR'">
                            <a class="vsm-link" routerLink="/medias" routerLinkActive="active-item"
                              *cmnIfApp="['screenlab']">
                              <i class="vsm-icon lnr-picture"></i>
                              <span class="vsm-title">{{ 'SIDEBAR.MEDIAS' | translate }}</span>
                            </a>
                          </div>
                        </ng-container>
                        <div class="vsm-item">
                          <a class="vsm-link" routerLink="/screenlab/dashboard" routerLinkActive="active-item"
                             *cmnIfApp="['screenlab']">
                            <i class="vsm-icon lnr-chart-bars"></i>
                            <span class="vsm-title">
                              {{ 'SIDEBAR.DASHBOARD' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item" *cmnIfPerm="['scr:scenario:read'];op:'OR'">
                          <a class="vsm-link" routerLink="/screenlab/scenario" routerLinkActive="active-item"
                            *cmnIfApp="['screenlab']">
                            <i class="vsm-icon lnr-film-play"></i>
                            <span class="vsm-title">{{ 'SIDEBAR.SCENARIOS' | translate }}</span>
                          </a>
                        </div>
                        <div class="vsm-item" *cmnIfPerm="['scr:scenario-disposition:read']">
                          <a class="vsm-link" routerLink="/screenlab/scenarioDispositions" routerLinkActive="active-item"
                            *cmnIfApp="['screenlab']">
                            <i class="vsm-icon lnr-layers"></i>
                            <span class="vsm-title">{{ 'SCENARIO_DISPOSITION.TITLE' | translate }}</span>
                          </a>
                        </div>
                        <div class="vsm-item" *cmnIfPerm="['scr:schedule:read'];op:'OR'">
                          <a class="vsm-link" routerLink="/screenlab/schedules" routerLinkActive="active-item"
                            *cmnIfApp="['screenlab']">
                              <i class="vsm-icon lnr-calendar-full"></i>
                            <span class="vsm-title">{{ 'SIDEBAR.SCHEDULE' | translate }}</span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humenu']">
          <div class="vsm-item" *cmnIfSimpleApp="['humenu']">
            <a routerLink="/humenu" routerLinkActive="active-item" class="vsm-link" *cmnIfApp="['humenu']">
              <img class="vsm-icon" src="/assets/images/apps/icon-humenu.svg">
              <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humenu')"></span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humedirectory']">
          <div class="vsm-item" *cmnIfSimpleApp="['humedirectory']">
            <a routerLink="/humedirectory" routerLinkActive="active-item" class="vsm-link"
              *cmnIfApp="['humedirectory']">
              <img class="vsm-icon" src="/assets/images/apps/icon-humedirectory.svg">
              <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humedirectory')"></span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humefan']">
          <ng-container *cmnIfSimpleApp="['humefan']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humefanMenu" *cmnIfApp="['humefan']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-humefan.svg">
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humefan')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humefan/arts" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{ 'SIDEBAR.MY_3D_OBJECTS' | translate }}</span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humefan/library" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{ 'SIDEBAR.MY_VIDEOS' | translate }}</span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humefan/editor" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{ 'SIDEBAR.CREATE_YOUR_VIDEO' | translate }}</span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humehealth']">
          <div class="vsm-item" *cmnIfSimpleApp="['humehealth']">
            <a routerLink="/humehealth" routerLinkActive="active-item" *cmnIfApp="['humehealth']" class="vsm-link">
              <img class="vsm-icon" src="/assets/images/apps/icon-humeservices.svg">
              <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humehealth')"></span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humetips']">
          <div class="vsm-item" *cmnIfSimpleApp="['humetips']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humetipsMenu" *cmnIfApp="['humetips']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-humetips.svg">
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humetips')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humetips/apps" routerLinkActive="active-item" class="vsm-link"
                            *cmnIfPerm="['hti:apps-page:read']">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.APPS' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humetips/configuration/settings" routerLinkActive="active-item" class="vsm-link"
                            *cmnIfPerm="['hti:setting:update:unrestricted']">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.SETTINGS' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humetips/collaborators" routerLinkActive="active-item" class="vsm-link"
                            *cmnIfPerm="['hti:collaborator:read']">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.COLLABORATORS' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humetips/teams" routerLinkActive="active-item" class="vsm-link"
                            *cmnIfPerm="['hti:team:read']">
                            <span class="vsm-title">{{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.TEAMS' | translate }}</span>
                          </a>
                          <a routerLink="/humetips/approvals" routerLinkActive="active-item" class="vsm-link"
                            *cmnIfPerm="['hti:approval:update']">
                            <span class="vsm-title">{{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.APPROVALS' | translate }}</span>
                          </a>
                          <a routerLink="/humetips/accounting" *cmnIfPerm="['hti:accounting:read']"
                            routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.ACCOUNTING' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humetips/feedbacks" *cmnIfPerm="['hti:feedback:read']"
                            routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.FEEDBACKS' | translate }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humecharity']">
          <div class="vsm-item" *cmnIfSimpleApp="['humecharity']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humecharityMenu" *cmnIfApp="['humecharity']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-humecharity.svg">
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humecharity')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humecharity/apps" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.APPS' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humecharity/configuration/settings" routerLinkActive="active-item"
                            class="vsm-link" *cmnIfPerm="['hti:setting:update:unrestricted']">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.SETTINGS' | translate }}
                            </span>
                          </a>
                          <a routerLink="/humecharity/accounting" *cmnIfPerm="['hti:accounting:read']"
                            routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMETIPS.HUMECLOUD_SIDEBAR.ACCOUNTING' | translate }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humehospitality']">
          <div class="vsm-item" *cmnIfSimpleApp="['humehospitality']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humehospitalityMenu" *cmnIfApp="['humehospitality']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/icon-humecloud.svg">
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humehospitality')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humehospitality/configurations" *cmnIfPerm="['hho:configuration:read']"
                            routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEHOSPITALITY.HUMECLOUD_SIDEBAR.CONFIGURATIONS' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humehospitality/rooms" *cmnIfPerm="['hho:room:read']"
                            routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEHOSPITALITY.HUMECLOUD_SIDEBAR.ROOMS' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humehospitality/events" *cmnIfPerm="['hho:event:read']"
                             routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEHOSPITALITY.HUMECLOUD_SIDEBAR.EVENTS' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humehospitality/wayfinding-devices" *cmnIfPerm="['hho:wayfinding-device:read']"
                             routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEHOSPITALITY.HUMECLOUD_SIDEBAR.WAYFINDING' | translate }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humeiptv']">
          <ng-container *cmnIfSimpleApp="['humeiptv']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humeiptvMenu" *cmnIfApp="['humeiptv']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-humeiptv.png" />
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humeiptv')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humeiptv/dashboard" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEIPTV.HUMECLOUD_SIDEBAR.DASHBOARD' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humeiptv/televisions" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEIPTV.HUMECLOUD_SIDEBAR.TV' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humeiptv/global-channels" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEIPTV.HUMECLOUD_SIDEBAR.GLOBAL_CHANNELS' | translate }}
                            </span>
                          </a>
                        </div>
                    </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humecore', 'hco:humecore:read']">
          <ng-container *cmnIfSimpleApp="['humecore']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humecoreMenu" *cmnIfApp="['humecore']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/icon-humecloud.svg" />
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humecore')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humecore/dashboard" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMECORE.HUMECLOUD_SIDEBAR.DASHBOARD' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humecore/servers" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMECORE.HUMECLOUD_SIDEBAR.SERVERS' | translate }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:app-page:read:humeorder']">
          <ng-container *cmnIfSimpleApp="['humeorder']">
            <div ngbAccordion [destroyOnHide]="false" [closeOthers]="true">
              <div ngbAccordionItem id="humeorderMenu" *cmnIfApp="['humeorder']">
                <div ngbAccordionHeader>
                  <button ngbAccordionButton class="vsm-link">
                    <img class="vsm-icon" src="/assets/images/apps/icon-humeorder.svg" />
                    <span class="vsm-title" [innerHTML]="getAppDisplayNameHtml('humeorder')"></span>
                    <i class="vsm-arrow"></i>
                  </button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody class="vsm-dropdown">
                    <ng-template>
                      <div class="vsm-list">
                        <div class="vsm-item">
                          <a routerLink="/humeorder/home/categories" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEORDER.HUMECLOUD_SIDEBAR.PRODUCTS' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/humeorder/configuration/theme" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">
                              {{ 'APPS.HUMEORDER.HUMECLOUD_SIDEBAR.SETTINGS' | translate }}
                            </span>
                          </a>
                        </div>
                        <div class="vsm-item">
                          <a routerLink="/medias" routerLinkActive="active-item" class="vsm-link">
                            <span class="vsm-title">{{ 'APPS.HUMEORDER.HUMECLOUD_SIDEBAR.MEDIAS' | translate }}</span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *cmnIfApp="['humeorder', 'humehealth', 'humetips', 'humecharity', 'screenlab']; op: 'OR'">
          <div class="vsm-item" *cmnIfPerm="['scr:device:create', 'scr:device:update:unrestricted', 'scr:device:delete']; op: 'OR'">
            <a class="vsm-link" routerLink="/devices" routerLinkActive="active-item">
              <i class="vsm-icon lnr-screen"></i>
              <span class="vsm-title">{{ 'SIDEBAR.EQUIPMENTS' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfApp="['humeiptv']">
          <div class="vsm-item" *cmnIfPerm="['cmn:location:read']">
            <a class="vsm-link" routerLink="/locations" routerLinkActive="active-item">
              <i class="vsm-icon lnr-location"></i>
              <span class="vsm-title">{{ 'SIDEBAR.LOCATIONS' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPlugin="['datasource']">
          <div class="vsm-item">
            <a class="vsm-link" routerLink="/screenlab/data-sources" routerLinkActive="active-item">
              <i class="vsm-icon lnr-database"></i>
              <span class="vsm-title">{{ 'DATA_SOURCE.MENU' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPlugin="['realEstate']">
          <div class="vsm-item" *cmnIfPerm="['ree:ad:read']">
            <a class="vsm-link" routerLink="/realestate" routerLinkActive="active-item">
              <i class="vsm-icon lnr-apartment"></i>
              <span class="vsm-title">{{ 'REAL_ESTATE.ADS.MENU' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPlugin="['pharmonweb']">
          <div class="vsm-item" *cmnIfPerm="['scr:pharmonweb-editor']">
            <a class="vsm-link" routerLink="/pharmonweb" routerLinkActive="active-item">
              <i class="vsm-icon lnr-heart-pulse"></i>
              <span class="vsm-title">{{ 'PHARMONWEB.MENU' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPlugin="['automobile']">
          <div class="vsm-item" *cmnIfPerm="['car:ad:read']">
            <a class="vsm-link" routerLink="/automobile" routerLinkActive="active-item">
              <i class="vsm-icon lnr-car"></i>
              <span class="vsm-title">{{ 'AUTOMOBILE.MENU' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPlugin="['azimut']">
          <div class="vsm-item" *cmnIfPerm="['scr:azimut:read']">
            <a class="vsm-link" routerLink="/screenlab/azimut" routerLinkActive="active-item">
              <i class="vsm-icon lnr-leaf"></i>
              <span class="vsm-title">{{ 'AZIMUT.TITLE' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:licence-page:read']">
          <div class="vsm-item" *cmnIfSimpleApp="[]">
            <a routerLink="/myLicenses" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}"
              class="vsm-link">
              <i class="vsm-icon lnr-license"></i>
              <span class="vsm-title">{{ 'SIDEBAR.MY_LICENSES' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <ng-container *cmnIfPerm="['cmn:contact-page:read']">
          <div class="vsm-item">
            <a routerLink="/contact" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}"
              class="vsm-link">
              <i class="vsm-icon pe-7s-id"></i>
              <span class="vsm-title">{{ 'SIDEBAR.CONTACT_US' | translate }}</span>
            </a>
          </div>
        </ng-container>
        <div class="vsm-header"
          *cmnIfPerm="['cmn:user:create', 'cmn:user:delete', 'cmn:app:create', 'cmn:app:update', 'cmn:app:delete', 'cmn:license:create', 'cmn:license:update', 'cmn:license:delete', 'cmn:license-type:create', 'cmn:license-type:update', 'cmn:license-type:delete', 'cmn:role:create', 'cmn:role:update', 'cmn:role:delete', 'cmn:seller:create', 'cmn:seller:update', 'cmn:seller:delete', 'cmn:selling-company:create', 'cmn:selling-company:update', 'cmn:selling-company:delete','scr:tag:create','scr:tag:update:unrestricted'];op:'OR'">
          {{ 'APPS.HUMEORDER.SETTINGS.HEADER.NAV_ADMIN' | translate }}
        </div>
        <div ngbAccordion *ngIf="scopeService.getScope()?.length! > 0" [destroyOnHide]="false" [closeOthers]="true">
          <div id="usersMenu" *cmnIfPerm="['cmn:user:create', 'cmn:user:update'];op:'AND'">
            <div>
              <a routerLink="/users" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon lnr-users"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.USERS' | translate }}</span>
              </a>
            </div>
          </div>
          <div ngbAccordionItem id="eventsMenu"
            *cmnIfPerm="['cmn:event:read', 'cmn:event:create', 'cmn:event:delete', 'cmn:event-type:update', 'cmn:event-type:read', 'cmn:event-type:delete'];op:'OR'">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="vsm-link">
                <i class="vsm-icon lnr-list"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.EVENTS' | translate }}</span>
                <i class="vsm-arrow"></i>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="vsm-dropdown">
                <ng-template>
                  <div class="vsm-list">
                    <div *cmnIfPerm="['cmn:event:read', 'cmn:event:create', 'cmn:event:delete'];op:'OR'" class="vsm-item">
                      <a routerLink="/events" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.EVENTS' | translate }}</span>
                      </a>
                    </div>
                    <div *cmnIfPerm="['cmn:event-type:update', 'cmn:event-type:read', 'cmn:event-type:delete'];op:'OR'" class="vsm-item">
                      <a routerLink="/admin/eventTypes" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.EVENT_TYPES' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div id="customersMenu"
            *cmnIfPerm="['cmn:customer:create', 'cmn:customer:update:unrestricted', 'cmn:customer:delete'];op:'OR'">
            <div>
              <a routerLink="/admin/customers" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon lnr-briefcase"></i>
                <span class="vsm-title span-panel-title">{{ 'CLIENTS' | translate }}</span>
              </a>
            </div>
          </div>
          <div id="appsMenu" *cmnIfPerm="['cmn:app:read', 'cmn:app-admin-page:read'];op:'AND'">
            <div>
              <a routerLink="/admin/apps" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-news-paper"></i>
                <span class="vsm-title span-panel-title">Apps</span>
              </a>
            </div>
          </div>
          <div ngbAccordionItem id="pluginsMenu"
            *cmnIfPerm="['cmn:plugin-admin-page:read','cmn:plugin-config-admin-page:read'];op:'OR'">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="vsm-link">
                <i class="vsm-icon pe-7s-plugin"></i>
                <span class="vsm-title span-panel-title">{{ 'PLUGIN.MENU' | translate }}</span>
                <i class="vsm-arrow"></i>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="vsm-dropdown">
                <ng-template>
                  <div class="vsm-list">
                    <div *cmnIfPerm="['cmn:plugin-config:read', 'cmn:plugin-config-admin-page:read'];op:'AND'"
                      class="vsm-item">
                      <a routerLink="/plugin-configs" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'PLUGIN.CONFIG.MENU' | translate }}</span>
                      </a>
                    </div>
                    <div *cmnIfPerm="['cmn:plugin:read', 'cmn:plugin-admin-page:read'];op:'AND'" class="vsm-item">
                      <a routerLink="/admin/plugins" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'PLUGIN.LIST.MENU' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div id="customSlidesMenu" *cmnIfPerm="['scr:custom-slide:read']">
            <div>
              <a routerLink="/screenlab/customSlides" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon lnr-pencil"></i>
                <span class="vsm-title span-panel-title">{{ 'CUSTOM_SLIDE.TITLE' | translate }}</span>
              </a>
            </div>
          </div>
          <div id="deviceAppsMenu" *cmnIfPerm="['scr:device-app:update','scr:device-app:create'];op:'OR'">
            <div>
              <a routerLink="/admin/deviceApps" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon lnr-screen"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.PLAYERS' | translate }}</span>
              </a>
            </div>
          </div>
          <div ngbAccordionItem id="licensesMenu"
            *cmnIfPerm="['cmn:license:create', 'cmn:license:update', 'cmn:license:delete', 'cmn:license-type:create', 'cmn:license-type:update', 'cmn:license-type:delete'];op:'OR'">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="vsm-link">
                <i class="vsm-icon lnr-license"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.LICENSES' | translate }}</span>
                <i class="vsm-arrow"></i>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="vsm-dropdown">
                <ng-template>
                  <div class="vsm-list">
                    <div
                      *cmnIfPerm="['cmn:license-type:create', 'cmn:license-type:update', 'cmn:license-type:delete'];op:'OR'"
                      class="vsm-item">
                      <a routerLink="/admin/licenseTypes" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.TYPES' | translate }}</span>
                      </a>
                    </div>
                    <div *cmnIfPerm="['cmn:license:create', 'cmn:license:update', 'cmn:license:delete'];op:'OR'"
                      class="vsm-item">
                      <a routerLink="/admin/licenses" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.LIST' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem id="rolesMenu" *cmnIfPerm="['cmn:role:create', 'cmn:role:update', 'cmn:role:delete'];op:'OR'">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="vsm-link">
                <i class="vsm-icon lnr-lock"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.ACCESS' | translate }}</span>
                <i class="vsm-arrow"></i>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="vsm-dropdown">
                <ng-template>
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/admin/roles" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.ROLES' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem id="salesMenu"
            *cmnIfPerm="['cmn:seller:create', 'cmn:seller:update', 'cmn:seller:delete', 'cmn:selling-company:create', 'cmn:selling-company:update', 'cmn:selling-company:delete'];op:'OR'">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="vsm-link">
                <i class="vsm-icon lnr-store"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.SALES_PERSON' | translate }}</span>
                <i class="vsm-arrow"></i>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="vsm-dropdown">
                <ng-template>
                  <div class="vsm-list">
                    <div
                      *cmnIfPerm="['cmn:selling-company:create', 'cmn:selling-company:update', 'cmn:selling-company:delete'];op:'OR'"
                      class="vsm-item">
                      <a routerLink="/admin/sellingCompanies" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.COMPANIES' | translate }}</span>
                      </a>
                    </div>
                    <div *cmnIfPerm="['cmn:seller:create', 'cmn:seller:update', 'cmn:seller:delete'];op:'OR'"
                      class="vsm-item">
                      <a routerLink="/admin/sellers" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">{{ 'SIDEBAR.SELLERS' | translate }}</span>
                      </a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div id="tagsMenu" *cmnIfPerm="['scr:tag:create','scr:tag:update:unrestricted'];op:'OR'">
            <div>
              <a routerLink="/tags" routerLinkActive="active-item" class="vsm-link" *cmnIfApp="['screenlab']">
                <i class="vsm-icon pe-7s-ticket"></i>
                <span class="vsm-title span-panel-title">{{ 'SIDEBAR.TAGS' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
