import { DragDropModule } from "@angular/cdk/drag-drop"
import { CommonModule, NgComponentOutlet } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { RouterModule } from "@angular/router"
// BOOTSTRAP COMPONENTS
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { faEllipsisV, faLightbulb, faWrench } from "@fortawesome/free-solid-svg-icons"
import { LoadingBarRouterModule } from "@ngx-loading-bar/router"
import { CalendarModule, DateAdapter } from "angular-calendar"
import { adapterFactory } from "angular-calendar/date-adapters/date-fns"
import { RoundProgressModule } from "angular-svg-round-progressbar"
import { QRCodeModule } from "angularx-qrcode"
import { HclLibModule } from "hcl-lib"
import { SelectDropDownModule } from "ngx-select-dropdown"
import { NgxMatTimepickerModule } from "ngx-mat-timepicker"
import { ImageCropperModule } from "ngx-image-cropper"
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'

@NgModule({
  imports: [
    HclLibModule,
    CommonModule,
    DragDropModule,
    LoadingBarRouterModule,
    // Angular Bootstrap Components
    QRCodeModule,
    FontAwesomeModule,
    RoundProgressModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SelectDropDownModule,
    HttpClientModule,
    NgxMatTimepickerModule,
    ImageCropperModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [provideCharts(withDefaultRegisterables())]
})
export class HumecloudCommonModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faEllipsisV,
      faLightbulb,
      faWrench
    )
  }
}
