<div class="location-details">
  <div class="location-details_header" *ngIf="locationForm">
    <div class="location-details_header_upper">
      <div class="location-details_header_upper_title">
        <mat-icon (click)="doGoBack()">arrow_back_ios_new</mat-icon>
        {{ "LOCATION.DETAILS.TITLE" | translate | uppercase }} : {{ location?.name | uppercase }}
      </div>
      <div class="=location-details_header_upper_buttons">
        <button mat-button class="hcl-button-primary" (click)="doSave()" [disabled]="!locationForm.valid"
                *cmnIfPerm="['cmn:location:update']">
          <mat-icon>done</mat-icon>
          {{ 'LOCATION.DETAILS.ACTIONS.SAVE' | translate }}
        </button>
        <button mat-button class="hcl-button-primary" (click)="doOpenCreate()" *cmnIfPerm="['cmn:location:create']">
          <mat-icon>add_circle</mat-icon>
          {{ 'LOCATION.DETAILS.ACTIONS.CREATE' | translate }}
        </button>
        <button mat-button class="hcl-button-delete" (click)="doDelete()" *cmnIfPerm="['cmn:location:delete']">
          <mat-icon>delete</mat-icon>
          {{ 'LOCATION.DETAILS.ACTIONS.DELETE' | translate }}
        </button>
      </div>
    </div>
    <div class="location-details_header_ancestors">
      <div class="location-details_header_ancestors_item" *ngFor="let ancestor of ancestorLocations"
           [routerLink]="['/locations/', ancestor.id]">
        <div class="location-details_header_ancestors_item_inner">
          <div class="location-details_header_ancestors_item_inner_title">
            {{ ancestor.name }}
          </div>
        </div>
      </div>
      <div class="location-details_header_ancestors_item">
        <span class="location-details_header_ancestors_item_inner">
          <span class="location-details_header_ancestors_item_inner_title_actual">
            {{ location?.name | uppercase }}
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="location-details_body">
    <form *ngIf="locationForm" class="location-details_body_form" [formGroup]="locationForm">
      <div class="location-details_body_form_title">
        {{ 'LOCATION.DETAILS.EDIT_DETAILS_TITLE' | translate }}
      </div>

      <!-- Name Field -->
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.NAME' | translate }}</mat-label>
        <input matInput formControlName="name" [placeholder]="'LOCATION.FIELDS.NAME' | translate">
      </mat-form-field>

      <!-- Description Field -->
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.DESCRIPTION' | translate }}</mat-label>
        <input matInput formControlName="description" [placeholder]="'LOCATION.FIELDS.DESCRIPTION' | translate">
      </mat-form-field>

      <!-- Type Field -->
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.TYPE' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let locationType of locationTypes" [value]="locationType">
            {{ ("LOCATION.LOCATION_TYPE." + locationType) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- tags -->
      <mat-form-field>
        <mat-label>{{ 'LOCATION.FIELDS.TAGS' | translate }}</mat-label>
        <mat-select formControlName="tagIds" multiple (openedChange)="onOpenLocationTag($event)">
          <input #searchLocationTag matInput type="text" (input)="onSearchLocationTag($event)" autocomplete="off">
          <mat-divider></mat-divider>
          <mat-option *ngFor="let tag of filteredLocationTags" [value]="tag.id">{{ tag.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Additional Fields Based on Type -->
      <!-- Hotel Fields -->
      <ng-container *ngIf="locationForm.get('type')?.value === 'HOTEL'">
        <mat-form-field>
          <mat-label>{{ 'LOCATION.FIELDS.HOTEL.ADDRESS' | translate }}</mat-label>
          <input matInput formControlName="address" [placeholder]="'LOCATION.FIELDS.HOTEL.ADDRESS' | translate">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'LOCATION.FIELDS.HOTEL.CITY' | translate }}</mat-label>
          <input matInput formControlName="city" [placeholder]="'LOCATION.FIELDS.HOTEL.CITY' | translate">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'LOCATION.FIELDS.HOTEL.POSTAL_CODE' | translate }}</mat-label>
          <input matInput formControlName="postalCode" [placeholder]="'LOCATION.FIELDS.HOTEL.POSTAL_CODE' | translate">
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'LOCATION.FIELDS.HOTEL.COUNTRY' | translate }}</mat-label>
          <input matInput formControlName="country" [placeholder]="'LOCATION.FIELDS.HOTEL.COUNTRY' | translate">
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="['ROOM', 'MEETING_ROOM', 'BEDROOM', 'FLOOR'].includes(locationForm.get('type')?.value)">
        <mat-form-field>
          <mat-label>{{ 'LOCATION.FIELDS.NUMBER' | translate }}</mat-label>
          <input matInput type="number" formControlName="number" [placeholder]="'LOCATION.FIELDS.NUMBER' | translate">
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="['ROOM', 'MEETING_ROOM', 'BEDROOM'].includes(locationForm.get('type')?.value)">
        <mat-checkbox formControlName="available">{{ 'LOCATION.FIELDS.AVAILABLE' | translate }}</mat-checkbox>
      </ng-container>

      <!-- Common Area Fields -->
      <ng-container *ngIf="locationForm.get('type')?.value === 'COMMON_AREA'">
        <!-- Nothing for the moment -->
      </ng-container>

      <!-- Other Fields -->
      <ng-container *ngIf="locationForm.get('type')?.value === 'OTHER'">
        <!-- Nothing for the moment -->
      </ng-container>
    </form>
    <div class="location-details_body_child">
      <mat-table [dataSource]="locationDataSource" matSort class="location-details_body_child_list humecloud-list">

        <!-- type -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOCATION.LIST.LABEL.TYPE' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <div class="location-details_body_child_list_location-type">
              <div class="location-details_body_child_list_location-type_text" [ngSwitch]="location.type">
                <ng-container *ngSwitchCase="LocationType.HOTEL">
                  <span>{{ 'LOCATION.LOCATION_TYPE.HOTEL' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.FLOOR">
                  <span>{{ 'LOCATION.LOCATION_TYPE.FLOOR' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.ROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.ROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.MEETING_ROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.MEETING_ROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.BEDROOM">
                  <span>{{ 'LOCATION.LOCATION_TYPE.BEDROOM' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.COMMON_AREA">
                  <span>{{ 'LOCATION.LOCATION_TYPE.COMMON_AREA' | translate }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="LocationType.OTHER">
                  <span>{{ 'LOCATION.LOCATION_TYPE.OTHER' | translate }}</span>
                </ng-container>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOCATION.LIST.LABEL.NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let location">
            <div class="location-details_body_child_list_item">
              <span class="location-details_body_child_list_item_name">{{ location.name }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let location">
            <button mat-icon-button [routerLink]="['/locations/', location.id]">
              <mat-icon>open_in_browser</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage()"></mat-paginator>
    </div>
  </div>
</div>
