import { CmnHideIfNoChildrenDirective } from './../../../directives/cmn-hide-if-no-children/cmn-hide-if-no-children.directive';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {Store} from "@ngrx/store";
import {CmnIfPermDirective, HumecloudRoles, HumecloudState, ScopeService, SimpleAppUtil} from 'hcl-lib';
import {MultiSelectComponent} from 'ng-multiselect-dropdown';
import {Observable} from 'rxjs';
import {ThemeOptions} from '../../../../theme-options';
import {AppService} from "../../../services/app/app.service";
import {WhitelabelService} from '../../../whitelabel/whitelabel.service';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { NgbAccordionModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CmnIfSimpleAppDirective } from '../../../directives/cmn-if-simple-app/cmn-if-simple-app.directive';
import { CmnIfAppDirective } from '../../../directives/cmn-if-app/cmn-if-app.directive';
import { CmnIfPluginDirective } from '../../../directives/cmn-if-plugin/cmn-if-plugin.directive';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, NgClass, AsyncPipe, NgbAccordionModule, NgbOffcanvasModule, TranslateModule, CmnIfPermDirective, CmnIfSimpleAppDirective, CmnIfAppDirective, CmnIfPluginDirective, CmnHideIfNoChildrenDirective]
})
export class SidebarComponent implements OnInit {
  public activeMenuId: string = '';
  scopeService: ScopeService
  whitelabelService: WhitelabelService

  @ViewChild(MultiSelectComponent, { static: true }) multiSelectComponent!: MultiSelectComponent

  ADMIN_ROLE = HumecloudRoles.ADMIN
  SUPERADMIN_ROLE = HumecloudRoles.SUPERADMIN
  SCREENLAB_ADMIN_ROLE = HumecloudRoles.SCREENLAB_ADMIN
  SCREENLAB_LIGHT_DESIGNER_ROLE = HumecloudRoles.SCREENLAB_LIGHT_DESIGNER
  SCREENLAB_LIGHT_DESIGNER_IMAGES_ROLE = HumecloudRoles.SCREENLAB_LIGHT_DESIGNER_IMAGES
  SCREENLAB_LIGHT_DESIGNER_VIDEOS_ROLE = HumecloudRoles.SCREENLAB_LIGHT_DESIGNER_VIDEOS

  isMobile!: boolean;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    scopeService: ScopeService,
    private store: Store<HumecloudState>,
    private simpleAppUtil: SimpleAppUtil,
    whitelabelService: WhitelabelService,
  ) {
    this.scopeService = scopeService
    this.whitelabelService = whitelabelService
  }


  public config$!: Observable<any>;

  private newInnerWidth!: number;
  private innerWidth!: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    this.config$ = this.store.select('config')

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.simpleAppUtil.getSimpleAppName() != null || this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });
    this.activeMenuId = this.findActiveMenuId();

    this.checkIfMobile();
  }

  private findActiveMenuId() {
    let snapshot = this.activatedRoute.snapshot;
    if (snapshot && snapshot.firstChild) {
      let routeData = snapshot.firstChild.data;
      return (routeData && routeData['menuId']) ? routeData['menuId'] : '';
    } else {
      return '';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.newInnerWidth = event.target.innerWidth;
    this.globals.toggleSidebar = this.newInnerWidth < 1200;
    this.checkIfMobile();
  }

  getAppDisplayNameHtml(appName: string): string {
    return this.appService.getAppDisplayNameHtml(appName)
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 801;
  }
}
