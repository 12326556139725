import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Schedule } from "projects/hcl-portal/src/app/screenlab/interfaces/schedule"
import { ScheduleService } from "projects/hcl-portal/src/app/screenlab/services/schedule/schedule.service"
import { of, Subscription } from "rxjs"
import { catchError } from "rxjs/operators"
import { Device } from "../../../../interfaces/device"
import { DeviceStatus } from "../../../../interfaces/device-status"
import { DeviceService } from "../../../../services/device/device.service"
import { DatePipe, NgIf, NgSwitch, NgSwitchCase } from "@angular/common"
import { MatCardModule } from "@angular/material/card"
import { TranslateModule } from "@ngx-translate/core"
import { RouterModule } from "@angular/router"
import { DeviceStatusComponent } from "../device-status/device-status.component"

enum ScheduleState {
  LOADING,
  NONE,
  SCHEDULE,
  PARENT_SCHEDULE,
  SVD_SCHEDULE
}

@Component({
    selector: 'app-device-details-status',
    templateUrl: './device-details-status.component.html',
    styleUrls: ['./device-details-status.component.scss'],
    standalone: true,
    imports: [RouterModule, DatePipe, NgIf, NgSwitch, NgSwitchCase, MatCardModule, TranslateModule, DeviceStatusComponent]
})
export class DeviceDetailsStatusComponent implements OnInit, OnDestroy {

  @Input() device!: Device

  subscriptions: Subscription = new Subscription()

  status!: DeviceStatus
  schedule!: Schedule

  scheduleState: ScheduleState = ScheduleState.LOADING
  ScheduleStates = ScheduleState

  constructor(
    private deviceService: DeviceService,
    private scheduleService: ScheduleService
  ) { }

  ngOnInit(): void {
    this.retrieveDeviceStatus()
    if (this.device.svdConfig && this.device.svdConfig.enabled) {
      this.scheduleState = ScheduleState.SVD_SCHEDULE
    } else {
      this.retrieveSchedule()
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDeviceStatus(): void {
    this.subscriptions.add(
      this.deviceService.getDeviceStatus(this.device.id).pipe(
        catchError(_ => of(null))
      ).subscribe(status => {
        if (status !== null) {
          this.status = status
        }
      })
    )
  }

  retrieveSchedule(): void {
    this.subscriptions.add(
      this.scheduleService.getScheduleByDevice(this.device.id).pipe(
        catchError(_ => of(null))
      ).subscribe(schedule => {
        if (schedule) {
          this.schedule = schedule
          if (this.schedule.customerId == this.device.customerId) {
            this.scheduleState = ScheduleState.SCHEDULE
          } else {
            this.scheduleState = ScheduleState.PARENT_SCHEDULE
          }
        } else {
          this.scheduleState = ScheduleState.NONE
        }
      })
    )
  }
}
