<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <!--<img width="42" src="./assets/images/avatars/1.jpg" alt="" class="rounded-circle">-->
                  <fa-icon [icon]="faUserCircle" size="3x"></fa-icon>
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <!--<img width="42" src="./assets/images/avatars/1.jpg" alt="" class="rounded-circle">-->
                          <fa-icon [icon]="faUserCircle" size="2x"></fa-icon>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ username }}</div>
                          <div class="widget-subheading opacity-8"></div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="logout()">{{'LOG_OUT' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="user-box-content">
                <ul class="nav flex-column">
                  <li class="nav-item"><a (click)="goToAccountPage()" class="nav-link user-box-content__page-link" ngbDropdownToggle>
                    {{ 'MY_USER' | translate }}</a></li>
                  <li class="nav-item"><a (click)="goToCustomerPage()" class="nav-link user-box-content__page-link" ngbDropdownToggle>
                    {{ 'MY_COMPANY' | translate }}</a></li>
                  <li class="nav-item"><a (click)="goToMyPluginsPage()" class="nav-link user-box-content__page-link" ngbDropdownToggle>
                    {{ 'MY_PLUGINS' | translate }}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
