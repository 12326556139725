import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CollectionUtil, ScopeService } from 'hcl-lib';
import { Subscription } from "rxjs";

export enum CmnIfAppOperator {
  AND,
  OR
}

@Directive({
    selector: '[cmnIfApp]',
    standalone: true
})
export class CmnIfAppDirective implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  private hasView = false;
  private appNames: Set<string> = new Set<string>();
  private operator: CmnIfAppOperator = CmnIfAppOperator.OR;
  private custAppNames: string[] = []

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopeService: ScopeService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.scopeService.getAppNamesObservable().subscribe(custAppNames => {
        this.custAppNames = custAppNames
        this.updateVisiblity()
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  set cmnIfApp(newAppNames: string[]) {
    let newAppNamesSet = new Set<string>();
    if (newAppNames && newAppNames.length > 0) {
      for (let newAppName of newAppNames) {
        newAppNamesSet.add(newAppName);
      }
    }

    if (CollectionUtil.setsEqual(newAppNamesSet, this.appNames)) {
      return;
    }
    this.appNames = newAppNamesSet;
    this.updateVisiblity();
  }

  @Input("cmnIfAppOp")
  set cmnIfAppOp(newOperatorStr: string) {
    let newOperator = newOperatorStr.toLowerCase() === 'and' ? CmnIfAppOperator.AND : CmnIfAppOperator.OR;
    if (newOperator === this.operator) {
      return;
    }

    this.operator = newOperator;
    this.updateVisiblity();
  }

  private updateVisiblity() {
    let custAppNames = this.custAppNames
    let appNames = this.appNames;
    let operator = this.operator;
    let hasOneApp = appNames.size == 0 || (custAppNames.length > 0 && custAppNames.find(appName => appNames.has(appName)) != null);
    let hasAllApps = appNames.size == 0 || Array.from(appNames).every(appName => custAppNames.find(custAppName => custAppName === appName) != null);
    let showElt = operator === CmnIfAppOperator.OR ? hasOneApp : hasAllApps;
    if (showElt && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!showElt && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
