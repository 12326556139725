import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HumecloudApps, SimpleAppUtil } from "hcl-lib";
import { Observable } from 'rxjs';


@Injectable()
export class AppSimpleAppGuard  {

  private evaluated = false

  constructor(
    private router: Router,
    private simpleAppUtil: SimpleAppUtil
  ) {
    // do nothing
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.evaluated) return true

    let simpleAppName = this.simpleAppUtil.getSimpleAppName();
    switch(simpleAppName) {
      case HumecloudApps.HUMEHEALTH: {
        this.router.navigate(["/" + HumecloudApps.HUMEHEALTH])
        break;
      }
      case HumecloudApps.SCREENLAB: {
        this.router.navigate(["/" + HumecloudApps.SCREENLAB])
        break;
      }
      default: {
        // do nothing
        break;
      }
    }
    this.evaluated = true
    return true
  }
}
