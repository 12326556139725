import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Role } from 'hcl-lib';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { CustomerRoles } from 'projects/hcl-portal/src/app/common/interfaces/customer-roles';
import { CustomerRolesSelect } from 'projects/hcl-portal/src/app/common/interfaces/customer-roles-select';
import { Subscription } from 'rxjs';

@Component({
    selector: 'customer-roles-select',
    templateUrl: './customer-roles-select.component.html',
    styleUrls: ['./customer-roles-select.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, MatChipsModule, TranslateModule, NgMultiSelectDropDownModule]
})
export class CustomerRolesSelectComponent implements OnInit, OnDestroy {

  @Input() customerRolesSelect!: CustomerRolesSelect;
  @Output() update: EventEmitter<CustomerRoles> = new EventEmitter();

  subscription: Subscription = new Subscription();

  roles!: Role[];
  selectedRoles: Role[] = [];
  dropdownRolesSettings: IDropdownSettings = {};

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.initializeRoles();
    this.initializeRolesDropdownSettings();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeRoles() {
    if (this.customerRolesSelect) {
      this.roles = this.customerRolesSelect.roles;
      this.selectedRoles = this.customerRolesSelect.rolesAssociatedToCustomer;
    }
  }

  onRolesModified(role?: ListItem) {
    this.update.emit({ customerId: this.customerRolesSelect.customer.id as string, roleIds: this.selectedRoles.map(role => { return role.id }) });
  }

  onSelectAllRoles(roles: any[]) {
    this.update.emit({ customerId: this.customerRolesSelect.customer.id as string, roleIds: roles.map(role => { return role.id }) });
  }

  onDeSelectAllRoles(roles?: any[]) {
    this.update.emit({ customerId: this.customerRolesSelect.customer.id as string, roleIds: [] });
  }

  initializeRolesDropdownSettings() {
    this.dropdownRolesSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translateService.instant('CUSTOMER_ROLES_SELECT_COMPONENT.SELECT_ALL'),
      unSelectAllText: this.translateService.instant('CUSTOMER_ROLES_SELECT_COMPONENT.UNSELECT_ALL'),
      enableCheckAll: true,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
