<div class="registration-components">
  <mat-card-header>
    <mat-card-title>{{ 'SELF_REGISTRATION_COMPONENT.TITLE' | translate}}</mat-card-title>
  </mat-card-header>
  <div class="app-user-box-parent">
    <app-user-box class="app-user-box"></app-user-box>
  </div>
  <customer-fields [parentForm]="customerForm" [namePlaceholder]="namePlaceholder" class="customer-fields"
    align="center">
  </customer-fields>
  <customer-admin-fields class="customer-fields" [parentForm]="customerForm" [apps]="[]" [sellingCompanies]="[]" align="right">
  </customer-admin-fields>
  <mat-card-actions>
    <button mat-raised-button color="accent" [disabled]="customerForm.invalid" (click)="createUser()">
      <mat-icon>done</mat-icon>
      {{'SAVE' | translate}}
    </button>
    <loader></loader>
  </mat-card-actions>
</div>
