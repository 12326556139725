import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Device } from '../../../interfaces/device';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';

const resolvedPromise = Promise.resolve(null);

@Component({
    selector: 'app-device-svd',
    templateUrl: './device-svd.component.html',
    styleUrls: ['./device-svd.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, TranslateModule]
})
export class DeviceSvdComponent implements OnInit {

  @Input() device!: Device
  @Output() formGroup: EventEmitter<UntypedFormGroup> = new EventEmitter()

  svdForm!: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.svdForm = this.formBuilder.group({
      enabled: [false, Validators.required],
      url: [""],
      playerId: [""],
      login: [""],
      password: [""],
      passwordInput: [""],
      updatePeriodInSeconds: [300],
      backgroundColor: [null]
    })
    if (this.device.svdConfig) {
      this.svdForm.patchValue(this.device.svdConfig)
    }
    resolvedPromise.then(() => {
      this.formGroup.emit(this.svdForm)
    })
  }
}
