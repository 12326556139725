import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { CmnIfPermDirective, Customer, CustomerService, CustomerNames, HumecloudState, KeycloakService, ScopeService } from 'hcl-lib';
import { Observable, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { ThemeOptions } from '../../../../theme-options';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { RecentSearchSelectComponent } from '../widget/recent-search-select/recent-search-select.component';
import { LicenseStatusComponent } from './elements/license-status/license-status.component';
import { NotificationsComponent } from './elements/notifications/notifications.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GuidesComponent } from './elements/guides/guides.component';
import { DotsComponent } from './elements/dots/dots.component';
import { UserBoxComponent } from './elements/user-box/user-box.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, AsyncPipe, MatButtonModule, MatIconModule, FontAwesomeModule, CmnIfPermDirective, RecentSearchSelectComponent, LicenseStatusComponent, NotificationsComponent, GuidesComponent, DotsComponent, UserBoxComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  selected!: Customer
  recentCustomers: Customer[] = []
  foundCustomers: CustomerNames[] = []
  showCustomerSettings = false

  faEllipsisV = faEllipsisV

  constructor(
    public globals: ThemeOptions,
    private store: Store<HumecloudState>,
    private customerService: CustomerService,
    private keycloakService: KeycloakService,
    private scopeService: ScopeService,
    private router: Router
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.config$ = this.store.select('config')
    this.subscription.add(
      this.scopeService.getScopeCustomer().pipe(
        tap(customer => {
          this.showCustomerSettings = this.keycloakService.getCustomerIds()[0] != customer?.id
        }),
        mergeMap(customer => this.initCustomerSelect(customer as Customer))
      ).subscribe()
    )
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive!: boolean;

  public config$!: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  initCustomerSelect(customer: Customer): Observable<any> {
    this.selected = customer
    return this.scopeService.getRecentCustomers().pipe(
      tap(customers => this.recentCustomers = customers)
    )
  }

  selectCustomer(customerNames: CustomerNames): void {
    this.subscription.add(
      this.customerService.getCustomer(customerNames.id).pipe(
        mergeMap((customer) => {
          this.scopeService.updateScope(customer)
          return this.initCustomerSelect(customer)
        })
      ).subscribe(_ => this.router.navigate(["/"]))
    )
  }

  searchCustomer(search: string): void {
    this.subscription.add(
      this.customerService.getAvailableCustomers(0, 5, search).subscribe(result => {
        this.foundCustomers = result
      })
    )
  }

  navigateToCurrentCustomer() {
    this.router.navigate(["customers/" + this.selected?.id])
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
