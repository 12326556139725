<h1 mat-dialog-title>
  <span [class]="'dot dot-' + event.severity">
    {{ "EVENT.SEVERITY." + event.severity | translate }}
  </span>
  <span>{{ event.label }}</span>
</h1>
<mat-dialog-content>
  <div>{{ event.at | date:'medium' }}</div>
  <div>{{ event.service }}</div>
  <div>{{ translation?.subject }}</div>
  <div>{{ translation?.message }}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
</mat-dialog-actions>