export enum DeviceActionType {
  CLEAR_DATA = "CLEAR_DATA",
  SEND_LOGS = "SEND_LOGS",
  TUNNEL = "TUNNEL"
}

export enum TunnelDeviceActionStatus {
  CLOSE = "CLOSE",
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  OPENED = "OPENED"
}

export abstract class DeviceAction {
  id?: string
  readonly type!: DeviceActionType
  deviceId!: string
}

export class ClearDataDeviceAction extends DeviceAction {
  override readonly type: DeviceActionType = DeviceActionType.CLEAR_DATA
}

export class SendLogsDeviceAction extends DeviceAction {
  override readonly type: DeviceActionType = DeviceActionType.SEND_LOGS
}

export class TunnelDeviceAction extends DeviceAction {
  override readonly type: DeviceActionType = DeviceActionType.TUNNEL
  port!: number
  status!: TunnelDeviceActionStatus
}
