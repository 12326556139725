import { createReducer, on } from "@ngrx/store";
import { getConfig, updateConfig } from "./config.actions";

const INITIAL_STATE = {
  headerTheme: '',
  sidebarTheme: '',
}

const _configReducer = createReducer(
  INITIAL_STATE,
  on(getConfig, (state) => Object.assign({}, state)),
  on(updateConfig, (state, { payload }) => Object.assign({}, state, { ...payload }))
);

export function configReducer(state: any, action: any) {
  return _configReducer(state, action);
}
