import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StripeReader} from "../../../../interfaces/stripe-reader";
import {StripeReaderModalComponent} from "../stripe-reader-modal/stripe-reader-modal.component";
import {Subscription} from "rxjs";
import {StripeService} from "../../../../services/stripe/stripe.service";
import {MatDialog} from "@angular/material/dialog";
import {Device} from "../../../../interfaces/device";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {VerifoneReader} from "../../../../interfaces/verifone-reader";
import {VerifoneReaderModalComponent} from "../verifone-reader-modal/verifone-reader-modal.component";
import {PaymentTerminalType, PeripheralConfig} from "../../../../interfaces/peripheral-config";
import {VerifoneReaderService} from "../../../../services/verifone-reader/verifone-reader.service";
import {mergeMap} from "rxjs/operators";
import {SettingService} from "../../../../../humetips/services/settings/setting.service";
import {CmnIfPermDirective, ScopeService} from "hcl-lib";
import {environment} from "../../../../../../environments/environment";
import {StripeLocation} from "../../../../interfaces/stripe-location";
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { KioskApplication } from '../../../../interfaces/kiosk-config';

@Component({
    selector: 'app-device-peripheral',
    templateUrl: './device-peripheral.component.html',
    styleUrls: ['./device-peripheral.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule, MatIconModule, MatDividerModule, TranslateModule, CmnIfPermDirective]
})
export class DevicePeripheralComponent implements OnInit, OnDestroy {

  @Input() device!: Device
  @Output() formGroup: EventEmitter<UntypedFormGroup> = new EventEmitter()

  subscriptions: Subscription = new Subscription()

  PaymentTerminalType: typeof PaymentTerminalType = PaymentTerminalType

  peripheralConfig?: PeripheralConfig
  currentStripeReader: StripeReader | null = null
  currentVerifoneReader: VerifoneReader | null = null

  peripheralConfigForm: UntypedFormGroup = this.formBuilder.group({
    paymentTerminalType: PaymentTerminalType.NONE,
    stripeSdkConfig: this.formBuilder.group({
      stripeLocationId: null,
      stripeSDKConnectionMethod: null
    }),
    stripeReaderId: null,
    verifoneReaderId: null
  })

  stripeTestMode: boolean = false

  get paymentTerminalType() {
    return this.peripheralConfigForm.get('paymentTerminalType')?.value
  }

  constructor(
    private humetipsSettingsService: SettingService,
    private scopeService: ScopeService,
    private stripeReaderService: StripeService,
    private verifoneReaderService: VerifoneReaderService,
    private matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.peripheralConfig = this.device.peripheralConfig
    this.checkPeripheralConfig()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  checkPeripheralConfig(): void {
    if (this.peripheralConfig == null) {
      this.peripheralConfig = {
        paymentTerminalType: PaymentTerminalType.NONE,
      }
    }

    this.peripheralConfigForm = this.formBuilder.group({
      paymentTerminalType: this.peripheralConfig.paymentTerminalType,
      stripeSdkConfig: this.formBuilder.group({
        stripeLocationId: this.peripheralConfig.stripeSdkConfig?.stripeLocationId,
        stripeSDKConnectionMethod: this.peripheralConfig.stripeSdkConfig?.stripeSDKConnectionMethod
      }),
      stripeReaderId: this.peripheralConfig.stripeReaderId,
      verifoneReaderId: this.peripheralConfig.verifoneReaderId,
    })

    this.formGroup.emit(this.peripheralConfigForm)
    this.retrieveReaderInformations()
  }

  retrieveReaderInformations() {
    switch (this.paymentTerminalType) {
      case PaymentTerminalType.STRIPE_CLOUD:
        this.retrieveStripeReaderById()
        this.getHumetipsSettings()
        break
      case PaymentTerminalType.STRIPE_SDK:
        this.getHumetipsSettings()
        this.getStripeLocations()
        break
      case PaymentTerminalType.VERIFONE:
        this.retrieveVerifoneReaderById()
        break
    }
  }

  openVerifoneReaderDialog(): void {
    const dialogRef = this.matDialog.open(VerifoneReaderModalComponent, {
      width: "500px",
      disableClose: true,
      data: {
        device: this.device,
        currentVerifoneReader: this.currentVerifoneReader
      },
    });
    dialogRef.afterClosed().subscribe((reader: VerifoneReader) => {
      if (reader != null) {
        this.currentVerifoneReader = reader
        this.peripheralConfigForm.patchValue({'verifoneReaderId': reader ? reader.id : null})
        this.retrieveVerifoneReaderById()
      }
    });
  }

  openStripeReaderDialog(): void {
    const dialogRef = this.matDialog.open(StripeReaderModalComponent, {
      width: "500px",
      data: {
        device: this.device,
        currentStripeReader: this.currentStripeReader
      },
    });
    dialogRef.afterClosed().subscribe((reader: StripeReader) => {
      if (reader != null) {
        this.currentStripeReader = reader
        this.peripheralConfigForm.patchValue({'stripeReaderId': reader ? reader.id : null})
        this.retrieveReaderInformations()
      }
    });
  }

  test() {
    console.log(this.peripheralConfigForm)
  }

  retrieveStripeReaderById(): void {
    let id = this.peripheralConfigForm.get('stripeReaderId')?.value
    if (id != null) {
      this.subscriptions.add(
        this.stripeReaderService.getStripeReader(
          id,
          this.device.kioskConfig?.application as KioskApplication
        ).subscribe(reader => {
          this.currentStripeReader = reader
        })
      )
    }
  }

  retrieveVerifoneReaderById(): void {
    let id = this.peripheralConfigForm.get('verifoneReaderId')?.value
    if (id != null) {
      this.subscriptions.add(
        this.verifoneReaderService.getVerifoneReader(id).subscribe(reader => {
          this.currentVerifoneReader = reader
        })
      )
    }
  }

  remove(): void {
    this.peripheralConfigForm.patchValue({
      paymentTerminalType: PaymentTerminalType.NONE,
      stripeSdkConfig: null,
      verifoneReaderId: null,
      stripeReaderId: null
    })
    this.currentVerifoneReader = null
    this.currentStripeReader = null
  }

  getHumetipsSettings() {
    if (environment.production) {
      this.subscriptions.add(this.scopeService.getAppNamesObservable().pipe(
        mergeMap(apps => {
          const app = apps.includes('humetips') ? 'humetips' : 'humecharity'
          return this.humetipsSettingsService.getSettings(app)
        })
      ).subscribe(settings => {
        this.stripeTestMode = settings.enableStripeTestMode
      }))
    } else this.stripeTestMode = true
  }

  locations: StripeLocation[] | null = []

  getStripeLocations() {
    this.subscriptions.add(this.stripeReaderService.getStripeLocation(this.device.kioskConfig?.application as KioskApplication).subscribe(locations => {
      this.locations = locations
      console.log(locations)
    }))
  }
}
