import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {StripeReader} from "../../../../interfaces/stripe-reader";
import {Subscription} from "rxjs";
import {StripeService} from "../../../../services/stripe/stripe.service";
import {Device} from "../../../../interfaces/device";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StripeReaderModal} from "../../../../interfaces/stripe-reader.modal";
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import { NgFor, NgIf } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CmnIfPermDirective } from 'hcl-lib';
import { KioskApplication } from '../../../../interfaces/kiosk-config';

@Component({
    selector: 'app-stripe-reader-modal',
    templateUrl: './stripe-reader-modal.component.html',
    styleUrls: ['./stripe-reader-modal.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, MatSelectModule, MatDividerModule, MatButtonModule, MatIconModule, MatDialogModule, TranslateModule, CmnIfPermDirective]
})
export class StripeReaderModalComponent implements OnInit, OnDestroy {

  @ViewChild("searchStripeReader") searchStripeReader!: ElementRef
  subscriptions: Subscription = new Subscription()

  device!: Device
  stripeReaders: StripeReader[] = []
  filteredStripeReaders!: StripeReader[]
  currentStripeReader?: StripeReader
  selectedStripeReader: StripeReader | null = null

  stripeReaderForm: UntypedFormGroup = this.formBuilder.group({
    showOnlyNotPairedReader: true,
  })

  constructor(
    private stripeReaderService: StripeService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<StripeReaderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripeReaderModal,
  ) {
  }

  ngOnInit(): void {
    this.device = this.data.device
    this.currentStripeReader = this.data.currentStripeReader

    this.getStripeReaders()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }


  getStripeReaders(updateAll: boolean = false): void {
    let showOnlyPairedReader = this.stripeReaderForm.get('showOnlyNotPairedReader')?.value

    this.subscriptions.add(this.stripeReaderService.getStripeReaders(
      this.device.kioskConfig?.application as KioskApplication,
      updateAll,
      showOnlyPairedReader,
    ).subscribe(readers => {
      if (this.currentStripeReader) {
        const index = readers.findIndex(r => r.id == this.currentStripeReader?.id);
        if (index > -1) {
          readers.splice(index, 1);
        }
      }
      this.stripeReaders = readers
    }))
  }

  onOpenStripeReader(open: boolean): void {
    if (open) {
      this.searchStripeReader.nativeElement.focus()
    } else {
      this.searchStripeReader.nativeElement.value = ''
      this.onSearchStripeReader(undefined)
    }
  }

  onChangeStripeReader(reader: StripeReader): void {
    this.selectedStripeReader = reader
  }

  validate(): void {
    if (this.currentStripeReader && !this.selectedStripeReader) {
      this.dialogRef.close(this.currentStripeReader)
    } else if (this.selectedStripeReader) {
      this.dialogRef.close(this.selectedStripeReader)
    }
  }

  onSearchStripeReader(event: Event | undefined) {
    const value = (event?.target as HTMLInputElement).value
    this.filteredStripeReaders = value ?
      this.stripeReaders.filter(stripeReader => stripeReader.label.toLowerCase().includes(value.toLowerCase()))
      : this.stripeReaders.slice()
  }
}
