import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CmnIfPermDirective,
  CustomerService,
  DeleteDialogComponent,
  QueryScope,
  SnackBarService,
  Tag,
  TagService,
  TargetType
} from "hcl-lib";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectChange, MatSelectModule} from "@angular/material/select";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe} from "@angular/common";
import {IDropdownSettings, NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EMPTY, from, Observable, of, Subscription, zip} from "rxjs";
import {Location, LocationType} from "../../../interfaces/location";
import {catchError, distinct, map, mergeMap, tap, toArray} from "rxjs/operators";
import {LocationService} from "../../../services/location/location.service";
import {LocationCreateDialogComponent} from "../location-create-dialog/location-create-dialog.component";

@Component({
  selector: "app-location-list",
  templateUrl: "./location-list.component.html",
  styleUrls: ["./location-list.component.scss"],
  standalone: true,
  imports: [RouterModule, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatButtonModule, MatIconModule, MatTooltipModule, MatTableModule, MatSortModule, MatPaginatorModule, MatGridListModule, MatMenuModule, MatCardModule, TranslateModule, CmnIfPermDirective, NgMultiSelectDropDownModule]
})
export class LocationListComponent implements AfterViewInit, OnDestroy {

  constructor(
    private locationService: LocationService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private snackService: SnackBarService,
    private tagService: TagService,
    private customerService: CustomerService,
    private chRef: ChangeDetectorRef
  ) {
    // do nothing
  }

  subscriptions: Subscription = new Subscription()

  locationDataSource = new MatTableDataSource<Location>()
  columnsToDisplay = ["type", "name", "ancestors", "actions"]

  locationSearch: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  })

  dropdownTagsSettings: IDropdownSettings = {}
  tags!: Tag[]
  tagsAdded: Tag[] = []

  selectedScope = QueryScope.CUSTOMER

  LocationType: typeof LocationType = LocationType
  locationTypes: LocationType[] = Object.values(LocationType);
  selectedLocationTypes: LocationType[] = [];

  locationCustomerNames: {[locationId: string]: string} = {}

  QueryScopes = [
    QueryScope.CUSTOMER,
    QueryScope.CUSTOMER_AND_CHILDREN
  ]

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: false }) sort!: MatSort

  ngAfterViewInit(): void {
    this.locationDataSource.sort = this.sort
    this.retrieveLocations()

    this.initializeTagsDropdownSettings()

    this.subscriptions.add(this.tagService.getAvailableTags([TargetType.LOCATION]).subscribe(tags => {
      this.tags = tags
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onSearch($event: any) {
    this.paginator.firstPage()
    this.retrieveLocations()
  }

  handlePage(): void {
    this.retrieveLocations()
  }

  retrieveLocations(): void {
    const tagIds = this.tagsAdded.map(tagAdded => tagAdded.id as string);

    this.subscriptions.add(
      this.locationService.getLocationsWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        tagIds,
        this.selectedScope,
        this.selectedLocationTypes,
        this.locationSearch.value.search,
      ).pipe(
        tap(invocationResult => {
          this.locationDataSource.data = invocationResult.data;
          this.paginator.length = invocationResult.pagination?.count as number;
        }),
        mergeMap(invocationResult => from(invocationResult.data) as Observable<Location>),

        map((location: Location) => location.customerId),
        distinct(),

        mergeMap(customerId =>
          this.customerService.getCustomer(customerId!).pipe(
            catchError(() => EMPTY)
          )
        ),
        toArray(),

        mergeMap(customers => {
          const ancestorLocationIds = Array.from(new Set(
            this.locationDataSource.data.flatMap(location => location.ancestorLocationIds)
          )).filter((id): id is string => id !== undefined);

          const ancestorLocations$ = this.locationService.getLocationsByIds(ancestorLocationIds).pipe(
            catchError(() => of([]))
          );

          return zip(of(customers), ancestorLocations$);
        })
      ).subscribe(([customers, ancestorLocations]) => {
        this.locationCustomerNames = customers.reduce((accumulator: { [customerId: string]: string }, currentValue) => {
          if (currentValue.id) {
            accumulator[currentValue.id] = currentValue.name;
          }
          return accumulator;
        }, {});

        this.locationCustomerNames = {
          ...this.locationCustomerNames,
          ...ancestorLocations.reduce((accumulator: { [locationId: string]: string }, location) => {
            if (location.id) {
              accumulator[location.id] = location.name;
            }
            return accumulator;
          }, {})
        };
        this.chRef.detectChanges();
      })
    );
  }

  doDelete(event: Event, location: Location): void {
    event.preventDefault()
    event.stopPropagation()
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant('LOCATION.DELETE.CONFIRMATION') + location.name
    }
    config.panelClass = "humecloud-dialog-container"
    const deleteDialogRef = this.matDialog.open(DeleteDialogComponent, config)
    this.subscriptions.add(
      deleteDialogRef.afterClosed().pipe(
        mergeMap(value => {
          if (value) {
            return this.locationService.deleteLocation(location.id!!)
          }
          return of(true)
        })
      ).subscribe(cancelled => {
        if (!cancelled) {
          this.handlePage()
        }
      })
    )

  }

  onTagSelected(tag: any) {
    this.tagsAdded.push(tag as Tag)
    this.retrieveLocations()
  }

  onTagDeSelected(tag: any) {
    this.tagsAdded = this.tagsAdded.filter(tagAdded => tagAdded.id !== (tag as Tag).id)
    this.retrieveLocations()
  }

  openLocationCreateDialog(): void {
    const locationCreateDialogRef = this.matDialog.open(LocationCreateDialogComponent)
    this.subscriptions.add(
      locationCreateDialogRef.afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }

  onQueryScopeChange(event: MatSelectChange): void {
    this.selectedScope = event.value
    this.retrieveLocations()
  }

  onSelectedTypesChange(event: MatSelectChange): void {
    this.selectedLocationTypes = event.value
    this.retrieveLocations()
  }

  initializeTagsDropdownSettings() {
    this.dropdownTagsSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "id",
      textField: "name",
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: this.translateService.instant('LOCATION.LIST.ACTIONS.SEARCH_PLACEHOLDER')
    }
  }
}
