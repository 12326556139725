import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VerifoneReaderModal } from "../../../../interfaces/verifone-reader.modal";
import { VerifoneReader } from "../../../../interfaces/verifone-reader";
import { Device } from "../../../../interfaces/device";
import { Subscription } from "rxjs";
import { VerifoneReaderService } from "../../../../services/verifone-reader/verifone-reader.service";
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CmnIfPermDirective } from 'hcl-lib';
import { MatIconModule } from '@angular/material/icon';

enum VerifoneReaderAction {
  SEARCHING,
  CREATE,
  EDIT
}

@Component({
    selector: 'app-verifone-reader-modal',
    templateUrl: './verifone-reader-modal.component.html',
    styleUrls: ['./verifone-reader-modal.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatDialogModule, MatButtonModule, TranslateModule, CmnIfPermDirective]
})
export class VerifoneReaderModalComponent implements OnInit, OnDestroy {

  subcriptions: Subscription = new Subscription()

  VerifoneReaderAction: typeof VerifoneReaderAction = VerifoneReaderAction;

  device!: Device
  currentVerifoneReader: VerifoneReader | null = null
  state!: VerifoneReaderAction
  verifoneForm!: UntypedFormGroup

  constructor(
    private dialogRef: MatDialogRef<VerifoneReaderModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private verifoneService: VerifoneReaderService,
    @Inject(MAT_DIALOG_DATA) public data: VerifoneReaderModal
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.device = this.data.device
    if (typeof this.data.currentVerifoneReader !== 'undefined') {
      this.currentVerifoneReader = this.data.currentVerifoneReader
    }
    this.state = this.currentVerifoneReader == null ? VerifoneReaderAction.SEARCHING : VerifoneReaderAction.EDIT
    this.verifoneForm = this.formBuilder.group({
      serialNumber: [null, Validators.required],
      description: [null, Validators.required],
      poste: [null, Validators.required],
      ilotlabel: [null, Validators.required],
      isProdInstance: [true],
      primaryIp: [{ value: null, disabled: true }],
      primaryPort: [{ value: null, disabled: true }],
      secondaryIp: [{ value: null, disabled: true }],
      secondaryPort: [{ value: null, disabled: true }]
    })
    if (this.currentVerifoneReader != null) {
      this.verifoneForm.patchValue(this.currentVerifoneReader)
    }
    this.updateInstanceValues()
  }

  ngOnDestroy() {
    this.subcriptions.unsubscribe()
  }

  getVerifoneServerValue(type: string) {
    const isProdInstance = this.verifoneForm.get('isProdInstance')?.value
    switch (type) {
      case "primaryIp":
        return isProdInstance ? "178.21.211.82" : "89.225.178.211"
      case "primaryPort":
        return isProdInstance ? "11508" : "11019"
      case "secondaryIp":
        return isProdInstance ? "178.21.210.82" : "wynid-integration.verifone.com"
      case "secondaryPort":
        return isProdInstance ? "11508" : "11019"
    }
    return
  }

  updateInstanceValues() {
    this.verifoneForm.patchValue({
      primaryIp: this.getVerifoneServerValue("primaryIp"),
      primaryPort: this.getVerifoneServerValue("primaryPort"),
      secondaryIp: this.getVerifoneServerValue("secondaryIp"),
      secondaryPort: this.getVerifoneServerValue("secondaryPort")
    })
  }

  resetVerifoneForm(serialNumber: string | null = null) {
    this.state = VerifoneReaderAction.SEARCHING
    this.verifoneForm.patchValue({
      serialNumber: serialNumber,
      description: null,
      poste: null,
      ilotlabel: null,
      isProdInstance: true
    })
    this.updateInstanceValues()
  }

  validate(): void {
    const reader: VerifoneReader = {
      serialNumber: this.verifoneForm.get('serialNumber')?.value,
      description: this.verifoneForm.get('description')?.value,
      ilotlabel: this.verifoneForm.get('ilotlabel')?.value,
      poste: this.verifoneForm.get('poste')?.value,
      isProdInstance: this.verifoneForm.get('isProdInstance')?.value,
      primaryIp: this.verifoneForm.get('primaryIp')?.value,
      primaryPort: this.verifoneForm.get('primaryPort')?.value,
      secondaryIp: this.verifoneForm.get('secondaryIp')?.value,
      secondaryPort: this.verifoneForm.get('secondaryPort')?.value,
      deviceId: this.device.id
    }

    if (this.state == VerifoneReaderAction.CREATE) {
      this.subcriptions.add(this.verifoneService.createVerifoneReader(reader).subscribe(_reader => {
        this.dialogRef.close(_reader)
      }))
    } else {
      reader.id = this.currentVerifoneReader?.id
      this.subcriptions.add(this.verifoneService.updateVerifoneReader(reader).subscribe(_reader => {
        this.dialogRef.close(_reader)
      }))
    }
  }

  back(): void {
    this.dialogRef.close(null)
  }

  search(): void {
    switch (this.state) {
      case VerifoneReaderAction.SEARCHING:
        this.doSearchVerifoneReader()
        break
      case VerifoneReaderAction.CREATE:
        this.doSearchVerifoneReader()
        break
      case VerifoneReaderAction.EDIT:
        this.resetVerifoneForm(null)
        break
    }
  }

  doSearchVerifoneReader() {
    const serial = this.verifoneForm.get('serialNumber')?.value
    this.subcriptions.add(
      this.verifoneService.getVerifoneReaderBySerialNumber(serial).subscribe(reader => {
        if (reader !== null) {
          this.currentVerifoneReader = reader
          this.state = reader == null ? VerifoneReaderAction.CREATE : VerifoneReaderAction.EDIT
          this.verifoneForm.patchValue(this.currentVerifoneReader)
        }
      })
    )
  }
}
