import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpShowError, InvocationResult } from 'hcl-lib'
import { environment } from 'projects/hcl-portal/src/environments/environment'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { ClearDataDeviceAction, DeviceAction, DeviceActionType, SendLogsDeviceAction, TunnelDeviceAction } from '../../interfaces/device-action'
import { ClearDataDeviceActionDto, DeviceActionDto, SendLogsDeviceActionDto, TunnelDeviceActionDto } from '../../interfaces/dto/device-action-dto'

@Injectable({
  providedIn: 'root'
})
export class DeviceActionService {

  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError(true)
  createDeviceAction(action: DeviceAction): Observable<DeviceAction> {
    const actionDto = this.mapToDeviceActionDto(action)
    return this.httpClient.post(`${environment.deviceApiBaseUrl}/devices/${action.deviceId}/actions`, actionDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getDeviceActions(deviceId: string): Observable<InvocationResult> {
    return this.httpClient.get(`${environment.deviceApiBaseUrl}/devices/${deviceId}/actions`).pipe(
      map((res: any) => (res as InvocationResult))
    )
  }

  getDeviceAction(deviceId: string, type: DeviceActionType): Observable<DeviceAction | null> {
    const url = `${environment.deviceApiBaseUrl}/devices/${deviceId}/actions/type`
    const queryParams = new HttpParams().set('type', type)
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

  cancelDeviceAction(action: DeviceAction) {
    return this.httpClient.delete(`${environment.deviceApiBaseUrl}/devices/${action.deviceId}/actions/${action.id}`)
  }

  mapToDeviceActionDto(action: DeviceAction): DeviceActionDto | undefined {
    let actionDto: DeviceActionDto | undefined = undefined

    switch (action.type) {
      case DeviceActionType.CLEAR_DATA: {
        let clearData = action as ClearDataDeviceAction
        let clearDataDto = new ClearDataDeviceActionDto()
        actionDto = clearDataDto
        break
      }
      case DeviceActionType.SEND_LOGS: {
        let sendLogs = action as SendLogsDeviceAction
        let sendLogsDto = new SendLogsDeviceActionDto()
        actionDto = sendLogsDto
        break
      }
      case DeviceActionType.TUNNEL: {
        let tunnelDeviceAction = action as TunnelDeviceAction
        let tunnelDeviceActionDto = new TunnelDeviceActionDto()
        actionDto = tunnelDeviceActionDto
        break
      }
      default: {
        // not supported
        break
      }
    }

    if (typeof actionDto !== 'undefined') {
      if (action.id) {
        actionDto.id = action.id
      }
      actionDto.deviceId = action.deviceId
    }

    return actionDto
  }
}
