<div class="background">
  <div class="title">
    <img src="/assets/images/icon-humecloud.svg" alt="humecloud icon">
    <h1>HUME<span>CLOUD</span></h1>
    <p>{{ 'YOUR_DIGITAL_SIGNAGE_PLATFORM' | translate }}</p>
  </div>

  <div *ngIf="userApps && userApps.length > 0" class="your-applications">
    <h2 class="your-applications_title">{{ 'YOUR_APPLICATIONS' | translate }}</h2>
    <div class="app-row">
      <ng-container *ngFor="let app of userApps">
        <div class="app" *cmnIfPerm="['cmn:app-page:read:' + app.name | lowercase]">
          <div class="app_content">
            <img class="app_content_icon" [src]="getAppIcon(app.name)" [alt]="app.name">
            <div class="app_content_text">
              <div class="app_content_name" [innerHTML]="getAppDisplayNameHtml(app.name)"></div>
              <p class="app_content_description">
                {{ 'APPS.' + app.name + '.DESCRIPTION' | uppercase | translate }}
              </p>
            </div>
          </div>
          <div class="app_actions">
            <button class="app-button hc-btn-{{app.name}} hc-btn-{{app.name}}-primary"
                    (click)="navigateToApp(app.name)">
              {{ 'OPEN' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *cmnIfPerm="['cmn:buy-apps-section:read']">
  <div *ngIf="suggestedApps && suggestedApps.length > 0" class="suggested-apps" >
    <h2 class="suggested-apps_title">{{'APPLICATIONS_BUILT_FOR_YOU' | translate}}</h2>
    <div class="suggested-apps_apps">
      <div class="app-row">
        <ng-container *ngFor="let app of suggestedApps">
          <div class="app" *cmnIfPerm="['cmn:app-page:read:' + app.name | lowercase]">
            <div class="app_content">
              <img class="app_content_icon" [src]="getAppIcon(app.name)">
              <div class="app_content_text">
                <div class="app_content_name" [innerHTML]="getAppDisplayNameHtml(app.name)"></div>
                <p class="app_content_description">
                  {{ 'APPS.' + app.name + '.DESCRIPTION' | uppercase | translate }}
                </p>
              </div>
            </div>
            <div class="app_actions">
              <button [ngClass]="app.tryCssClasses" *ngIf="app.trialLicenseType"
                      (click)="doSubscribe(app)">{{ 'TRY' | translate }}
              </button>
              <a [ngClass]="app.buyCssClasses" [routerLink]="['/contact']"
              >{{ 'BUY' | translate }}</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
