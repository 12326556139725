import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Customer, KeycloakService, ScopeService, SnackBarService } from 'hcl-lib';
import { User } from 'projects/hcl-portal/src/app/common/interfaces/user';
import { CustomerFieldsComponent } from 'projects/hcl-portal/src/app/common/Layout/Components/widget/customer/customer-fields/customer-fields.component';
import { UserService } from 'projects/hcl-portal/src/app/common/services/user/user.service';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderComponent } from '../../Layout/Components/widget/loader/loader.component';
import { MatCardModule } from '@angular/material/card';
import { UserBoxComponent } from '../../Layout/Components/header/elements/user-box/user-box.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CustomerAdminFieldsComponent } from '../../Layout/Components/widget/customer/customer-admin-fields/customer-admin-fields.component';

@Component({
    selector: 'self-registration',
    templateUrl: './self-registration.component.html',
    styleUrls: ['./self-registration.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, TranslateModule, LoaderComponent, UserBoxComponent, CustomerFieldsComponent, CustomerAdminFieldsComponent]
})
export class SelfRegistrationComponent implements OnInit, OnDestroy {

  @ViewChild(CustomerFieldsComponent, { static: true }) customerFieldsComponent!: CustomerFieldsComponent;
  @ViewChild(LoaderComponent, { static: true }) loaderComponent!: LoaderComponent;

  subscription: Subscription = new Subscription()

  namePlaceholder!: string

  customerForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private router: Router,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private keycloakService: KeycloakService,
    private scopeService: ScopeService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.namePlaceholder = this.translateService.instant('SELF_REGISTRATION_COMPONENT.NAME_PLACEHOLDER')
  }

  async createUser() {
    this.loaderComponent.loading = true
    let user: User = {
      id: "",
      email: this.keycloakService.getEmail(),
      customers: [],
      roles: [],
      permissions: [],
      customerId: "",
      firstName: this.keycloakService.getFirstName(),
      lastName: this.keycloakService.getLastName(),
      phoneNumber: this.keycloakService.getPhoneNumber()
    }
    let adminForm = this.customerForm.get('admin') as AbstractControl
    let generalFieldsForm = this.customerForm.get('generalFields') as AbstractControl
    let domain = (adminForm.get('domain')?.value && adminForm.get('domain')?.value[0])
      ? adminForm.get('domain')?.value[0] : null
    if (!domain) {
      this.snackBarService.snackBarError('CUSTOMER_FIELDS_COMPONENT.SELECT_DOMAIN')
    } else {
      const customer: Customer = {
        id: '',
        customerId: '',
        name: generalFieldsForm.get('name')?.value,
        appIds: adminForm.get('apps')?.value,
        address: {
          address: generalFieldsForm.get('address')?.value,
          zipCode: generalFieldsForm.get('zipCode')?.value,
          city: generalFieldsForm.get('city')?.value,
          country: generalFieldsForm.get('country')?.value
        },
        phoneNumber: generalFieldsForm.get('phoneNumber')?.value,
        phoneNumber2: generalFieldsForm.get('phoneNumber2')?.value,
        domain: domain,
        comments: generalFieldsForm.get('comments')?.value,
        email: generalFieldsForm.get('email')?.value,
        website: generalFieldsForm.get('website')?.value,
        companyRegistrationNumber: generalFieldsForm.get('companyRegistrationNumber')?.value,
        designer: adminForm.get('designer')?.value,
        customerType: (adminForm.get('customerType')?.value && adminForm.get('customerType')?.value[0])
          ? adminForm.get('customerType')?.value[0] : null,
        sellingCompanyId: (adminForm.get('sellingCompany')?.value && adminForm.get('sellingCompany')?.value[0])
          ? adminForm.get('sellingCompany')?.value[0] : null,
        logoMediaId: generalFieldsForm.get('logoMediaId')?.value,
        metadata: undefined
      }

      let userSavedInKeycloak = await this.waitForUserToBeSavedInKeycloak(user.email)
      if (userSavedInKeycloak) {
        this.userService.selfRegister(user, customer).pipe(
          catchError(() => {
            this.loaderComponent.loading = false
            return of()
          })
        ).subscribe(createdUser => {
          if (createdUser != undefined) {
            this.subscription.add(this.scopeService.getScopeObservable().subscribe(_ => {
              this.router.navigate(["/"])
            }))
            this.keycloakService.getToken(true).toPromise()
          }
        })
      } else {
        this.loaderComponent.loading = false
      }
    }
  }

  async waitForUserToBeSavedInKeycloak(username: string) {
    let userSavedInKeycloak = await this.userService.checkUserExistsInKeycloak(username).toPromise()
    let executionStart = Date.now()
    while (!userSavedInKeycloak) {
      const millis = Date.now() - executionStart
      const executionTimeInSeconds = Math.floor(millis / 1000)
      if (executionTimeInSeconds > 20) {
        this.showSnackbar(this.translateService.instant('SELF_REGISTRATION_COMPONENT.TIMEOUT'));
        return false
      }
      await new Promise(resolve => setTimeout(resolve, 1000)) //sleep for 1 second
      userSavedInKeycloak = await this.userService.checkUserExistsInKeycloak(username).toPromise()
    }
    return true
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 2000
    });
  }
}
