<h1 mat-dialog-title>{{ 'DEVICE.ACTIONS.ADD_DEVICE' | translate }}</h1>
<form [formGroup]="deviceForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <!-- name -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.NAME.LABEL' | translate }}</mat-label>
      <input matInput formControlName="name" [placeholder]="'DEVICE.FIELDS.NAME.PLACEHOLDER' | translate" required>
    </mat-form-field>
    <!-- type -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.TYPE.LABEL' | translate }}</mat-label>
      <mat-select formControlName="type" matNativeControl required>
        <mat-option *ngFor="let type of deviceTypesKeys" [value]="type">
          {{ "DEVICE.TYPE." + type | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- orientation -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.ORIENTATION.LABEL' | translate }}</mat-label>
      <mat-select formControlName="orientation" matNativeControl required>
        <mat-option *ngFor="let orientation of deviceOrientationKeys" [value]="orientation">
          {{ "DEVICE.ORIENTATION." + orientation | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- muted -->
    <mat-checkbox formControlName="muted">
      {{ 'DEVICE.FIELDS.MUTED.LABEL' | translate }}
    </mat-checkbox>
    <!-- autoPowerOnOff -->
    <mat-checkbox formControlName="autoPowerOnOff">
      {{ 'DEVICE.FIELDS.AUTO_POWER_ON_OFF.LABEL' | translate }}
    </mat-checkbox>
    <!-- useUpdatingTimeSlots -->
    <mat-checkbox formControlName="useUpdatingTimeSlots">
      {{ 'DEVICE.FIELDS.USE_UPDATING_TIME_SLOTS.LABEL' | translate }}
    </mat-checkbox>
    <!-- useRealTime -->
    <mat-checkbox formControlName="useRealTime" *cmnIfPerm="['scr:device:update:use-real-time']">
      {{ "DEVICE.FIELDS.USE_REAL_TIME.LABEL" | translate }}
    </mat-checkbox>
    <!-- tags -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.TAGS.LABEL' | translate }}</mat-label>
      <mat-select formControlName="addedTags" multiple (openedChange)="onOpenDeviceTag($event)">
        <input #searchDeviceTag matInput type="text" (input)="onSearchDeviceTag($event)" autocomplete="off">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let deviceTag of filteredDeviceTags" [value]="deviceTag">{{ deviceTag.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- excluded tags -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.EXCLUDED_TAGS.LABEL' | translate }}</mat-label>
      <mat-select formControlName="excludedTags" multiple (openedChange)="onOpenMediaTag($event)">
        <input #searchMediaTag matInput type="text" (input)="onSearchMediaTag($event)" autocomplete="off">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let mediaTag of filteredMediaTags" [value]="mediaTag">{{ mediaTag.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- legacyScreenlabUser -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.LEGACY_USER.LABEL' | translate }}</mat-label>
      <input matInput formControlName="legacyScreenlabUser"
        [placeholder]="'DEVICE.FIELDS.LEGACY_USER.PLACEHOLDER' | translate">
    </mat-form-field>
    <!-- language -->
    <div class="mat-dialog-content__language-container">
      <mat-form-field class="mat-dialog-content__language-container__mat-form-field">
        <mat-label>{{ 'DEVICE.FIELDS.LANGUAGE.LABEL' | translate }}</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let locale of DeviceSupportedLanguages | enumToArray"
            [value]="getDeviceSupportedLanguage(locale)">
            {{ 'DEVICE.FIELDS.LANGUAGE.SELECT.' + locale | translate }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="mat-dialog-content__language-container__cancel" *ngIf="deviceForm.value.language"
          (click)="resetLocale()">close</mat-icon>
      </mat-form-field>
    </div>
    <!-- zoneId -->
    <div>
      <mat-label class="mat-dialog-content__container-zoneid__timezone-label">{{ 'DEVICE.FIELDS.TIMEZONE.LABEL' |
        translate }}</mat-label>
      <single-searchable-select class="mat-dialog-content__container-zoneid__select-zoneid" formControlName="zoneId"
        [data]="zoneIdSearchableData" [showSelectArrow]="true"
        [placeholder]="'DEVICE.FIELDS.TIMEZONE.LABEL_IN_SELECT' | translate" [stickyPlaceholder]="false">
      </single-searchable-select>
    </div>
    <!-- comment -->
    <mat-form-field>
      <mat-label>{{ 'DEVICE.FIELDS.COMMENT.LABEL' | translate }}</mat-label>
      <textarea matInput formControlName="comment"
        [placeholder]="'DEVICE.FIELDS.COMMENT.PLACEHOLDER' | translate"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-raised-button color="accent" [disabled]="!deviceForm.valid" type="submit">
      <mat-icon>done</mat-icon>
      {{ 'VALIDATE' | translate }}
    </button>
  </mat-dialog-actions>
</form>