import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'hcl-lib';
import { Observable } from 'rxjs';


@Injectable()
export class AppGuard  {

  constructor(
    private keycloakService: KeycloakService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let activate = this.keycloakService.getApiUserId() != undefined
    if (!activate) {
      this.router.navigate([this.keycloakService.usesDefaultRealm() ? "register" : "contactAdmin"])
    }
    return activate
  }
}
