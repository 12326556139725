import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CmnIfPermDirective, EnumToArrayPipe, Tag, TagService, TargetType} from "hcl-lib";
import {MatButton} from "@angular/material/button";
import {MatCheckbox} from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatDivider} from "@angular/material/divider";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
  SingleSearchableSelectComponent
} from "../../../Layout/Components/widget/single-searchable-select/single-searchable-select.component";
import {TranslateModule} from "@ngx-translate/core";
import {Subscription, zip} from "rxjs";
import {
  Location,
  LocationBedroom,
  LocationFloor,
  LocationHotel, LocationMeetingRoom,
  LocationRoom,
  LocationType
} from "../../../interfaces/location";
import {LocationService} from "../../../services/location/location.service";

@Component({
  selector: 'app-location-create-dialog',
  standalone: true,
  imports: [
    CmnIfPermDirective, EnumToArrayPipe, MatButton, MatCheckbox, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle, MatDivider, MatFormField, MatIcon, MatInput, MatLabel, MatOption, MatSelect, MatSuffix, NgForOf, NgIf, ReactiveFormsModule, SingleSearchableSelectComponent, TranslateModule
  ],
  templateUrl: './location-create-dialog.component.html',
  styleUrl: './location-create-dialog.component.scss'
})
export class LocationCreateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()
  locationForm: FormGroup = this.formBuilder.group({
    type: [null, Validators.required],
    name: [null, Validators.required],
    description: [null],
    locationId: [ this.data?.locationId ? this.data.locationId : null],
    tagIds: [[]]
  })

  locationTypes: LocationType[] = Object.values(LocationType);

  @ViewChild("searchLocation") searchLocation!: ElementRef
  locations: Location[] = []
  filteredLocations: Location[] = []

  @ViewChild("searchLocationTag") searchLocationTag!: ElementRef
  locationTags: Tag[] = []
  filteredLocationTags: Tag[] = []

  constructor(
    public dialogRef: MatDialogRef<LocationCreateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private locationService: LocationService,
    private tagService: TagService,
    @Inject(MAT_DIALOG_DATA) public data: { locationId?: string }
  ) {
    // do nothing
  }

  ngOnInit() {
    this.retrieveLocations();

    this.locationForm.get('type')?.valueChanges.subscribe((selectedType) => {
      this.updateFormFields(selectedType);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  retrieveLocations(search?: string) {
    this.subscriptions.add(
      zip(
        this.locationService.getLocationsWithPaging(
          0,
          10000,
          undefined,
          undefined,
          undefined,
          search
        ),
        this.tagService.getAvailableTags([TargetType.LOCATION])
      ).subscribe(([locations, locationTags]) => {
        this.locations = locations.data
        this.filteredLocations = locations.data
        this.locationTags = locationTags
        this.filteredLocationTags = locationTags
      })
    )
  }

  onSubmit() {
    const location: Location = this.buildLocationFromForm();
    this.subscriptions.add(
      this.locationService.createLocation(location).subscribe(() => {
        this.dialogRef.close();
      })
    );
  }

  updateFormFields(selectedType: LocationType) {
    this.resetConditionalFields();

    switch (selectedType) {
      case LocationType.HOTEL:
        this.locationForm.addControl('address', this.formBuilder.control(null, Validators.required));
        this.locationForm.addControl('city', this.formBuilder.control(null));
        this.locationForm.addControl('postalCode', this.formBuilder.control(null));
        this.locationForm.addControl('country', this.formBuilder.control(null));
        break;
      case LocationType.FLOOR:
        this.locationForm.addControl('number', this.formBuilder.control(0));
        break;
      case LocationType.ROOM:
      case LocationType.MEETING_ROOM:
      case LocationType.BEDROOM:
        this.locationForm.addControl('number', this.formBuilder.control(0));
        this.locationForm.addControl('available', this.formBuilder.control(true));
        break;
      case LocationType.COMMON_AREA:
      case LocationType.OTHER:
      default:
        break;
    }
  }

  resetConditionalFields() {
    const controlsToRemove = ['address', 'city', 'postalCode', 'country', 'floorNbr', 'number', 'available'];
    controlsToRemove.forEach(control => {
      if (this.locationForm.contains(control)) {
        this.locationForm.removeControl(control);
      }
    });
  }

  onOpenLocation($open: boolean) {
    if ($open) {
      this.searchLocation.nativeElement.focus()
    } else {
      this.searchLocation.nativeElement.value = ''
      this.onSearchLocation(undefined)
    }
  }

  onSearchLocation(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.retrieveLocations(value.toLowerCase())
    }
  }

  onOpenLocationTag($open: boolean) {
    if ($open) {
      this.searchLocationTag.nativeElement.focus()
    } else {
      this.searchLocationTag.nativeElement.value = ''
      this.onSearchLocationTag(undefined)
    }
  }

  onSearchLocationTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredLocationTags = value ? this.locationTags?.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase())) : this.locationTags?.slice()
    }
  }

  private buildLocationFromForm(): Location {
    const location: Location = {
      type: this.locationForm.value.type,
      name: this.locationForm.value.name,
      description: this.locationForm.value.description,
      locationId: this.locationForm.value.locationId,
      tagIds: this.locationForm.value.tagIds.map((tags: any) => tags.id),
    };

    switch (location.type) {
      case LocationType.HOTEL:
        return {
          ...location,
          address: this.locationForm.value.address,
          city: this.locationForm.value.city,
          country: this.locationForm.value.country,
          postalCode: this.locationForm.value.postalCode,
        } as LocationHotel;

      case LocationType.FLOOR:
        return {
          ...location,
          number: this.locationForm.value.number,
        } as LocationFloor;

      case LocationType.ROOM:
      case LocationType.BEDROOM:
      case LocationType.MEETING_ROOM:
        return {
          ...location,
          number: this.locationForm.value.number,
          available: this.locationForm.value.available,
        } as LocationRoom | LocationBedroom | LocationMeetingRoom;

      default:
        return location;
    }
  }

}
