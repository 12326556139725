import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Device } from "../../../interfaces/device";
import { KioskApplication, KioskConfig, KioskNavigationBarPosition } from "../../../interfaces/kiosk-config";
import { EnumToArrayPipe } from "hcl-lib";
import { MatSelectChange } from "@angular/material/select";
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { Subscription } from "rxjs";
import { DeviceService } from "../../../services/device/device.service";
import { NgFor, NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelectModule } from "@angular/material/select";
import { CmnIfAppDirective } from "../../../directives/cmn-if-app/cmn-if-app.directive";
import { MatIconModule } from "@angular/material/icon";

const resolvedPromise = Promise.resolve(null);

@Component({
  selector: 'app-device-kiosk',
  templateUrl: './device-kiosk.component.html',
  styleUrls: ['./device-kiosk.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatSelectModule, MatIconModule, MatTooltipModule, TranslateModule, CmnIfAppDirective, EnumToArrayPipe]
})
export class DeviceKioskComponent implements OnInit {

  subscriptions: Subscription = new Subscription()

  @Input() device!: Device
  @Output() formGroup: EventEmitter<UntypedFormGroup> = new EventEmitter()

  @ViewChild('copiedTooltip') copiedTooltip!: MatTooltip;

  kioskForm!: UntypedFormGroup
  navigationBarPositionKeys: string[] = Object.keys(KioskNavigationBarPosition).filter(value => isNaN(Number(value)))
  KioskApplication = KioskApplication;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private deviceService: DeviceService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.kioskForm = this.formBuilder.group({
      application: [KioskApplication.CUSTOM, Validators.required],
      url: [null, Validators.required],
      whitelist: [null],
      whitelistUrl: [null],
      homeTime: [300, Validators.required],
      idleTime: [300, Validators.required],
      backgroundColor: [null],
      foregroundColor: [null],
      screenlabEnabled: [true, Validators.required],
      showNavigation: [true, Validators.required],
      navigationBarPosition: [KioskNavigationBarPosition.LEFT, Validators.required],
      cacheFlushEnabled: [true, Validators.required],
      virtualKeyboardEnabled: [true, Validators.required],
      rotationEnabled: [false, Validators.required]
    })
    if (this.device.kioskConfig) {
      this.kioskForm.patchValue(this.device.kioskConfig)
      this.onDeviceKioskApplicationChanged(null)
    }
    resolvedPromise.then(() => {
      this.formGroup.emit(this.kioskForm)
    })
  }

  onDeviceKioskApplicationChanged($event: MatSelectChange | null) {
    const appWithUrlReadOnly: KioskApplication[] = [
      KioskApplication.HUMECHARITY,
      KioskApplication.HUMETIPS
    ]

    let selectedApp: KioskApplication = this.kioskForm.get('application')?.value

    if (appWithUrlReadOnly.includes(selectedApp)) {
      this.subscriptions.add(
        this.deviceService.getDeviceKioskUrlByApp(this.device.id, selectedApp).subscribe(url => {
          this.kioskForm.patchValue({ url: url })
          this.formGroup.emit(this.kioskForm)
        })
      )
      this.kioskForm.get('url')?.disable()
    }
    else this.kioskForm.get('url')?.enable()
  }

  copyUrlToClipboard() {
    navigator.clipboard.writeText((this.device.kioskConfig as KioskConfig).url)
    this.copiedTooltip.disabled = false

    setTimeout(() => {
      this.copiedTooltip.hide()
      this.copiedTooltip.disabled = true
    }, 3000)
    this.copiedTooltip.show()
  }
}
