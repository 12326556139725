import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContextService implements OnDestroy {

  private subscriptions = new Subscription()
  private pageApp$ = new BehaviorSubject("")

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initRouterSubscription()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initRouterSubscription() {
    this.subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) route = route.firstChild
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      ).subscribe(data => {
        this.pageApp$.next(data['app'])
      })
    )
  }

  getPageApp(): Observable<string> {
    return this.pageApp$.asObservable()
  }
}
