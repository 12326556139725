<div class="pairing-dialog">
  <h1 mat-dialog-title>{{ 'DEVICE.PAIRING.TITLE' | translate }}</h1>
  <div class="pairing-dialog_content">
    <div class="pairing-dialog_error" *ngIf="deviceTypeNeedRemoteConfig() && !hasRemoteControlConfig">
      <mat-icon>
        warning
      </mat-icon>
      <span>{{ 'DEVICE.PAIRING.REMOTE_ALERT' | translate }}</span>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'DEVICE.PAIRING.FIELDS.CODE.LABEL' | translate }}</mat-label>
      <input [(ngModel)]="device.pairingCode" matInput
             [placeholder]="'DEVICE.PAIRING.FIELDS.CODE.PLACEHOLDER' | translate" required>
    </mat-form-field>
  </div>
  <footer>
    <button mat-button mat-dialog-close (click)="doBack()">
      {{ 'DEVICE.PAIRING.BACK' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="doValid()">
      {{ 'DEVICE.PAIRING.VALIDATE' | translate }}
    </button>
  </footer>
</div>
