import {Component, Inject, OnDestroy, OnInit} from "@angular/core"
import {Subscription} from "rxjs"
import {Customer, CustomerNames,  CustomerService, KeycloakService} from "hcl-lib"
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms"
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog"
import {ChannelType} from "../../../interfaces/enum/channel-type"
import {TranslateModule, TranslateService} from "@ngx-translate/core"
import {UserPreference} from "../../../interfaces/user-preference"
import {EventUserPreferenceService} from "../../../services/event-user-preference/event-user-preference.service"
import {PopulatedUserNotificationPreference} from "../event-user-preference/event-user-preference.component"
import {EventTypeService} from "../../../services/event-type/event-type.service"
import { NgFor, NgIf } from "@angular/common"
import { MatDialogModule } from "@angular/material/dialog"
import { RecentSearchSelectComponent } from "../../../Layout/Components/widget/recent-search-select/recent-search-select.component"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import {EventTranslation, EventType} from "../../../interfaces/event-type"
import { SearchableSelectComponent } from "../../../Layout/Components/widget/searchable-select/searchable-select.component"
import { UserNotificationPreference } from "../../../interfaces/user-notification-preference"

@Component({
    selector: "app-event-user-notification-preference-edit-modal",
    templateUrl: "./user-notification-preference-edit-modal.component.html",
    styleUrls: ["./user-notification-preference-edit-modal.component.scss"],
    standalone: true,
    imports: [NgFor, NgIf, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatIconModule, TranslateModule, SearchableSelectComponent, RecentSearchSelectComponent]
})
export class UserNotificationPreferenceEditModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  searchableSelectChannels: { id: ChannelType, name: string, selected: boolean}[]
  searchableSelectCustomers: { id?: string, name: string, selected: boolean}[] = []
  userPreference!: UserPreference
  userNotificationPreference!: PopulatedUserNotificationPreference

  foundCustomers: any[] = []
  foundEventType: { id: string, name: string }[] = []

  userNotificationPreferenceFieldsForm!: UntypedFormGroup
  eventType = {
    translations: [{ code: "" } as EventTranslation]
  } as EventType

  constructor(
    public dialogRef: MatDialogRef<UserNotificationPreferenceEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private eventUserPreferenceService: EventUserPreferenceService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private eventTypeService: EventTypeService,
    private keycloakService: KeycloakService,
  ) {
    this.searchableSelectChannels = Object.keys(ChannelType).map(
      channel => ({
        id: ChannelType[channel as keyof typeof ChannelType],
        name: this.translateService.instant("EVENT.USER_PREFERENCE.CHANNELS." + channel),
        selected: false
      })
    )
  }

  ngOnInit(): void {
    this.userPreference = this.data.userPreference
    this.userNotificationPreference = this.data.userNotificationPreference

    this.createFieldsForm()
    this.initForm()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  initForm() {
    if (this.userPreference) {
      this.updateChannelsValue(this.userPreference.channels)
    }
    if (this.userNotificationPreference) {
      if (this.userNotificationPreference.channels) {
        this.updateChannelsValue(this.userNotificationPreference.channels)
      }
      if (this.userNotificationPreference.targetCustomers) {
        this.updateCustomersValue(this.userNotificationPreference.targetCustomers)
      }
    }
    this.searchEventType()
    this.patchFormValue()
  }

  updateCustomersValue(customers: Customer[]) {
    customers.forEach(customer => {
      this.searchableSelectCustomers.push({
        id: customer.id,
        name: customer.name,
        selected: true
      })
    })
  }

  updateChannelsValue(channels: ChannelType[]) {
    this.searchableSelectChannels = this.searchableSelectChannels.map(itc => {
      return {
        id: itc.id,
        name: itc.name,
        selected: channels.findIndex(channel => ChannelType[channel] === itc.id) !== -1
      }
    })
  }

  patchFormValue(): void {
    this.userNotificationPreferenceFieldsForm.patchValue({
      eventKey: this.userNotificationPreference ? {id: "", name: this.userNotificationPreference.eventKey } : null,
      targetCustomers: this.searchableSelectCustomers,
      channels: this.searchableSelectChannels
    })
  }

  searchCustomer(search: string) {
    this.subscriptions.add(
      this.customerService.getAvailableCustomers(0, 5, search).subscribe(result => {
        this.foundCustomers = result.filter(it =>
          !this.searchableSelectCustomers.find(sIt => sIt.id === it.id))
      })
    )
  }

  searchEventType(search?: string) {
    this.subscriptions.add(
      this.eventTypeService.getEventTypesWithPaging(0, 5).subscribe(result => {
        this.foundEventType = result.data.map((it: any) => ({id: it.id, name: it.key}))
      })
    )
  }

  createFieldsForm() {
    this.userNotificationPreferenceFieldsForm = this.formBuilder.group({
      eventKey: [null, Validators.required],
      targetCustomers: [{ id: "", value: "", disabled: true }],
      channels: this.searchableSelectChannels,
    })
  }

  selectCustomer(customerNames: CustomerNames) {
    if (!this.searchableSelectCustomers.find(it => it.id === customerNames.id)) {
      this.searchableSelectCustomers.push({
        id: customerNames.id,
        name: customerNames.name,
        selected: true
      })
      this.userNotificationPreferenceFieldsForm.patchValue({
        targetCustomers: this.searchableSelectCustomers,
        channels: this.searchableSelectChannels
      })
    }
  }

  selectEventType(eventType: { id: string, name: string }) {
    this.userNotificationPreferenceFieldsForm.patchValue({
      eventKey: eventType
    })
  }

  onSubmit() {
    const value = this.userNotificationPreferenceFieldsForm.value
    const createEventUserNotificationPreference: UserNotificationPreference = {
      id: undefined,
      userId: this.keycloakService.getApiUserId(),
      eventKey: value.eventKey.name,
      targetCustomerIds: value.targetCustomers.map((it: Customer) => it.id),
      channels: value.channels,
    }
    if (this.userNotificationPreference) {
      createEventUserNotificationPreference.id = this.userNotificationPreference.id
      this.subscriptions.add(
        this.eventUserPreferenceService.updateEventUserNotificationPreference(
          createEventUserNotificationPreference
        ).subscribe((userNotificationPreference) => {
          this.dialogRef.close(userNotificationPreference)
        })
      )
    } else {
      this.subscriptions.add(
        this.eventUserPreferenceService.createEventUserNotificationPreference(
          createEventUserNotificationPreference
        ).subscribe((userNotificationPreference) => {
          this.dialogRef.close(userNotificationPreference)
        })
      )
    }
  }

  onClickRemoveCustomer(cId: string) {
    this.searchableSelectCustomers = this.searchableSelectCustomers.filter(it => {
      return it.id !== cId
    })
    this.patchFormValue()
  }
}
