import {HttpClient, HttpParams} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {HttpShowError, InvocationResult, QueryScope} from "hcl-lib"
import {environment} from "projects/hcl-portal/src/environments/environment"
import {Observable} from "rxjs"
import {map} from "rxjs/operators"
import {
  Location, LocationBedroom,
  LocationFloor,
  LocationHotel,
  LocationMeetingRoom,
  LocationRoom,
  LocationType
} from "../../interfaces/location"
import {
  LocationBedroomDto,
  LocationDto,
  LocationFloorDto,
  LocationHotelDto,
  LocationMeetingRoomDto,
  LocationRoomDto
} from "../../interfaces/dto/location-dto"

@Injectable({
  providedIn: "root"
})
export class LocationService {

  constructor(
    private httpClient: HttpClient
  ) {
    // do nothing
  }

  @HttpShowError()
  getLocationsWithPaging(
    page: number,
    perPage: number,
    tagIds?: string[],
    queryScope?: QueryScope,
    locationTypes?: LocationType[],
    search?: string,
    locationId?: string
  ): Observable<InvocationResult> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations`
    let queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())
    if (tagIds) {
      tagIds.forEach(id => {
        queryParams = queryParams.append("tagIds", id)
      })
    }
    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }
    if (locationTypes) {
      locationTypes.forEach(locationType => {
        queryParams = queryParams.append("locationTypes", locationType)
      })
    }
    if (locationId) {
      queryParams = queryParams.append("locationId", locationId)
    }
    if (search) {
      queryParams = queryParams.append("search", search)
    }

    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getLocation(id: string): Observable<Location> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations/${id}`
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getLocationsByLocationId(id: string): Observable<Location[]> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations/${id}/locations`
    return this.httpClient.get(url).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getLocationsByIds(
    locationIds: string[]
  ): Observable<Location[]> {
    const queryParams = new HttpParams({ fromObject: { 'ids': locationIds } })

    const url = `${environment.cmnLocationApiBaseUrl}/locations/locationIds`
    return this.httpClient.get(url, {params: queryParams}).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  createLocation(location: Location): Observable<Location> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations`;

    const baseLocationDto: LocationDto = {
      name: location.name,
      type: location.type,
      description: location.description,
      locationId: location.locationId,
      tagIds: location.tagIds,
    };

    const locationDto = this.buildLocationDto(location, baseLocationDto);

    return this.httpClient.post(url, locationDto).pipe(
      map((res: any) => (res as InvocationResult).data)
    );
  }

  @HttpShowError(true)
  updateLocation(location: Location): Observable<Location> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations/${location.id}`;

    const baseLocationDto: LocationDto = {
      id: location.id,
      name: location.name,
      type: location.type,
      description: location.description,
      locationId: location.locationId,
      tagIds: location.tagIds,
    };

    const locationDto = this.buildLocationDto(location, baseLocationDto);

    return this.httpClient.put(url, locationDto).pipe(
      map((res: any) => (res as InvocationResult).data)
    );
  }

  @HttpShowError(true)
  deleteLocation(id: string): Observable<any> {
    const url = `${environment.cmnLocationApiBaseUrl}/locations/${id}`
    return this.httpClient.delete(url)
  }

  private buildLocationDto(location: Location, baseDto: LocationDto): LocationDto {
    switch (location.type) {
      case LocationType.HOTEL:
        return {
          ...baseDto,
          address: (location as LocationHotel).address,
          city: (location as LocationHotel).city,
          country: (location as LocationHotel).country,
          postalCode: (location as LocationHotel).postalCode,
        } as LocationHotelDto;

      case LocationType.FLOOR:
        return {
          ...baseDto,
          number: (location as LocationFloor).number,
        } as LocationFloorDto;

      case LocationType.ROOM:
      case LocationType.MEETING_ROOM:
      case LocationType.BEDROOM:
        return {
          ...baseDto,
          number: (location as LocationRoom | LocationMeetingRoom | LocationBedroom).number,
          available: (location as LocationRoom | LocationMeetingRoom | LocationBedroom).available,
        } as LocationRoomDto | LocationMeetingRoomDto | LocationBedroomDto;

      default:
        return baseDto;
    }
  }
}
