<div class="header-dots">
  <div class="btn-group" ngbDropdown placement="bottom">
    <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle>
      <span>
        <div [ngSwitch]="translateService.currentLang" class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-focus"></div>
          <div *ngSwitchCase="'fr'" class="language-icon flag fi fi-fr"></div>
          <div *ngSwitchCase="'en'" class="language-icon flag fi fi-gb"></div>
        </div>
      </span>
    </button>
    <div ngbDropdownMenu>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
          <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
          <div class="menu-header-content text-center text-white">
            <h6 class="menu-header-subtitle mt-0">{{'CHOOSE_LANGUAGE' | translate}}</h6>
          </div>
        </div>
      </div>
      <button type="button" class="dropdown-item" (click)="translateService.use('fr')">
        <div class="language-icon flag fi fi-fr"></div>
        <span>{{'FRENCH' | translate}}</span>
      </button>
      <button type="button" class="dropdown-item" (click)="translateService.use('en')">
        <div class="language-icon flag fi fi-gb"></div>
        <span>{{'ENGLISH' | translate}}</span>
      </button>

    </div>
  </div>
</div>
