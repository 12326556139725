import { Injectable } from "@angular/core"
import {HttpClient, HttpParams} from "@angular/common/http"
import {HttpShowError, InvocationResult, QueryScope} from "hcl-lib"
import {Observable} from "rxjs"
import {EventDto} from "../../interfaces/dto/event-dto"
import {map} from "rxjs/operators"
import {environment} from "../../../../environments/environment"

@Injectable({
  providedIn: "root"
})
export class EventService {
  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError()
  getEventsWithPaging(
    page: number,
    perPage: number,
    search?: string,
    services?: string[],
    queryScope?: QueryScope
  ): Observable<InvocationResult> {
    let queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())

    if (search) {
      queryParams = queryParams.append("search", search)
    }

    if (services) {
      services.forEach(it => {
        queryParams = queryParams.append("service", it)
      })
    }

    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }

    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/events`,
      { params: queryParams }
    ).pipe(
      map((res: any) => {
        return res as InvocationResult
      })
    )
  }

  @HttpShowError()
  getEvent(
    eventId: string
  ): Observable<EventDto> {
    return this.httpClient.get(`${environment.cmnEventApiBaseUrl}/events/${eventId}`).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getServices(
    page: number,
    perPage: number,
  ): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())

    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/events/services`,
      { params: queryParams }
    ).pipe(
      map((res: any) => {
        return res as InvocationResult
      })
    )
  }
}
