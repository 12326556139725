<mat-toolbar color="accent">
  <button mat-raised-button [routerLink]="['../..']">
    <mat-icon>arrow_back</mat-icon>{{ 'BACK' | translate }}
  </button>
</mat-toolbar>
<mat-card class="device-stats">
  <mat-card-header *ngIf="device">
    <mat-card-title>{{'DEVICE.EQUIPMENT_STATISTICS' | translate}} {{ device.name }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="device-stats__content">
    <mat-card-subtitle>{{'DEVICE.SELECT_PERIOD' | translate}}</mat-card-subtitle>
    <div class="device-stats__content__options">
      <range-datepicker class="datepicker" (setRange)="setRange($event)" [initalRange]="lastWeek">
      </range-datepicker>
      <mat-form-field>
        <mat-label>{{'DEVICE.GRANULARITY' | translate}} :</mat-label>
        <mat-select [(value)]="granularity" (valueChange)="onSelectGranularity()">
          <mat-option *ngFor="let granularity of granularities" [value]="granularity">
            {{ ('DEVICE.CHARTS.GRANULARITY.' + granularity) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <app-device-durations-chart></app-device-durations-chart>
    <app-device-sessions-chart></app-device-sessions-chart>
  </mat-card-content>
</mat-card>
