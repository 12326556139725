import { DatePipe, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { License, LicenseService, ScopeService } from 'hcl-lib';
import { LicenseType, LicenseTypeEnum } from 'projects/hcl-portal/src/app/common/interfaces/license-type';
import { ContextService } from 'projects/hcl-portal/src/app/common/services/context/context.service';
import { LicenseTypeService } from 'projects/hcl-portal/src/app/common/services/license-type/license-type.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-license-status',
    templateUrl: './license-status.component.html',
    styleUrls: ['./license-status.component.scss'],
    standalone: true,
    imports: [DatePipe, UpperCasePipe, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, TranslateModule]
})
export class LicenseStatusComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  appName!: string
  customerId!: string

  license!: License | null
  licenseType!: LicenseType

  loading = false
  licenseRequired = false
  isLicenseTypeQuota = false

  constructor(
    private contextService: ContextService,
    private licenseService: LicenseService,
    private licenseTypeService: LicenseTypeService,
    private scopeService: ScopeService
  ) { }

  ngOnInit() {
    this.retrievePageApp()
    this.retrieveCustomerId()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  retrieveCustomerId() {
    this.subscriptions.add(
      this.scopeService.getScopeObservable().subscribe((customerId) => {
        if (this.customerId != customerId) {
          this.loading = true
          this.customerId = customerId as string
          this.retrieveCurrentLicense()
        }
      })
    )
  }

  retrievePageApp() {
    this.subscriptions.add(
      this.contextService.getPageApp().subscribe((appName) => {
        if (appName) {
          this.licenseRequired = true
        } else {
          this.licenseRequired = false
        }
        if (this.appName != appName) {
          this.loading = true
          this.appName = appName
          this.retrieveCurrentLicense()
        }
      })
    )
  }

  retrieveCurrentLicense() {
    if (this.customerId && this.appName) {
      this.subscriptions.add(
        this.licenseService.getCurrentLicense(this.customerId, this.appName).subscribe(
          (license) => {
            this.license = license
            this.retrieveLicenseType(license.licenseTypeId)
            this.loading = false
          },
          (err) => {
            this.license = null
            this.loading = false
          }
        )
      )
    } else {
      this.license = null
      this.loading = false
    }
  }

  retrieveLicenseType(licenseTypeId: string) {
    this.subscriptions.add(
      this.licenseTypeService.getLicenseType(licenseTypeId).subscribe((licenseType) => {
        this.licenseType = licenseType
        this.isLicenseTypeQuota = licenseType.type == LicenseTypeEnum.Quota
      })
    )
  }
}
