import { NgIf } from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import {Router, RouterModule} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  UserFieldsComponent
} from 'projects/hcl-portal/src/app/common/Layout/Components/widget/user/user-fields/user-fields.component';
import {UserService} from 'projects/hcl-portal/src/app/common/services/user/user.service';
import {Subscription} from 'rxjs';
import { User } from '../../../interfaces/user';


@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, MatToolbarModule, MatButtonModule, MatIconModule, TranslateModule, UserFieldsComponent]
})
export class UserCreateComponent implements OnInit, OnDestroy {

  @ViewChild(UserFieldsComponent) userFieldsComponent!: UserFieldsComponent;

  subscription: Subscription = new Subscription();
  title!: string
  action!: string

  constructor(
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.title = this.translateService.instant('USER_CREATE_COMPONENT.TITLE')
    this.action = this.translateService.instant('USER_CREATE_COMPONENT.ACTION')
  }

  doCreate($event: User) {
    this.subscription.add(
      this.userService.addUser(
        $event,
        this.userFieldsComponent.passwordCreateComponent.getPassword(),
        this.userFieldsComponent.passwordCreateComponent.isRandomGeneratedPassword(),
        this.userFieldsComponent.sendEmail
      ).subscribe(() => {
          this.router.navigate(["/users"])
        }
      ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
