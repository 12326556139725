import { configReducer } from './config.reducer';
import { scopeReducer } from './scope.reducer';

export interface HumecloudState {
  config: any;
  scope: any;
}

export const HUMECLOUD_INITIAL_STATE = { } as HumecloudState

export const rootReducer = {
  config: configReducer,
  scope: scopeReducer
}
