<mat-toolbar class="toolbar" color="accent">
  <div class="toolbar__left">
    <mat-icon>search</mat-icon>
    <form [formGroup]="userSearchForm">
      <mat-form-field class="toolbar__left__search">
        <input id="searchUserInput" matInput [formControlName]="'userSearchField'" (keyup)="handleSearchKeyUp($event)">
      </mat-form-field>
    </form>
    <mat-select class="toolbar__left__scope" [(value)]="selectedScope" (selectionChange)="onQueryScopeChange($event)">
      <mat-option *ngFor="let scope of QueryScopes" [value]="scope">
        {{ ("USER.QUERY_SCOPE." + scope) | translate }}
      </mat-option>
    </mat-select>
  </div>
  <div class="toolbar__right__buttons">
    <button *cmnIfPerm="['cmn:user:create'];op:'OR'" mat-raised-button [routerLink]="['/users/create']"
      routerLinkActive="router-link-active">
      <mat-icon>add</mat-icon>
      {{"ADD" | translate}}
    </button>
    <button *cmnIfPerm="['cmn:user:create'];op:'OR'" mat-raised-button [routerLink]="['/users/import']"
      routerLinkActive="router-link-active">
      <mat-icon>file_upload</mat-icon>
      {{"IMPORT" | translate}}
    </button>
  </div>
</mat-toolbar>

<div class="mb-3" class="div-mat-table">
  <mat-table [dataSource]="userDataSource" (matSortChange)="sortData($event)" matSort>

    <!-- email -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let data">
        <span class="show-mobile div-mat-table__label">Email: </span>
        {{data.user.email}}
      </mat-cell>
    </ng-container>

    <!-- lastName -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell class="name-header" *matHeaderCellDef mat-sort-header>{{"NAME" | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data">
        <span class="show-mobile div-mat-table__label">{{"NAME" | translate}}: </span>
        {{ data.user.lastName }}
      </mat-cell>
    </ng-container>

    <!-- clients -->
    <ng-container matColumnDef="clients">
      <mat-header-cell class="clients-header" *matHeaderCellDef>{{"CLIENTS" | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let data">
        <span class="show-mobile div-mat-table__label">{{"ORGANIZATION_TYPE.CLIENT" | translate}}: </span>
        <div class="customersList">{{data.customerNames}}</div>
      </mat-cell>
    </ng-container>

    <!-- Managed by -->
    <ng-container matColumnDef="managedBy">
      <mat-header-cell *matHeaderCellDef>{{ 'USER_LIST_COMPONENT.MANAGED_BY' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data">
        <span class="show-mobile div-mat-table__label">{{ 'USER_LIST_COMPONENT.MANAGED_BY' | translate }}: </span>
        <div class="customersList">{{data.managedBy}}</div>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell class="actions" *matCellDef="let data">
        <button *cmnIfPerm="['cmn:user:update'];op:'OR';cid:data.user.customerId" mat-icon-button color="primary"
          [routerLink]="['/users', data.user.id]" [queryParams]="{cid: data.user.customerId}">
          <mat-icon>edit</mat-icon>
        </button>
        <button *cmnIfPerm="['cmn:user:delete'];op:'OR';cid:data.user.customerId" mat-icon-button color="primary"
          (click)="doDelete(data.user)" id="deleteUserButton">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let user; columns: columnsToDisplay"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage($event)">
  </mat-paginator>
</div>