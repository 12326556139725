<div class="media-list">
  <div class="media-list__content">
    <mat-tab-group class="media-list__tabs" mat-stretch-tabs="false">
      <mat-tab [label]="'MEDIA_LIST.MY_MEDIAS.TAB' | translate | titlecase " class="media-list__tabs__tab">
        <app-media-list-widget cdkDropListGroup [mediaListWidgetType]="MediaListWidgetType.MY_MEDIAS">
        </app-media-list-widget>
      </mat-tab>
      <mat-tab [label]="'MEDIA_LIST.PUBLIC_MEDIAS.TAB' | translate  | titlecase" class="media-list__tabs__tab"
        *cmnIfPerm="['scr:public-media:read']">
        <app-media-list-widget cdkDropListGroup [mediaListWidgetType]="MediaListWidgetType.PUBLIC_MEDIAS">
        </app-media-list-widget>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
