<mat-card class="mb-3">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mat-card-content">
    <form [formGroup]="userForm" class="user-component">
      <mat-form-field class="user-component">
        <mat-label>{{'USER.FIRSTNAME' | translate}}</mat-label>
        <input matInput formControlName="firstName" id="inputFirstName">
      </mat-form-field>
      <mat-form-field class="user-component">
        <mat-label>{{'CUSTOMER.NAME' | translate}}</mat-label>
        <input matInput formControlName="lastName" id="inputLastName">
      </mat-form-field>
      <div *ngIf="showEmailInput">
        <mat-form-field class="user-component">
          <mat-label>{{'CUSTOMER.USER_EMAIL' | translate}}</mat-label>
          <input matInput formControlName="email" id="inputEmail" required>
        </mat-form-field>
      </div>
      <mat-form-field class="user-component">
        <mat-label>{{'PHONE' | translate}}</mat-label>
        <input matInput formControlName="phoneNumber" id="inputPhoneNumber">
      </mat-form-field>
    </form>
    <div *ngIf="showPasswordInput">
      <ng-template matStepLabel>{{'CUSTOMER.SET_PASSWORD' | translate}}</ng-template>
      <div class="user-component">
        <password-create class="password-create"></password-create>
      </div>
    </div>
    <div *ngIf="showCustomersInput" class="user-component">
      <label>{{'CUSTOMER.CHOOSE_CLIENT_FOR_USER' | translate}}</label>
      <app-recent-search-select class="user-component__customer-search" [placeholder]="selectCustomerPlaceholder"
        [selected]="customer" [recentChoices]="recentCustomers" (selectionChange)="updateUserCustomer($event)"
        (searchChange)="searchCustomer($event)" [foundChoices]="foundCustomers">
      </app-recent-search-select>
      <label>{{'CUSTOMER.CHOOSE_CLIENTS_USER_ACCESS' | translate}}</label>
      <div>
        <app-recent-search-select [placeholder]="selectCustomerPlaceholder" [recentChoices]="recentCustomers"
          (selectionChange)="selectCustomer($event)" (searchChange)="searchCustomer($event)"
          [foundChoices]="foundCustomers">
        </app-recent-search-select>
        <label class="label">{{'CUSTOMER.ASSOCIATE_ROLES_TO_CLIENTS' | translate}}</label>
        <div *ngFor="let customerRolesSelectData of customersRolesSelectData" class="roles-customer">
          <customer-roles-select (update)="updateUserRole($event)" [customerRolesSelect]="customerRolesSelectData">
          </customer-roles-select>
          <button mat-icon-button (click)="removeCustomer(customerRolesSelectData.customer)">
            <img src='/assets/images/screenlab/bin-icon.svg' alt="delete">
          </button>
        </div>
      </div>
    </div>
    <mat-checkbox *ngIf="showPasswordInput" class="notify-checkbox" [(ngModel)]="sendEmail">
      {{'CUSTOMER.NOTIFY_USER_BY_EMAIL' | translate}}</mat-checkbox>
  </mat-card-content>
  <mat-card-actions class="mard-card-actions">
    <button mat-raised-button color="accent" id="validateButton" [disabled]="userForm.invalid" (click)="doValidate()">
      <mat-icon>add</mat-icon> {{ action }}
    </button>
  </mat-card-actions>
</mat-card>
