import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from "@angular/router";
import { App, ScopeService, LicenseService, License, CmnIfPermDirective } from 'hcl-lib';
import { AppService } from '../../../../common/services/app/app.service';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LicenseTypeService } from "../../../services/license-type/license-type.service";
import { LicenseType } from "../../../interfaces/license-type";
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe, NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';

interface UserApp extends App {
  license?: License,
  licenseType?: LicenseType
}

interface SuggestedApp extends App {
  licenseTypes?: LicenseType[]
  trialLicenseType?: LicenseType
  buyCssClasses: string[]
  tryCssClasses: string[]
}

@Component({
    selector: 'user-app-app-list',
    templateUrl: './user-app-list.component.html',
    styleUrls: ['./user-app-list.component.scss'],
    standalone: true,
    imports: [RouterModule, NgClass, NgFor, NgIf, LowerCasePipe, UpperCasePipe, TranslateModule, CmnIfPermDirective]
})
export class UserAppListComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  userApps: UserApp[] = [];
  suggestedApps: SuggestedApp[] = [];
  appNamesNotToDisplay = ['humecore']


  constructor(
    private appService: AppService,
    private licenseService: LicenseService,
    private licenseTypeService: LicenseTypeService,
    private scopeService: ScopeService,
    private router: Router
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.scopeService.getAppsObservable().pipe(
        mergeMap((userApps) => this.appService.getApps().pipe(map(apps => ({ userApps, apps }))))
      ).subscribe(allApps => {
        this.userApps = allApps.userApps
        this.suggestedApps = allApps.apps.filter(app => this.userApps.find(userApp => userApp.id === app.id) == null)
          .map(app => { return {
            id: app.id,
            name: app.name,
            buyCssClasses: [ 'app-button', 'buy-btn' ],
            tryCssClasses: [ 'app-button', 'try-btn' ]
          } as SuggestedApp })

        this.userApps = this.userApps.filter((app => !this.appNamesNotToDisplay.includes(app.name!)))
        this.userApps.forEach(app => this.retrieveLicense(app))
        this.suggestedApps = this.suggestedApps.filter((app => !this.appNamesNotToDisplay.includes(app.name!)))
        this.suggestedApps.forEach(app => this.retrieveTrialLicenseType(app))
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setSubscription(subscription: Subscription) {
    this.subscriptions = subscription;
  }

  retrieveLicense(userApp: UserApp) {
    const customerId = this.scopeService.getScope()
    this.subscriptions.add(
      this.licenseService.getCurrentLicense(customerId, userApp.name as string).subscribe(license => {
        userApp.license = license
        this.retrieveLicenseType(userApp)
      })
    )
  }

  retrieveLicenseType(userApp: UserApp) {
    this.subscriptions.add(
      this.licenseTypeService.getLicenseType((userApp.license as License).licenseTypeId).subscribe(licenseType => {
        userApp.licenseType = licenseType
      })
    )
  }

  retrieveTrialLicenseType(suggestedApp: SuggestedApp) {
    this.subscriptions.add(
      this.licenseTypeService.getLicenseTypesByAppId(suggestedApp.id).subscribe(licenseTypes => {
        suggestedApp.licenseTypes = licenseTypes
        suggestedApp.trialLicenseType = licenseTypes.find(licenseType => licenseType.trial)
        suggestedApp.tryCssClasses = [ 'app-button', 'try-btn',
          suggestedApp.trialLicenseType ? `hc-btn-${suggestedApp.name}-primary` : `hc-btn-${suggestedApp.name}-secondary` ]
        suggestedApp.buyCssClasses = [ 'app-button', 'buy-btn',
          suggestedApp.trialLicenseType ? `hc-btn-${suggestedApp.name}-secondary` : `hc-btn-${suggestedApp.name}-primary` ]
      })
    )
  }

  doSubscribe(app: App) {
    this.subscriptions.add(
      this.licenseService.subscribeTrialLicense(app.id).subscribe((license) => {
        this.scopeService.refreshApps();
      })
    )
  }

  navigateToApp(appName?: string) {
    if (typeof appName === 'string') {
      let route = this.getDefaultRouteForApp(appName, this.router.config)
      if (route == null) return
      this.router.navigate([route.path])
    }
  }

  private getDefaultRouteForApp(appName: string, routes: Routes): any {
    for (let route of routes) {
      if (route.children) {
        let foundChildRoute = this.getDefaultRouteForApp(appName, route.children)
        if (foundChildRoute) {
          return foundChildRoute;
        }
      }

      let data = route.data;
      if (data && data['app'] == appName) {
        return route;
      }
    }
  }

  getAppIcon(appName?: string): string {
    if (typeof appName === 'string') {
      return this.appService.getAppIcon(appName)
    }
    return ''
  }

  getAppLogo(appName: string): string {
    return this.appService.getAppLogo(appName)
  }

  getAppColor(appName: string): string {
    return this.appService.getAppColor(appName)
  }

  getAppDisplayNameHtml(appName?: string) {
    if (typeof appName === 'string') {
      return this.appService.getAppDisplayNameHtml(appName)
    }
    return ''
  }
}
