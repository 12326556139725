<form [formGroup]="svdForm">

  <!-- enabled -->
  <mat-checkbox formControlName="enabled">
    {{ "DEVICE.SVD_CONFIG.ENABLED" | translate }}
  </mat-checkbox>

  <!-- url -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.URL" | translate }}</mat-label>
    <input matInput formControlName="url" [placeholder]="'DEVICE.SVD_CONFIG.URL' | translate">
  </mat-form-field>

  <!-- playerId -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.PLAYER_ID" | translate }}</mat-label>
    <input matInput formControlName="playerId" [placeholder]="'DEVICE.SVD_CONFIG.PLAYER_ID' | translate">
  </mat-form-field>

  <!-- login -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.LOGIN" | translate }}</mat-label>
    <input matInput formControlName="login" [placeholder]="'DEVICE.SVD_CONFIG.LOGIN' | translate">
  </mat-form-field>

  <!-- passwordInput -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.PASSWORD" | translate }}</mat-label>
    <input matInput type="password" formControlName="passwordInput" [placeholder]="'DEVICE.SVD_CONFIG.PASSWORD' | translate">
  </mat-form-field>

  <!-- updatePeriodInSeconds -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.UPDATE_PERIOD_IN_SECONDS" | translate }}</mat-label>
    <input matInput formControlName="updatePeriodInSeconds"
      [placeholder]="'DEVICE.SVD_CONFIG.UPDATE_PERIOD_IN_SECONDS' | translate">
  </mat-form-field>

  <!-- backgroundColor -->
  <mat-form-field>
    <mat-label>{{ "DEVICE.SVD_CONFIG.BACKGROUND_COLOR" | translate }}</mat-label>
    <input matInput type="color" formControlName="backgroundColor">
  </mat-form-field>
</form>