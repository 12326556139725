import { Injectable } from "@angular/core"
import {HttpShowError, InvocationResult, KeycloakService} from "hcl-lib"
import {Observable} from "rxjs"
import {environment} from "../../../../environments/environment"
import {map} from "rxjs/operators"
import {HttpClient, HttpParams} from "@angular/common/http"
import {UserPreferenceDto} from "../../interfaces/dto/user-preference-dto"
import {UserNotificationPreferenceDto} from "../../interfaces/dto/user-notification-preference-dto"
import {UserNotificationPreference} from "../../interfaces/user-notification-preference"
import {UserPreference} from "../../interfaces/user-preference"

@Injectable({
  providedIn: "root"
})
export class EventUserPreferenceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError()
  getEventUserPreference(
    userId: string
  ): Observable<UserPreferenceDto> {
    return this.httpClient.get(`${environment.cmnEventApiBaseUrl}/user-preferences/${userId}`).pipe(
      map((res: any) => {
        return (res as InvocationResult)?.data
      })
    )
  }

  @HttpShowError()
  getEventUserNotificationPreferenceWithPaging(
    userId: string,
    page: number,
    perPage: number,
  ): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())
    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/user-notification-preferences/${userId}`,
      { params: queryParams }
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  createEventUserPreference(
    createEventUserPreference: UserPreference
  ): Observable<UserPreference> {
    return this.httpClient.post(
      `${environment.cmnEventApiBaseUrl}/user-preferences/`,
      createEventUserPreference as UserPreferenceDto
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  createEventUserNotificationPreference(
    createEventUserNotificationPreference: UserNotificationPreference
  ): Observable<UserNotificationPreference> {
    return this.httpClient.post(
      `${environment.cmnEventApiBaseUrl}/user-notification-preferences/`,
      createEventUserNotificationPreference as UserNotificationPreferenceDto
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  updateEventUserPreference(
    eventUserPreference: UserPreference
  ): Observable<UserPreference> {
    return this.httpClient.put(
      `${environment.cmnEventApiBaseUrl}/user-preferences/${eventUserPreference.userId}`,
      eventUserPreference as UserPreferenceDto
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  updateEventUserNotificationPreference(
    updateEventUserNotificationPreference: UserNotificationPreference
  ): Observable<UserNotificationPreference> {
    return this.httpClient.put(
      `${environment.cmnEventApiBaseUrl}/user-notification-preferences/${updateEventUserNotificationPreference.id}`,
      updateEventUserNotificationPreference as UserNotificationPreferenceDto
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  deleteEventUserPreference(id: string): Observable<boolean> {
    const url = `${environment.cmnEventApiBaseUrl}/user-notification-preferences/${id}`
    return this.httpClient.delete(url).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
