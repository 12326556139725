import { Routes } from '@angular/router';
import { AppGuard } from './common/guards/app-guard.service';
import { AppSimpleAppGuard } from './common/guards/app-simple-app-guard.service';
import { ContactAdministratorGuard } from './common/guards/contact-administrator-guard.service';
import { SelfRegistrationGuard } from './common/guards/self-registration-guard.service';
import { BaseLayoutComponent } from './common/Layout/base-layout/base-layout.component';
import { UserAppListComponent } from './common/pages/app/user-app-list/user-app-list.component';
import { ContactAdministratorComponent } from './common/pages/contact/contact-administrator/contact-administrator.component';
import { ContactIndexComponent } from './common/pages/contact/contact-index/contact-index.component';
import { CustomerDetailsComponent } from './common/pages/customer/customer-details/customer-details.component';
import { DeviceDetailsComponent } from './common/pages/device/device-details/device-details.component';
import { DeviceListComponent } from './common/pages/device/device-list/device-list.component';
import { DeviceStatsComponent } from './common/pages/device/device-stats/device-stats.component';
import { MyLicensesComponent } from './common/pages/license/my-licenses/my-licenses.component';
import { MediaListComponent } from './common/pages/media/media-list/media-list.component';
import { SelfRegistrationComponent } from './common/pages/registration/self-registration.component';
import { TagListComponent } from './common/pages/tag/tag-list.component';
import { UserAccountDetailsComponent } from './common/pages/user/user-account-details/user-account-details.component';
import { UserCreateComponent } from './common/pages/user/user-create/user-create.component';
import { UserDetailsComponent } from './common/pages/user/user-details/user-details.component';
import { UserImportComponent } from './common/pages/user/user-import/user-import.component';
import { UserListComponent } from './common/pages/user/user-list/user-list.component';
import { PluginConfigsComponent } from './common/pages/plugin/plugin-configs/plugin-configs.component';
import { EventListComponent } from './common/pages/event/event-list/event-list.component';
import { EventUserPreferenceComponent } from './common/pages/event/event-user-preference/event-user-preference.component';
import {LocationListComponent} from "./common/pages/location/location-list/location-list.component";
import {LocationDetailsComponent} from "./common/pages/location/location-details/location-details.component";

export const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AppGuard, AppSimpleAppGuard],
    children: [
      { path: '', redirectTo: 'user-apps', pathMatch: 'full' },
      { path: 'user-apps', component: UserAppListComponent, data: { menuId: 'appsMenu' } },
      { path: 'contact', component: ContactIndexComponent, data: {} },
      { path: 'devices', component: DeviceListComponent, data: {} },
      { path: 'devices/:id', component: DeviceDetailsComponent, data: {} },
      { path: 'devices/:id/stats', component: DeviceStatsComponent, data: {} },
      { path: 'locations', component: LocationListComponent, data: {} },
      { path: 'locations/:id', component: LocationDetailsComponent, data: {} },
      { path: 'medias', component: MediaListComponent, data: {} },
      { path: 'myLicenses', component: MyLicensesComponent, data: {} },
      { path: 'my-plugin-configs', component: PluginConfigsComponent, data: {} },
      { path: 'plugin-configs', component: PluginConfigsComponent, data: {} },
      { path: 'users', component: UserListComponent, data: { menuId: 'usersMenu' } },
      { path: 'events', component: EventListComponent, data: { menuId: 'eventsMenu' } },
      { path: 'event-user-preference', component: EventUserPreferenceComponent, data: {} },
      { path: 'users/create', component: UserCreateComponent, data: {} },
      { path: 'users/import', component: UserImportComponent, data: {} },
      { path: 'me', component: UserAccountDetailsComponent },
      { path: 'users/:id', component: UserDetailsComponent, data: {} },
      { path: 'tags', component: TagListComponent },
      { path: 'customers/:id', component: CustomerDetailsComponent, data: {} },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.routes')
      },
      {
        path: 'humefan',
        data: { app: 'humefan' },
        loadChildren: () => import('./humefan/humefan.routes')
      },
      {
        path: 'humenu',
        data: { app: 'humenu' },
        loadChildren: () => import('./humenu/humenu.routes')
      },
      {
        path: 'humeorder',
        data: { app: 'humeorder' },
        loadChildren: () => import('./humeorder/humeorder.routes')
      },
      {
        path: 'humetips',
        data: { app: 'humetips' },
        loadChildren: () => import('./humetips/humetips.routes')
      },
      {
        path: 'humecharity',
        data: { app: 'humecharity' },
        loadChildren: () => import('./humetips/humetips.routes')
      },
      {
        path: 'humehospitality',
        data: { app: 'humehospitality' },
        loadChildren: () => import('./humehospitality/humehospitality.routes')
      },
      {
        path: 'humedirectory',
        data: { app: 'humedirectory' },
        loadChildren: () => import('./humedirectory/humedirectory.routes')
      },
      {
        path: 'humehealth',
        data: { app: 'humehealth' },
        loadChildren: () => import('./humehealth/humehealth.routes')
      },
      {
        path: 'humeiptv',
        data: { app: 'humeiptv' },
        loadChildren: () => import('./humeiptv/humeiptv.routes')
      },
      {
        path: 'humecore',
        data: { app: 'humecore' },
        loadChildren: () => import('./humecore/humecore.routes')
      },
      {
        path: 'screenlab',
        data: { app: 'screenlab' },
        loadChildren: () => import('./screenlab/screenlab.routes')
      },
      {
        path: "humecanva",
        data: { app: 'humecanva' },
        loadChildren: () => import('./humecanva/humecanva.routes')
      },
      {
        path: 'realestate',
        loadChildren: () => import('./real-estate/real-estate.routes')
      },
      {
        path: 'pharmonweb',
        loadChildren: () => import('./pharmonweb/pharmonweb.routes')
      },
      {
        path: 'automobile',
        loadChildren: () => import('./automobile/automobile.routes')
      },
    ]
  },
  { path: 'contactAdmin', component: ContactAdministratorComponent, canActivate: [ContactAdministratorGuard], canDeactivate: [ContactAdministratorGuard] },
  { path: 'register', component: SelfRegistrationComponent, canActivate: [SelfRegistrationGuard], canDeactivate: [SelfRegistrationGuard] },
  { path: '**', redirectTo: '' }
];
