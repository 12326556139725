<div class="notifications" ngbDropdown>
  <button ngbDropdownToggle>
    <fa-icon [icon]="faBell"></fa-icon>
    <span class="notifications__length badge">{{ unreadNotifications.length }}</span>
  </button>

  <div ngbDropdownMenu>
    <div class="dropdown-menu-header">
      <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
        <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
        <div class="menu-header-content text-center text-white">
          <h6 class="menu-header-subtitle mt-0">{{ 'NOTIFICATIONS.HEADER' | translate }}</h6>
        </div>
      </div>
    </div>
    <div class="notification-container">
      <ng-container *ngFor="let notification of unreadNotifications">
        <div class="dropdown-item notification-container__item">
          <div [class]="'dot dot-' + notification.severity"></div>
          <label>{{ notification.label }}</label>
        </div>
      </ng-container>
      <div class="notification-container__link">
        <a routerLink="/events">{{ "NOTIFICATIONS.SEE_ALL" | translate }}</a>
      </div>
    </div>
  </div>
</div>
