<form [formGroup]="peripheralConfigForm" class="peripherals">
  <div class="peripherals_list">
    <span class="peripherals_list_title">
      {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.TITLE' | translate}}
    </span>
    <mat-form-field appearance="outline">
      <mat-label>{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.ADD_TERMINAL' | translate }}</mat-label>
      <mat-select formControlName="paymentTerminalType" (selectionChange)="retrieveReaderInformations()">
        <mat-option value="NONE">
          {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.TERMINAL_TYPES.NONE' | translate }}
        </mat-option>
        <mat-option value="VERIFONE">{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.TERMINAL_TYPES.VERIFONE' | translate }}
        </mat-option>
        <mat-option value="STRIPE_CLOUD">{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.TERMINAL_TYPES.STRIPE_CLOUD' | translate }}
        </mat-option>
        <mat-option value="STRIPE_SDK">{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.TERMINAL_TYPES.STRIPE_SDK' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- STRIPE CLOUD -->
    <mat-card *ngIf="paymentTerminalType == PaymentTerminalType.STRIPE_CLOUD" class="peripherals_list_reader">
      <mat-card-title>
        <span>
          <img src="/assets/images/device/hardware/stripe.png">
          {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.TITLE' | translate }}
        </span>
        <button type="button" mat-button color="primary" (click)="retrieveStripeReaderById()">
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngIf="currentStripeReader">
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.DETAILS.DEVICE_NAME' | translate }} :</div>
            <div>{{ currentStripeReader.label }}</div>
          </div>
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.DETAILS.DEVICE_TYPE' | translate }} :</div>
            <div>{{ currentStripeReader.type }}</div>
          </div>
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.DETAILS.DEVICE_IP' | translate }} :</div>
            <div>{{ currentStripeReader.ipAddress }}</div>
          </div>
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.DETAILS.DEVICE_STATUS' | translate }} :</div>
            <span class="reader-status-value reader-status-success" *ngIf="currentStripeReader.online">
              {{ 'DEVICE.STATUS.ONLINE' | translate }}
            </span>
            <span class="reader-status-value reader-status-error" *ngIf="!currentStripeReader.online">
              {{ 'DEVICE.STATUS.OFFLINE' | translate }}
            </span>
          </div>
        </div>
        <div *ngIf="!currentStripeReader">
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.NONE_SELECTED' | translate }}
        </div>
      </mat-card-content>
      <mat-card-footer>
        <button *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" type="button" mat-raised-button color="primary"
                (click)="openStripeReaderDialog()">
          <mat-icon>edit</mat-icon>
          <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.EDIT' | translate }}</span>
        </button>
        <button *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" class="peripherals__footer__button__delete"
                type="button" mat-raised-button (click)="remove()">
          <mat-icon>delete</mat-icon>
          <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.DELETE' | translate }}</span>
        </button>
      </mat-card-footer>
    </mat-card>

    <!-- STRIPE SDK -->
    <mat-card *ngIf="paymentTerminalType == PaymentTerminalType.STRIPE_SDK" class="peripherals_list_reader">
      <mat-card-title>
        <span>
          <img src="/assets/images/device/hardware/stripe.png">
          {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.TITLE' | translate }}
        </span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="reader-sdk-content  " formGroupName="stripeSdkConfig">
          <mat-form-field appearance="outline">
            <mat-label>{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.CONNECTION_MODE.TITLE' | translate }}</mat-label>
            <mat-select formControlName="stripeSDKConnectionMethod" (selectionChange)="test()">
              <mat-option value="USB">
                {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.CONNECTION_MODE.USB' | translate }}
              </mat-option>
              <mat-option value="BLUETOOTH" disabled>
                {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.CONNECTION_MODE.BLUETOOTH' | translate }}
              </mat-option>
              <mat-option value="TAPTOPAY" disabled>
                {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.CONNECTION_MODE.TAPTOPAY' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE_SDK.LOCATION.TITLE' | translate }}</mat-label>
            <mat-select formControlName="stripeLocationId">
              <mat-option *ngFor="let location of locations" [value]="location.id">
                {{ location.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- VERIFONE -->
    <mat-card *ngIf="paymentTerminalType == PaymentTerminalType.VERIFONE" class="peripherals_list_reader">
      <mat-card-title>
        <span>
          <img src="/assets/images/device/hardware/verifone.png">
          {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.TITLE' | translate }}
        </span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngIf="currentVerifoneReader">
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.DETAILS.SERIAL_NUMBER' | translate }} :</div>
            <div>{{ currentVerifoneReader.serialNumber }}</div>
          </div>
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.DETAILS.DESCRIPTION' | translate }} :</div>
            <div>{{ currentVerifoneReader.description }}</div>
          </div>
          <div class="intel">
            <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.DETAILS.POSTE' | translate }} :</div>
            <div>{{ currentVerifoneReader.poste }}</div>
          </div>
        </div>
        <div *ngIf="!currentVerifoneReader">
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.NONE_SELECTED' | translate }}
        </div>
      </mat-card-content>
      <mat-card-footer>
        <button *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" type="button" mat-raised-button color="primary"
                (click)="openVerifoneReaderDialog()">
          <mat-icon>edit</mat-icon>
          <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.EDIT' | translate }}</span>
        </button>
        <button *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" class="peripherals__footer__button__delete"
                type="button" mat-raised-button (click)="remove()">
          <mat-icon>delete</mat-icon>
          <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.DELETE' | translate }}</span>
        </button>
      </mat-card-footer>
    </mat-card>

  </div>
</form>
