import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContextService } from 'projects/hcl-portal/src/app/common/services/context/context.service';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-guides',
    templateUrl: './guides.component.html',
    styleUrls: ['./guides.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, MatButtonModule, FontAwesomeModule, TranslateModule]
})
export class GuidesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  appName!: string

  faLightbulb = faLightbulb

  constructor(
    private contextService: ContextService
  ) { }

  ngOnInit() {
    this.retrievePageApp()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  retrievePageApp() {
    this.subscriptions.add(
      this.contextService.getPageApp().subscribe(appName => {
        this.appName = appName
      })
    )
  }
}
