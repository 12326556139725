/// <reference types="@angular/localize" />

import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import "hammerjs";
import { KeycloakService } from "hcl-lib";
import { AppComponent } from "./app/app.component";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular-ivy";
import { appConfig } from "./app/app.config";
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/en';

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.production ? "prod" : "dev",
  tracePropagationTargets: environment.sentry.tracePropagationTargets,
  tracesSampleRate: environment.sentry.tracesSampleRate,
});

if (environment.production) {
  enableProdMode();
}

KeycloakService.init(environment.keycloak.url, environment.keycloak.realm, environment.keycloak.clientId)
  .then(() => {
    bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
  })
  .catch((err: any) => console.error(err));
