import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'hcl-lib';
import { Observable } from 'rxjs';
import { SelfRegistrationComponent } from '../pages/registration/self-registration.component';


@Injectable()
export class ContactAdministratorGuard  {

  constructor(
    private keycloakService: KeycloakService
  ) { }

  canDeactivate(
    component: SelfRegistrationComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.keycloakService.getApiUserId() != undefined || this.keycloakService.usesDefaultRealm()
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.keycloakService.getApiUserId() == undefined && !this.keycloakService.usesDefaultRealm()
  }
}
