<h1 mat-dialog-title>{{ "EVENT.USER_PREFERENCE.TITLE_EDIT" | translate }}</h1>
<div *ngIf="userNotificationPreferenceFieldsForm">
  <form [formGroup]="userNotificationPreferenceFieldsForm" (ngSubmit)="onSubmit()" class="form-container">
    <div class="form-content">
      <label>{{ "EVENT.USER_PREFERENCE.KEY_NAME" | translate }}</label>
      <app-recent-search-select class="select-scope"
                                (selectionChange)="selectEventType($event)" (searchChange)="searchEventType($event)"
                                [foundChoices]="foundEventType" [selected]="userNotificationPreferenceFieldsForm.value.eventKey">
      </app-recent-search-select>
    </div>
    <div class="form-content">
      <div class="form-content">
        <label>{{ "EVENT.USER_PREFERENCE.TARGET_CUSTOMER_PLACEHOLDER" | translate }}</label>
        <app-recent-search-select class="select-scope"
                                  (selectionChange)="selectCustomer($event)" (searchChange)="searchCustomer($event)"
                                  [foundChoices]="foundCustomers">
        </app-recent-search-select>
      </div>
      <div class="customer-container">
        <div class="delete-button" *ngFor="let customer of userNotificationPreferenceFieldsForm.value.targetCustomers">
          <label> {{ customer.name }} </label>
          <button mat-mini-fab color="primary" (click)="onClickRemoveCustomer(customer.id)" >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-content">
      <label>{{ "EVENT.USER_PREFERENCE.CHANNELS.PLACEHOLDER" | translate }}</label>
      <searchable-select formControlName="channels"
                         [showSelectArrow]="true" [placeholder]="'EVENT.USER_PREFERENCE.CHANNELS.PLACEHOLDER' | translate"
                         [multipleSelect]="true" [stickyPlaceholder]="false">
      </searchable-select>
    </div>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
      <button mat-raised-button color="accent" [disabled]="!userNotificationPreferenceFieldsForm.valid" type="submit">
        <mat-icon>done</mat-icon>
        {{ 'VALIDATE' | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</div>
