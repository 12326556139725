import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { User } from 'projects/hcl-portal/src/app/common/interfaces/user';
import { UserService } from 'projects/hcl-portal/src/app/common/services/user/user.service';
import { Subscription } from 'rxjs';
import { UserFieldsComponent } from '../../../Layout/Components/widget/user/user-fields/user-fields.component';


@Component({
    selector: 'user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    standalone: true,
    imports: [RouterModule, NgIf, MatToolbarModule, MatButtonModule, MatIconModule, TranslateModule, UserFieldsComponent]
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  user!: User;
  subscriptions: Subscription = new Subscription();
  title!: string
  action!: string

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeUserData();
    this.action = this.translateService.instant('USER_DETAILS_COMPONENT.ACTION')
  }

  initializeUserData() {
    this.subscriptions.add(
      this.userService.getUser(this.route.snapshot.paramMap.get('id') as string, this.route.snapshot.queryParams['cid']).subscribe(user => {
        this.user = user
        this.title = this.translateService.instant('USER_DETAILS_COMPONENT.TITLE') + " " + this.user.email
      })
    )
  }

  doUpdate($event: User) {
    this.subscriptions.add(this.userService.updateUser($event, this.route.snapshot.queryParams['cid']).subscribe(() => {
      this.router.navigate(["/users"]);
    }));
  }
}
