import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {StripeReader} from "../../interfaces/stripe-reader";
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {HttpShowError, InvocationResult} from "hcl-lib";
import {KioskApplication} from "../../interfaces/kiosk-config";
import {StripeLocation} from "../../interfaces/stripe-location";

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  @HttpShowError()
  getStripeReaders(
    app: KioskApplication,
    updateAll: boolean,
    onlyNotPaired: boolean
  ): Observable<StripeReader[]> {
    const url = `${environment.deviceApiBaseUrl}/stripe-readers`
    let queryParams = new HttpParams()
      .set('app', app)
      .set('update', updateAll)
      .set('onlyNotPaired', onlyNotPaired)

    return this.httpClient.get(url, {params: queryParams}).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  updateStripeReaders(reader: StripeReader) {
    const url = `${environment.deviceApiBaseUrl}/stripe-readers/${reader.id}`

    return this.httpClient.put(url, reader).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  getStripeReader(
    id: string,
    app: KioskApplication
  ) : Observable<StripeReader | null> {
    const url = `${environment.deviceApiBaseUrl}/stripe-readers/${id}`
    let queryParams = new HttpParams()
      .set('app', app)

    return this.httpClient.get(url, {params: queryParams}).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

  getStripeLocation(
    app: KioskApplication
  ) : Observable<StripeLocation[] | null> {
    const url = `${environment.deviceApiBaseUrl}/stripe-locations`
    let queryParams = new HttpParams()
      .set('app', app)

    return this.httpClient.get(url, {params: queryParams}).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }
}
