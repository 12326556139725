import {Directive, ElementRef, Input, OnDestroy} from '@angular/core';

@Directive({
    selector: '[cmnHideIfNoChildren]',
    standalone: true
})
export class CmnHideIfNoChildrenDirective implements OnDestroy {
  _observer!: MutationObserver;

  constructor(private el: ElementRef) {
    // do nothing
  }

  ngOnDestroy() {
    this.disconnectObserver()
  }

  @Input()
  set cmnHideIfNoChildren(querySelector: string) {
    this.searchElementAndHideIfNeeded(querySelector)

    this.disconnectObserver()

    this._observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation, index) => {
        if (mutation.type === 'childList') {
          if (querySelector) {
            this.searchElementAndHideIfNeeded(querySelector)
          }
        }
      });
    });
    this._observer.observe(
      this.el.nativeElement,
      {attributes: true, childList: true, characterData: true}
    );
  }

  private searchElementAndHideIfNeeded(querySelector: string) {
    const content = this.el.nativeElement.querySelector(querySelector);
    if (content && content.children.length === 0) {
      this.el.nativeElement.style.display = 'none';
    }
  }

  private disconnectObserver() {
    if (this._observer) {
      this._observer.disconnect();
    }
  }
}
