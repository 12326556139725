import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, RouterModule } from '@angular/router';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Device } from '../../../interfaces/device';
import { Granularity } from "../../../interfaces/stats";
import { DeviceDurationsChartComponent } from '../../../Layout/Components/widget/charts/device-durations-chart/device-durations-chart.component';
import { DeviceSessionsChartComponent } from '../../../Layout/Components/widget/charts/device-sessions-chart/device-sessions-chart.component';
import { DeviceService } from '../../../services/device/device.service';
import { StatsService } from '../../../services/stats/stats.service';
import { NgFor, NgIf } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { RangeDatepickerComponent } from "hcl-lib";
import { MatCardModule } from "@angular/material/card";

const moment = _rollupMoment || _moment

@Component({
    selector: 'app-device-stats',
    templateUrl: './device-stats.component.html',
    styleUrls: ['./device-stats.component.scss'],
    standalone: true,
    imports: [RouterModule, NgFor, NgIf, ReactiveFormsModule, MatSelectModule, MatToolbarModule, MatButtonModule, MatIconModule, MatCardModule, TranslateModule, RangeDatepickerComponent, DeviceDurationsChartComponent, DeviceSessionsChartComponent]
})
export class DeviceStatsComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  device!: Device

  @ViewChild(DeviceDurationsChartComponent) durations!: DeviceDurationsChartComponent
  @ViewChild(DeviceSessionsChartComponent) sessions!: DeviceSessionsChartComponent

  private now = new Date()
  lastWeek: { start: Date, end: Date } = {
    start: moment(this.now).subtract(6, "day").toDate(),
    end: this.now
  }
  range: {[key: string]: string} = {}

  granularities = Object.values(Granularity)
  granularity: Granularity = Granularity.DAY

  constructor(
    private deviceService: DeviceService,
    private statsService: StatsService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.route.params.pipe(
        mergeMap(params => this.deviceService.getDevice(params['id']))
      ).subscribe(device => {
        this.device = device
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  setRange(range: { start: Date, end: Date }) {
    this.range['start'] = range.start.toISOString()
    this.range['end'] = range.end.toISOString()
    this.refreshCharts()
  }

  onSelectGranularity() {
    this.refreshCharts()
  }

  private refreshCharts() {
    this.subscription.add(
      this.route.params.pipe(
        mergeMap(params => this.statsService.getDeviceEvents(params['id'], this.granularity, this.range['start'], this.range['end']))
      ).subscribe(events => {
        this.durations.refresh(events, this.granularity)
        this.sessions.refresh(events, this.granularity)
      })
    )
  }
}
