import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CmnIfPermDirective, EnumToArrayPipe, FormControlToFormGroupPipe, ScopeService, Tag, TagService, TargetType, TimeSlot } from "hcl-lib";
import {
  Device,
  DeviceOrientation,
  DeviceRemoteControlConfig,
  DeviceSupportedLanguage,
  DeviceTeamViewerConfig,
  DeviceType,
  DeviceVpnConfig,
  DeviceWindowsConfig
} from 'projects/hcl-portal/src/app/common/interfaces/device';
import { DeviceService } from 'projects/hcl-portal/src/app/common/services/device/device.service';
import { Subscription, zip } from 'rxjs';
import { mergeMap, tap } from "rxjs/operators";
import * as _sha1 from 'js-sha1';
import { default as _rollupSha1 } from 'js-sha1'
import { DeviceSvdConfig } from "../../../interfaces/device-svd-config";
import { DeviceMigrateDialogComponent } from "../device-migrate-dialog/device-migrate-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CalendarComponent } from "../../../Layout/Components/widget/calendar/calendar.component";
import { SearchableTimeZoneData } from "../../../util/searchable-timezone-data";
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { SingleSearchableSelectComponent } from "../../../Layout/Components/widget/single-searchable-select/single-searchable-select.component";
import { DeviceDetailsStatusComponent } from "../../../Layout/Components/widget/device-details-status/device-details-status.component";
import { DevicePeripheralComponent } from "../device-peripherals/device-peripheral/device-peripheral.component";
import { CmnIfPluginDirective } from "../../../directives/cmn-if-plugin/cmn-if-plugin.directive";
import { DeviceDetailsActionsComponent } from "../../../Layout/Components/widget/device-details-actions/device-details-actions.component";
import { DeviceKioskComponent } from "../device-kiosk/device-kiosk.component";
import { DeviceSvdComponent } from "../device-svd/device-svd.component";

const sha1 = _rollupSha1 || _sha1

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
  standalone: true,
  imports: [RouterModule, NgFor, NgIf, NgSwitch, NgSwitchCase, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatTabsModule, MatCardModule, MatToolbarModule, MatButtonModule, MatIconModule, MatDividerModule, TranslateModule, CmnIfPermDirective, CmnIfPluginDirective, EnumToArrayPipe, DevicePeripheralComponent, SingleSearchableSelectComponent, DeviceDetailsStatusComponent, CalendarComponent, DeviceDetailsActionsComponent, DeviceKioskComponent, DeviceSvdComponent, FormControlToFormGroupPipe]
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  device!: Device
  deviceForm!: UntypedFormGroup
  deviceTypesKeys: string[] = Object.keys(DeviceType).filter(value => isNaN(Number(value)))
  deviceOrientationKeys: string[] = Object.keys(DeviceOrientation).filter(value => isNaN(Number(value)))

  deviceTags: Tag[] = []
  filteredDeviceTags!: Tag[]
  mediaTags: Tag[] = []
  filteredMediaTags!: Tag[]

  useCustomerOpeningTimeSlots: boolean = true
  customerOpeningTimeSlots: TimeSlot[] = []

  useUpdatingTimeSlots: boolean = false
  useCustomerUpdatingTimeSlots: boolean = true
  customerUpdatingTimeSlots: TimeSlot[] = []

  zoneIdSearchableData = SearchableTimeZoneData

  @ViewChild("searchDeviceTag") searchDeviceTag!: ElementRef
  @ViewChild("searchMediaTag") searchMediaTag!: ElementRef
  @ViewChild("remoteControlConfigTypeSelect") remoteControlConfigTypeSelect!: MatSelect
  @ViewChild("openingHoursCalendar") openingHoursCalendar!: CalendarComponent
  @ViewChild("updatingHoursCalendar") updatingHoursCalendar!: CalendarComponent

  DeviceSupportedLanguages = DeviceSupportedLanguage

  constructor(
    private deviceService: DeviceService,
    private scopeService: ScopeService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private tagService: TagService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.deviceForm = this.formBuilder.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      orientation: [null, Validators.required],
      muted: [null, Validators.required],
      comment: [null],
      legacyScreenlabUser: [null],
      pairingCode: [null],
      addedTags: [[]],
      excludedTags: [[]],
      autoPowerOnOff: [null, Validators.required],
      remoteControlConfigs: this.formBuilder.array([]),
      useRealTime: [null, Validators.required],
      language: [null],
      zoneId: [null]
    })

    this.subscriptions.add(
      this.scopeService.getScopeCustomer().subscribe(customer => {
        this.customerOpeningTimeSlots = customer?.openingTimeSlots ?? []
        this.customerUpdatingTimeSlots = customer?.updatingTimeSlots ?? []
      })
    )

    this.route.params.subscribe(params => {
      const deviceId = params['id']
      this.retrieveDevice(deviceId)
    })
  }

  get remoteControlConfigs() {
    return this.deviceForm.controls["remoteControlConfigs"] as UntypedFormArray
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDevice(id: string) {
    this.subscriptions.add(
      this.deviceService.getDevice(id).pipe(
        tap(device => {
          this.device = device
          this.deviceForm.patchValue(device)
          device.remoteControlConfigs.forEach(config => this.createConfig(config))
          if (device.openingTimeSlots) {
            this.useCustomerOpeningTimeSlots = false
          }
          if (device.useUpdatingTimeSlots) {
            this.useUpdatingTimeSlots = true
            if (device.updatingTimeSlots) {
              this.useCustomerUpdatingTimeSlots = false
            }
          }
        }),
        mergeMap(_ => zip(
          this.tagService.getAvailableTags([TargetType.DEVICE]),
          this.tagService.getAvailableTags([TargetType.MEDIA]),
        ))
      ).subscribe(([deviceTags, mediaTags]) => {
        this.deviceTags = deviceTags
        this.filteredDeviceTags = deviceTags
        this.mediaTags = mediaTags
        this.filteredMediaTags = mediaTags
        this.deviceForm.patchValue({
          addedTags: deviceTags.filter(tag => this.device.tagIds?.includes(tag.id as string)),
          excludedTags: mediaTags.filter(tag => this.device.excludedTagIds?.includes(tag.id as string))
        })
      })
    )
  }

  onSubmit() {
    this.device.name = this.deviceForm.value.name
    this.device.type = this.deviceForm.value.type
    this.device.orientation = this.deviceForm.value.orientation
    this.device.muted = this.deviceForm.value.muted
    this.device.comment = this.deviceForm.value.comment
    this.device.legacyScreenlabUser = this.deviceForm.value.legacyScreenlabUser
    this.device.pairingCode = this.deviceForm.value.pairingCode
    this.device.tagIds = this.deviceForm.value.addedTags.map((tagAdded: any) => tagAdded.id)
    this.device.excludedTagIds = this.deviceForm.value.excludedTags.map((tagExcluded: any) => tagExcluded.id)
    this.device.autoPowerOnOff = this.deviceForm.value.autoPowerOnOff
    this.device.useRealTime = this.deviceForm.value.useRealTime
    this.device.language = this.deviceForm.value.language
    this.device.zoneId = this.deviceForm.value.zoneId

    if (this.device.type == DeviceType.KIOSK) {
      const kioskForm = this.deviceForm.get('kiosk')?.value
      this.device.kioskConfig = {
        application: kioskForm.application,
        url: kioskForm.url,
        whitelist: kioskForm.whitelist,
        whitelistUrl: kioskForm.whitelistUrl,
        homeTime: kioskForm.homeTime,
        idleTime: kioskForm.idleTime,
        backgroundColor: kioskForm.backgroundColor,
        foregroundColor: kioskForm.foregroundColor,
        screenlabEnabled: kioskForm.screenlabEnabled,
        showNavigation: kioskForm.showNavigation,
        navigationBarPosition: kioskForm.navigationBarPosition,
        cacheFlushEnabled: kioskForm.cacheFlushEnabled,
        virtualKeyboardEnabled: kioskForm.virtualKeyboardEnabled,
        rotationEnabled: kioskForm.rotationEnabled
      }
    } else {
      this.device.kioskConfig = undefined
    }

    if (this.device.type == DeviceType.KIOSK) {
      const peripheralForm = this.deviceForm.get('peripheralConfig')?.value
      this.device.peripheralConfig = {
        paymentTerminalType: peripheralForm.paymentTerminalType,
        stripeSdkConfig: peripheralForm.stripeSdkConfig,
        stripeReaderId: peripheralForm.stripeReaderId,
        verifoneReaderId: peripheralForm.verifoneReaderId
      }
    } else {
      this.device.peripheralConfig = undefined
    }

    this.device.remoteControlConfigs = this.buildRemoteControlConfigs()
    this.device.svdConfig = this.buildSvdConfig()

    if (this.useCustomerOpeningTimeSlots) {
      this.device.openingTimeSlots = undefined
    } else {
      this.device.openingTimeSlots = this.openingHoursCalendar.getTimeSlots()
    }

    if (this.useUpdatingTimeSlots) {
      this.device.useUpdatingTimeSlots = true
      if (this.useCustomerUpdatingTimeSlots) {
        this.device.updatingTimeSlots = undefined
      } else {
        this.device.updatingTimeSlots = this.updatingHoursCalendar.getTimeSlots()
      }
    } else {
      this.device.useUpdatingTimeSlots = false
      this.device.updatingTimeSlots = undefined
    }

    this.subscriptions.add(
      this.deviceService.updateDevice(this.device).subscribe(_ => {
        this.router.navigate(['/devices'])
      })
    )
  }

  onSearchDeviceTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredDeviceTags = value ? this.deviceTags.filter(deviceTag => deviceTag.name.toLowerCase().includes(value.toLowerCase())) : this.deviceTags.slice()
    }
  }

  onOpenDeviceTag($event: boolean) {
    let open = $event
    if (open) {
      this.searchDeviceTag.nativeElement.focus()
    } else {
      this.searchDeviceTag.nativeElement.value = ''
      this.onSearchDeviceTag(undefined)
    }
  }

  onSearchMediaTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredMediaTags = value ? this.mediaTags.filter(mediaTag => mediaTag.name.toLowerCase().includes(value.toLowerCase())) : this.mediaTags.slice()
    }
  }

  onOpenMediaTag($event: boolean) {
    let open = $event
    if (open) {
      this.searchMediaTag.nativeElement.focus()
    } else {
      this.searchMediaTag.nativeElement.value = ''
      this.onSearchMediaTag(undefined)
    }
  }

  doAddKioskFormGroup(formGroup: UntypedFormGroup) {
    this.deviceForm.addControl('kiosk', formGroup)
    const kioskForm = this.deviceForm.get('kiosk')?.value
    if (this.device.kioskConfig) {
      this.device.kioskConfig.application = kioskForm.application
    }
  }

  doAddPeripheralFormGroup(formGroup: UntypedFormGroup) {
    this.deviceForm.addControl('peripheralConfig', formGroup)
  }

  doAddSvdFormGroup(formGroup: UntypedFormGroup) {
    this.deviceForm.addControl('svd', formGroup)
  }

  onDeviceTypeChanged(event: MatSelectChange) {
    if (this.deviceForm.controls['kiosk']) {
      this.deviceForm.removeControl('kiosk')
    }
  }

  createConfig(config: DeviceRemoteControlConfig): void {
    switch (config.type) {
      case "teamviewer": {
        const configForm = this.formBuilder.group({
          type: [null],
          description: [null],
          id: [null, Validators.required],
          password: [null, Validators.required],
        })
        configForm.patchValue(config)
        this.remoteControlConfigs.push(configForm)
        break
      }
      case "vpn": {
        const configForm = this.formBuilder.group({
          type: [null],
          description: [null],
          hostname: [null, Validators.required],
          login: [null, Validators.required],
          password: [null, Validators.required],
        })
        configForm.patchValue(config)
        this.remoteControlConfigs.push(configForm)
        break
      }
      case "windows": {
        const configForm = this.formBuilder.group({
          type: [null],
          description: [null],
          login: [null, Validators.required],
          password: [null, Validators.required],
        })
        configForm.patchValue(config)
        this.remoteControlConfigs.push(configForm)
        break
      }
      default: {
        // not supported
        break
      }
    }
  }

  addConfig(type: String): void {
    switch (type) {
      case "teamviewer": {
        this.createConfig(new DeviceTeamViewerConfig())
        break
      }
      case "vpn": {
        this.createConfig(new DeviceVpnConfig())
        break
      }
      case "windows": {
        this.createConfig(new DeviceWindowsConfig())
        break
      }
      default: {
        // not supported
        break
      }
    }
    this.remoteControlConfigTypeSelect.value = []
  }

  removeConfig(configIndex: number): void {
    this.remoteControlConfigs.removeAt(configIndex)
  }

  buildRemoteControlConfigs(): DeviceRemoteControlConfig[] {
    let configs: DeviceRemoteControlConfig[] = []
    this.remoteControlConfigs.controls.forEach(control => {
      switch (control.value.type) {
        case "teamviewer": {
          let config = new DeviceTeamViewerConfig()
          config.description = control.value.description
          config.id = control.value.id
          config.password = control.value.password
          configs.push(config)
          break
        }
        case "vpn": {
          let config = new DeviceVpnConfig()
          config.description = control.value.description
          config.hostname = control.value.hostname
          config.login = control.value.login
          config.password = control.value.password
          configs.push(config)
          break
        }
        case "windows": {
          let config = new DeviceWindowsConfig()
          config.description = control.value.description
          config.login = control.value.login
          config.password = control.value.password
          configs.push(config)
          break
        }
        default: {
          // not supported
          break
        }
      }
    })
    return configs
  }

  buildSvdConfig(): DeviceSvdConfig | undefined {
    let svdConfig = undefined
    if (this.deviceForm.get('svd')) {
      const svdForm = this.deviceForm.get('svd')?.value
      let svdPassword = ""
      if (svdForm.passwordInput.length > 0) {
        svdPassword = sha1(svdForm.passwordInput)
      } else {
        svdPassword = svdForm.password
      }
      svdConfig = {
        enabled: svdForm.enabled,
        url: svdForm.url,
        playerId: svdForm.playerId,
        login: svdForm.login,
        password: svdPassword,
        updatePeriodInSeconds: svdForm.updatePeriodInSeconds,
        backgroundColor: svdForm.backgroundColor
      } as DeviceSvdConfig
    }
    return svdConfig
  }

  openDeviceMigrateDialog(): void {
    const deviceMigrateDialogRef = this.matDialog.open(DeviceMigrateDialogComponent, {
      data: this.device
    })
    this.subscriptions.add(
      deviceMigrateDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(["/devices"])
        }
      })
    )
  }

  resetLocale() {
    this.deviceForm.controls["language"].setValue(null)
  }

  getDeviceSupportedLanguage(locale: string): DeviceSupportedLanguage {
    return this.DeviceSupportedLanguages[locale as keyof typeof DeviceSupportedLanguage]
  }
}
