<ng-container *cmnIfPerm="['scr:device-action:read']">
  <ng-container *ngIf="action && action.status">
    <div class="device-tunnel-status" [ngSwitch]="action.status">
      <span *ngSwitchCase="TunnelDeviceActionStatus.CLOSE" class="status-danger">
        {{ "DEVICE.DEVICE_ACTION.TUNNEL_STATUS.CLOSE" | translate }}
      </span>
      <span *ngSwitchCase="TunnelDeviceActionStatus.CLOSED" class="status-danger">
        {{ "DEVICE.DEVICE_ACTION.TUNNEL_STATUS.CLOSED" | translate }}
      </span>
      <span *ngSwitchCase="TunnelDeviceActionStatus.OPEN" class="status-warning">
        {{ "DEVICE.DEVICE_ACTION.TUNNEL_STATUS.OPEN" | translate }}
      </span>
      <span *ngSwitchCase="TunnelDeviceActionStatus.OPENED" class="status-success">
        {{ "DEVICE.DEVICE_ACTION.TUNNEL_STATUS.OPENED" | translate : { port: 'port' } }}
      </span>
    </div>
  </ng-container>
</ng-container>
