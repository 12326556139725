export var SearchableTimeZoneData = [
  { id: "Pacific/Tahiti", name: "Pacific/Tahiti (GMT-10:00)" },
  { id: "America/Adak", name: "America/Adak (GMT-10:00)" },
  { id: "Pacific/Honolulu", name: "Pacific/Honolulu (GMT-10:00)" },
  { id: "Pacific/Marquesas", name: "Pacific/Marquesas (GMT-09:30)" },
  { id: "Pacific/Gambier", name: "Pacific/Gambier (GMT-09:00)" },
  { id: "America/Anchorage", name: "America/Anchorage (GMT-09:00)" },
  { id: "America/Tijuana", name: "America/Tijuana (GMT-08:00)" },
  { id: "America/Vancouver", name: "America/Vancouver (GMT-08:00)" },
  { id: "America/Dawson", name: "America/Dawson (GMT-07:00)" },
  { id: "America/Los_Angeles", name: "America/Los_Angeles (GMT-08:00)" },
  { id: "America/Mazatlan", name: "America/Mazatlan (GMT-07:00)" },
  { id: "America/Chihuahua", name: "America/Chihuahua (GMT-06:00)" },
  { id: "America/Hermosillo", name: "America/Hermosillo (GMT-07:00)" },
  { id: "America/Edmonton", name: "America/Edmonton (GMT-07:00)" },
  { id: "America/Yellowknife", name: "America/Yellowknife (GMT-07:00)" },
  { id: "America/Dawson_Creek", name: "America/Dawson_Creek (GMT-07:00)" },
  { id: "America/Denver", name: "America/Denver (GMT-07:00)" },
  { id: "America/Phoenix", name: "America/Phoenix (GMT-07:00)" },
  { id: "America/Belize", name: "America/Belize (GMT-06:00)" },
  { id: "America/Guatemala", name: "America/Guatemala (GMT-06:00)" },
  { id: "America/El_Salvador", name: "America/El_Salvador (GMT-06:00)" },
  { id: "America/Tegucigalpa", name: "America/Tegucigalpa (GMT-06:00)" },
  { id: "America/Mexico_City", name: "America/Mexico_City (GMT-06:00)" },
  { id: "America/Cancun", name: "America/Cancun (GMT-05:00)" },
  { id: "America/Merida", name: "America/Merida (GMT-06:00)" },
  { id: "America/Monterrey", name: "America/Monterrey (GMT-06:00)" },
  { id: "America/Managua", name: "America/Managua (GMT-06:00)" },
  { id: "America/Winnipeg", name: "America/Winnipeg (GMT-06:00)" },
  { id: "America/Rainy_River", name: "America/Rainy_River (GMT-06:00)" },
  { id: "America/Regina", name: "America/Regina (GMT-06:00)" },
  { id: "America/Swift_Current", name: "America/Swift_Current (GMT-06:00)" },
  { id: "America/Costa_Rica", name: "America/Costa_Rica (GMT-06:00)" },
  { id: "America/Chicago", name: "America/Chicago (GMT-06:00)" },
  { id: "America/Jamaica", name: "America/Jamaica (GMT-05:00)" },
  { id: "America/Nassau", name: "America/Nassau (GMT-05:00)" },
  { id: "America/Port-au-Prince", name: "America/Port-au-Prince (GMT-05:00)" },
  { id: "America/Panama", name: "America/Panama (GMT-05:00)" },
  { id: "America/Lima", name: "America/Lima (GMT-05:00)" },
  { id: "America/Guayaquil", name: "America/Guayaquil (GMT-05:00)" },
  { id: "America/Bogota", name: "America/Bogota (GMT-05:00)" },
  { id: "Pacific/Easter", name: "Pacific/Easter (GMT-05:00)" },
  { id: "America/Toronto", name: "America/Toronto (GMT-05:00)" },
  { id: "America/Nipigon", name: "America/Nipigon (GMT-05:00)" },
  { id: "America/Atikokan", name: "America/Atikokan (GMT-05:00)" },
  { id: "America/Montreal", name: "America/Montreal (GMT-05:00)" },
  { id: "America/Havana", name: "America/Havana (GMT-05:00)" },
  { id: "America/Cayman", name: "America/Cayman (GMT-05:00)" },
  { id: "America/New_York", name: "America/New_York (GMT-05:00)" },
  { id: "America/Detroit", name: "America/Detroit (GMT-05:00)" },
  { id: "America/Kentucky", name: "America/Kentucky (GMT+00:00)" },
  { id: "America/Indiana", name: "America/Indiana (GMT+00:00)" },
  { id: "America/Caracas", name: "America/Caracas (GMT-04:00)" },
  { id: "America/Barbados", name: "America/Barbados (GMT-04:00)" },
  { id: "Atlantic/Bermuda", name: "Atlantic/Bermuda (GMT-04:00)" },
  { id: "America/La_Paz", name: "America/La_Paz (GMT-04:00)" },
  { id: "America/Campo_Grande", name: "America/Campo_Grande (GMT-04:00)" },
  { id: "America/Cuiaba", name: "America/Cuiaba (GMT-04:00)" },
  { id: "America/Porto_Velho", name: "America/Porto_Velho (GMT-04:00)" },
  { id: "America/Boa_Vista", name: "America/Boa_Vista (GMT-04:00)" },
  { id: "America/Manaus", name: "America/Manaus (GMT-04:00)" },
  { id: "America/Guadeloupe", name: "America/Guadeloupe (GMT-04:00)" },
  { id: "America/Guyana", name: "America/Guyana (GMT-04:00)" },
  { id: "America/Thule", name: "America/Thule (GMT-04:00)" },
  { id: "America/Puerto_Rico", name: "America/Puerto_Rico (GMT-04:00)" },
  { id: "America/Asuncion", name: "America/Asuncion (GMT-03:00)" },
  { id: "America/Martinique", name: "America/Martinique (GMT-04:00)" },
  { id: "America/Halifax", name: "America/Halifax (GMT-04:00)" },
  { id: "America/Glace_Bay", name: "America/Glace_Bay (GMT-04:00)" },
  { id: "America/Moncton", name: "America/Moncton (GMT-04:00)" },
  { id: "America/Blanc-Sablon", name: "America/Blanc-Sablon (GMT-04:00)" },
  { id: "America/Santo_Domingo", name: "America/Santo_Domingo (GMT-04:00)" },
  { id: "America/Dominica", name: "America/Dominica (GMT-04:00)" },
  { id: "America/St_Lucia", name: "America/St_Lucia (GMT-04:00)" },
  { id: "America/Port_of_Spain", name: "America/Port_of_Spain (GMT-04:00)" },
  { id: "America/Aruba", name: "America/Aruba (GMT-04:00)" },
  { id: "America/St_Johns", name: "America/St_Johns (GMT-03:30)" },
  { id: "America/Belem", name: "America/Belem (GMT-03:00)" },
  { id: "America/Fortaleza", name: "America/Fortaleza (GMT-03:00)" },
  { id: "America/Recife", name: "America/Recife (GMT-03:00)" },
  { id: "America/Araguaina", name: "America/Araguaina (GMT-03:00)" },
  { id: "America/Maceio", name: "America/Maceio (GMT-03:00)" },
  { id: "America/Sao_Paulo", name: "America/Sao_Paulo (GMT-03:00)" },
  { id: "America/Godthab", name: "America/Godthab (GMT-02:00)" },
  { id: "America/Santiago", name: "America/Santiago (GMT-03:00)" },
  { id: "America/Paramaribo", name: "America/Paramaribo (GMT-03:00)" },
  { id: "America/Montevideo", name: "America/Montevideo (GMT-03:00)" },
  { id: "America/Argentina", name: "America/Argentina (GMT+00:00)" },
  { id: "America/Argentina", name: "America/Argentina (GMT+00:00)" },
  { id: "America/Noronha", name: "America/Noronha (GMT-02:00)" },
  { id: "America/Scoresbysund", name: "America/Scoresbysund (GMT-01:00)" },
  { id: "Atlantic/Azores", name: "Atlantic/Azores (GMT-01:00)" },
  { id: "Atlantic/Cape_Verde", name: "Atlantic/Cape_Verde (GMT-01:00)" },
  { id: "Africa/Ouagadougou", name: "Africa/Ouagadougou (GMT+00:00)" },
  { id: "Africa/Bissau", name: "Africa/Bissau (GMT+00:00)" },
  { id: "Europe/Guernsey", name: "Europe/Guernsey (GMT+00:00)" },
  { id: "Europe/London", name: "Europe/London (GMT+00:00)" },
  { id: "Africa/Conakry", name: "Africa/Conakry (GMT+00:00)" },
  { id: "Africa/Banjul", name: "Africa/Banjul (GMT+00:00)" },
  { id: "America/Danmarkshavn", name: "America/Danmarkshavn (GMT+00:00)" },
  { id: "Africa/Accra", name: "Africa/Accra (GMT+00:00)" },
  { id: "Europe/Lisbon", name: "Europe/Lisbon (GMT+00:00)" },
  { id: "Atlantic/Madeira", name: "Atlantic/Madeira (GMT+00:00)" },
  { id: "Atlantic/Canary", name: "Atlantic/Canary (GMT+00:00)" },
  { id: "Africa/Casablanca", name: "Africa/Casablanca (GMT+01:00)" },
  { id: "Africa/Bamako", name: "Africa/Bamako (GMT+00:00)" },
  { id: "Africa/Abidjan", name: "Africa/Abidjan (GMT+00:00)" },
  { id: "Africa/Dakar", name: "Africa/Dakar (GMT+00:00)" },
  { id: "Africa/Freetown", name: "Africa/Freetown (GMT+00:00)" },
  { id: "Africa/Monrovia", name: "Africa/Monrovia (GMT+00:00)" },
  { id: "Africa/Lome", name: "Africa/Lome (GMT+00:00)" },
  { id: "Atlantic/Reykjavik", name: "Atlantic/Reykjavik (GMT+00:00)" },
  { id: "Europe/Dublin", name: "Europe/Dublin (GMT+00:00)" },
  { id: "Europe/Brussels", name: "Europe/Brussels (GMT+01:00)" },
  { id: "Europe/Sarajevo", name: "Europe/Sarajevo (GMT+01:00)" },
  { id: "Africa/Porto-Novo", name: "Africa/Porto-Novo (GMT+01:00)" },
  { id: "Europe/Belgrade", name: "Europe/Belgrade (GMT+01:00)" },
  { id: "Africa/Malabo", name: "Africa/Malabo (GMT+01:00)" },
  { id: "Africa/Libreville", name: "Africa/Libreville (GMT+01:00)" },
  { id: "Europe/Gibraltar", name: "Europe/Gibraltar (GMT+01:00)" },
  { id: "Africa/Tunis", name: "Africa/Tunis (GMT+01:00)" },
  { id: "Europe/Zagreb", name: "Europe/Zagreb (GMT+01:00)" },
  { id: "Europe/Budapest", name: "Europe/Budapest (GMT+01:00)" },
  { id: "Europe/Warsaw", name: "Europe/Warsaw (GMT+01:00)" },
  { id: "Europe/Rome", name: "Europe/Rome (GMT+01:00)" },
  { id: "Europe/Madrid", name: "Europe/Madrid (GMT+01:00)" },
  { id: "Europe/Podgorica", name: "Europe/Podgorica (GMT+01:00)" },
  { id: "Europe/Monaco", name: "Europe/Monaco (GMT+01:00)" },
  { id: "Europe/Of", name: "Europe/Of (GMT+00:00)" },
  { id: "Europe/Malta", name: "Europe/Malta (GMT+01:00)" },
  { id: "Europe/Paris", name: "Europe/Paris (GMT+01:00)" },
  { id: "Europe/Amsterdam", name: "Europe/Amsterdam (GMT+01:00)" },
  { id: "Europe/Oslo", name: "Europe/Oslo (GMT+01:00)" },
  { id: "Africa/Windhoek", name: "Africa/Windhoek (GMT+02:00)" },
  { id: "Africa/Niamey", name: "Africa/Niamey (GMT+01:00)" },
  { id: "Africa/Lagos", name: "Africa/Lagos (GMT+01:00)" },
  { id: "Europe/Zurich", name: "Europe/Zurich (GMT+01:00)" },
  { id: "Africa/Douala", name: "Africa/Douala (GMT+01:00)" },
  { id: "Africa/Brazzaville", name: "Africa/Brazzaville (GMT+01:00)" },
  { id: "Africa/Bangui", name: "Africa/Bangui (GMT+01:00)" },
  { id: "Africa/Congo", name: "Africa/Congo (GMT+00:00)" },
  { id: "Europe/Prague", name: "Europe/Prague (GMT+01:00)" },
  { id: "Europe/Bratislava", name: "Europe/Bratislava (GMT+01:00)" },
  { id: "Europe/Ljubljana", name: "Europe/Ljubljana (GMT+01:00)" },
  { id: "Europe/Stockholm", name: "Europe/Stockholm (GMT+01:00)" },
  { id: "Europe/Copenhagen", name: "Europe/Copenhagen (GMT+01:00)" },
  { id: "Europe/Berlin", name: "Europe/Berlin (GMT+01:00)" },
  { id: "Africa/Algiers", name: "Africa/Algiers (GMT+01:00)" },
  { id: "Europe/Vaduz", name: "Europe/Vaduz (GMT+01:00)" },
  { id: "Europe/Luxembourg", name: "Europe/Luxembourg (GMT+01:00)" },
  { id: "Africa/Ndjamena", name: "Africa/Ndjamena (GMT+01:00)" },
  { id: "Europe/Vatican", name: "Europe/Vatican (GMT+01:00)" },
  { id: "Europe/Tirane", name: "Europe/Tirane (GMT+01:00)" },
  { id: "Africa/Luanda", name: "Africa/Luanda (GMT+01:00)" },
  { id: "Europe/Vienna", name: "Europe/Vienna (GMT+01:00)" },
  { id: "Europe/Sofia", name: "Europe/Sofia (GMT+02:00)" },
  { id: "Africa/Bujumbura", name: "Africa/Bujumbura (GMT+02:00)" },
  { id: "Africa/Gaborone", name: "Africa/Gaborone (GMT+02:00)" },
  { id: "Europe/Kaliningrad", name: "Europe/Kaliningrad (GMT+02:00)" },
  { id: "Africa/Kigali", name: "Africa/Kigali (GMT+02:00)" },
  { id: "Europe/Bucharest", name: "Europe/Bucharest (GMT+02:00)" },
  { id: "Europe/Athens", name: "Europe/Athens (GMT+02:00)" },
  { id: "Asia/Amman", name: "Asia/Amman (GMT+03:00)" },
  { id: "Africa/Lusaka", name: "Africa/Lusaka (GMT+02:00)" },
  { id: "Europe/Tallinn", name: "Europe/Tallinn (GMT+02:00)" },
  { id: "Africa/Cairo", name: "Africa/Cairo (GMT+02:00)" },
  { id: "Africa/Johannesburg", name: "Africa/Johannesburg (GMT+02:00)" },
  { id: "Africa/Harare", name: "Africa/Harare (GMT+02:00)" },
  { id: "Europe/Chisinau", name: "Europe/Chisinau (GMT+02:00)" },
  { id: "Africa/Blantyre", name: "Africa/Blantyre (GMT+02:00)" },
  { id: "Asia/Jerusalem", name: "Asia/Jerusalem (GMT+02:00)" },
  { id: "Europe/Helsinki", name: "Europe/Helsinki (GMT+02:00)" },
  { id: "Africa/Congo", name: "Africa/Congo (GMT+00:00)" },
  { id: "Asia/Nicosia", name: "Asia/Nicosia (GMT+02:00)" },
  { id: "Africa/Mbabane", name: "Africa/Mbabane (GMT+02:00)" },
  { id: "Asia/Damascus", name: "Asia/Damascus (GMT+03:00)" },
  { id: "Asia/Beirut", name: "Asia/Beirut (GMT+02:00)" },
  { id: "Europe/Istanbul", name: "Europe/Istanbul (GMT+03:00)" },
  { id: "Europe/Riga", name: "Europe/Riga (GMT+02:00)" },
  { id: "Europe/Vilnius", name: "Europe/Vilnius (GMT+02:00)" },
  { id: "Africa/Maseru", name: "Africa/Maseru (GMT+02:00)" },
  { id: "Africa/Tripoli", name: "Africa/Tripoli (GMT+02:00)" },
  { id: "Europe/Kiev", name: "Europe/Kiev (GMT+02:00)" },
  { id: "Europe/Uzhgorod", name: "Europe/Uzhgorod (GMT+02:00)" },
  { id: "Europe/Zaporozhye", name: "Europe/Zaporozhye (GMT+02:00)" },
  { id: "Africa/Maputo", name: "Africa/Maputo (GMT+02:00)" },
  { id: "Asia/Bahrain", name: "Asia/Bahrain (GMT+03:00)" },
  { id: "Europe/Minsk", name: "Europe/Minsk (GMT+03:00)" },
  { id: "Europe/Moscow", name: "Europe/Moscow (GMT+03:00)" },
  { id: "Europe/Simferopol", name: "Europe/Simferopol (GMT+03:00)" },
  { id: "Europe/Volgograd", name: "Europe/Volgograd (GMT+03:00)" },
  { id: "Asia/Baghdad", name: "Asia/Baghdad (GMT+03:00)" },
  { id: "Africa/Addis_Ababa", name: "Africa/Addis_Ababa (GMT+03:00)" },
  { id: "Africa/Mogadishu", name: "Africa/Mogadishu (GMT+03:00)" },
  { id: "Asia/Riyadh", name: "Asia/Riyadh (GMT+03:00)" },
  { id: "Africa/Asmara", name: "Africa/Asmara (GMT+03:00)" },
  { id: "Indian/Antananarivo", name: "Indian/Antananarivo (GMT+03:00)" },
  { id: "Africa/Kampala", name: "Africa/Kampala (GMT+03:00)" },
  { id: "Africa/Dar_es_Salaam", name: "Africa/Dar_es_Salaam (GMT+03:00)" },
  { id: "Africa/Nairobi", name: "Africa/Nairobi (GMT+03:00)" },
  { id: "Asia/Kuwait", name: "Asia/Kuwait (GMT+03:00)" },
  { id: "Africa/Khartoum", name: "Africa/Khartoum (GMT+02:00)" },
  { id: "Asia/Aden", name: "Asia/Aden (GMT+03:00)" },
  { id: "Indian/Mayotte", name: "Indian/Mayotte (GMT+03:00)" },
  { id: "Asia/Qatar", name: "Asia/Qatar (GMT+03:00)" },
  { id: "Asia/Tehran", name: "Asia/Tehran (GMT+03:30)" },
  { id: "Europe/Samara", name: "Europe/Samara (GMT+04:00)" },
  { id: "Asia/Tbilisi", name: "Asia/Tbilisi (GMT+04:00)" },
  { id: "Asia/Muscat", name: "Asia/Muscat (GMT+04:00)" },
  { id: "Indian/Mauritius", name: "Indian/Mauritius (GMT+04:00)" },
  { id: "Asia/Dubai", name: "Asia/Dubai (GMT+04:00)" },
  { id: "Asia/Yerevan", name: "Asia/Yerevan (GMT+04:00)" },
  { id: "Asia/Baku", name: "Asia/Baku (GMT+04:00)" },
  { id: "Asia/Kabul", name: "Asia/Kabul (GMT+04:30)" },
  { id: "Asia/Yekaterinburg", name: "Asia/Yekaterinburg (GMT+05:00)" },
  { id: "Asia/Ashgabat", name: "Asia/Ashgabat (GMT+05:00)" },
  { id: "Asia/Dushanbe", name: "Asia/Dushanbe (GMT+05:00)" },
  { id: "Asia/Karachi", name: "Asia/Karachi (GMT+05:00)" },
  { id: "Asia/Samarkand", name: "Asia/Samarkand (GMT+05:00)" },
  { id: "Asia/Tashkent", name: "Asia/Tashkent (GMT+05:00)" },
  { id: "Indian/Maldives", name: "Indian/Maldives (GMT+05:00)" },
  { id: "Asia/Colombo", name: "Asia/Colombo (GMT+05:30)" },
  { id: "Asia/Kolkata", name: "Asia/Kolkata (GMT+05:30)" },
  { id: "Asia/Kathmandu", name: "Asia/Kathmandu (GMT+05:45)" },
  { id: "Asia/Dhaka", name: "Asia/Dhaka (GMT+06:00)" },
  { id: "Asia/Thimphu", name: "Asia/Thimphu (GMT+06:00)" },
  { id: "Asia/Omsk", name: "Asia/Omsk (GMT+06:00)" },
  { id: "Asia/Novosibirsk", name: "Asia/Novosibirsk (GMT+07:00)" },
  { id: "Asia/Bishkek", name: "Asia/Bishkek (GMT+06:00)" },
  { id: "Asia/Almaty", name: "Asia/Almaty (GMT+06:00)" },
  { id: "Asia/Rangoon", name: "Asia/Rangoon (GMT+06:30)" },
  { id: "Asia/Krasnoyarsk", name: "Asia/Krasnoyarsk (GMT+07:00)" },
  { id: "Asia/Ho_Chi_Minh", name: "Asia/Ho_Chi_Minh (GMT+07:00)" },
  { id: "Asia/Hovd", name: "Asia/Hovd (GMT+07:00)" },
  { id: "Indian/Christmas", name: "Indian/Christmas (GMT+07:00)" },
  { id: "Asia/Phnom_Penh", name: "Asia/Phnom_Penh (GMT+07:00)" },
  { id: "Asia/Vientiane", name: "Asia/Vientiane (GMT+07:00)" },
  { id: "Asia/Bangkok", name: "Asia/Bangkok (GMT+07:00)" },
  { id: "Asia/Jakarta", name: "Asia/Jakarta (GMT+07:00)" },
  { id: "Asia/Irkutsk", name: "Asia/Irkutsk (GMT+08:00)" },
  { id: "Asia/Hong_Kong", name: "Asia/Hong_Kong (GMT+08:00)" },
  { id: "Asia/Manila", name: "Asia/Manila (GMT+08:00)" },
  { id: "Asia/Macau", name: "Asia/Macau (GMT+08:00)" },
  { id: "Asia/Ulaanbaatar", name: "Asia/Ulaanbaatar (GMT+08:00)" },
  { id: "Asia/Kuala_Lumpur", name: "Asia/Kuala_Lumpur (GMT+08:00)" },
  { id: "Asia/Kuching", name: "Asia/Kuching (GMT+08:00)" },
  { id: "Asia/Shanghai", name: "Asia/Shanghai (GMT+08:00)" },
  { id: "Asia/Singapore", name: "Asia/Singapore (GMT+08:00)" },
  { id: "Asia/Taipei", name: "Asia/Taipei (GMT+08:00)" },
  { id: "Australia/Perth", name: "Australia/Perth (GMT+08:00)" },
  { id: "Asia/Yakutsk", name: "Asia/Yakutsk (GMT+09:00)" },
  { id: "Asia/Dili", name: "Asia/Dili (GMT+09:00)" },
  { id: "Asia/Tokyo", name: "Asia/Tokyo (GMT+09:00)" },
  { id: "Pacific/Palau", name: "Pacific/Palau (GMT+09:00)" },
  { id: "Asia/Seoul", name: "Asia/Seoul (GMT+09:00)" },
  { id: "Asia/Pyongyang", name: "Asia/Pyongyang (GMT+09:00)" },
  { id: "Australia/Broken_Hill", name: "Australia/Broken_Hill (GMT+10:30)" },
  { id: "Australia/Adelaide", name: "Australia/Adelaide (GMT+10:30)" },
  { id: "Australia/Darwin", name: "Australia/Darwin (GMT+09:30)" },
  { id: "Asia/Vladivostok", name: "Asia/Vladivostok (GMT+10:00)" },
  { id: "Asia/Sakhalin", name: "Asia/Sakhalin (GMT+11:00)" },
  { id: "Asia/Magadan", name: "Asia/Magadan (GMT+11:00)" },
  { id: "Pacific/Guam", name: "Pacific/Guam (GMT+10:00)" },
  { id: "Pacific/Port_Moresby", name: "Pacific/Port_Moresby (GMT+10:00)" },
  { id: "Australia/Hobart", name: "Australia/Hobart (GMT+11:00)" },
  { id: "Australia/Melbourne", name: "Australia/Melbourne (GMT+11:00)" },
  { id: "Australia/Sydney", name: "Australia/Sydney (GMT+11:00)" },
  { id: "Australia/Brisbane", name: "Australia/Brisbane (GMT+10:00)" },
  { id: "Australia/Lord_Howe", name: "Australia/Lord_Howe (GMT+11:00)" },
  { id: "Asia/Srednekolymsk", name: "Asia/Srednekolymsk (GMT+11:00)" },
  { id: "Pacific/Guadalcanal", name: "Pacific/Guadalcanal (GMT+11:00)" },
  { id: "Pacific/Noumea", name: "Pacific/Noumea (GMT+11:00)" },
  { id: "Pacific/Norfolk", name: "Pacific/Norfolk (GMT+12:00)" },
  { id: "Asia/Kamchatka", name: "Asia/Kamchatka (GMT+12:00)" },
  { id: "Asia/Anadyr", name: "Asia/Anadyr (GMT+12:00)" },
  { id: "Pacific/Kwajalein", name: "Pacific/Kwajalein (GMT+12:00)" },
  { id: "Pacific/Fiji", name: "Pacific/Fiji (GMT+12:00)" },
  { id: "Pacific/Auckland", name: "Pacific/Auckland (GMT+13:00)" },
  { id: "Pacific/Apia", name: "Pacific/Apia (GMT+13:00)" },
  { id: "Pacific/Tongatapu", name: "Pacific/Tongatapu (GMT+13:00)" }
]