import { Component, ViewChild } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { DeviceEventsSummary } from 'projects/hcl-portal/src/app/common/interfaces/device';
import { Granularity } from "projects/hcl-portal/src/app/common/interfaces/stats";

const moment = _rollupMoment || _moment

@Component({
    selector: 'app-device-sessions-chart',
    templateUrl: './device-sessions-chart.component.html',
    styleUrls: ['./device-sessions-chart.component.scss'],
    standalone: true,
    imports: [BaseChartDirective]
})
export class DeviceSessionsChartComponent {
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective

  serieLabel!: String

  chartData: ChartDataset<"bar">[] = []
  chartLabels: string[] = []
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        axis: "y",
        ticks: {
          maxTicksLimit: 10,
          stepSize: 1,
        },
        grid: {
          display: false,
        }
      }
    }
  }

  constructor(
    private translateService: TranslateService,
  ) { }

  refresh(events: DeviceEventsSummary, granularity: Granularity): void {
    this.chart.data = undefined
    this.chartData = []

    this.chartData.push({
      label: this.translateService.instant("DEVICE.CHARTS.SESSIONS.TITLE"),
      backgroundColor: "darkred",
      data: events.usages.map(usage => usage.sessions)
    })
    this.chartLabels = events.usages.map(usage => this.format(usage, granularity))
  }

  private format(usage: {from: string, to: string}, granularity: Granularity): string {
    switch (granularity) {
      case Granularity.HOUR: return moment(usage.from).format("ll LT")
      case Granularity.DAY: return moment(usage.from).format("ll")
      default: return `${moment(usage.from).format("ll")} - ${moment(usage.to).format("ll")}`
    }
  }
}
