import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { DeviceService } from "../../../services/device/device.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Device, DeviceType } from "../../../interfaces/device";
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-device-pairing-dialog',
    templateUrl: './device-pairing-dialog.component.html',
    styleUrls: ['./device-pairing-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, TranslateModule]
})
export class DevicePairingDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  device!: Device
  hasRemoteControlConfig: boolean = false

  constructor(
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<DevicePairingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.device = this.data.device
    this.hasRemoteControlConfig = this.device.remoteControlConfigs.length > 0
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  doValid() {
    this.subscriptions.add(
      this.deviceService.updateDevice(this.device).subscribe(_ => {
        this.dialogRef.close(true)
      })
    )
  }

  doBack() {
    this.dialogRef.close()
  }

  deviceTypeNeedRemoteConfig(): boolean {
    return this.device.type == DeviceType.SCR_LG || this.device.type == DeviceType.SCR_TIZEN ? false : true
  }
}
