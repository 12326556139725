import { Component, OnInit, OnDestroy, Input } from "@angular/core"
import { Subscription } from "rxjs"
import { Device } from "../../../../interfaces/device"
import { DeviceActionService } from "../../../../services/device-action/device-action.service"
import { DeviceAction, TunnelDeviceActionStatus } from "../../../../interfaces/device-action"
import { DeviceActionType } from "../../../../interfaces/device-action"
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { DeviceActionDialogComponent } from "../device-action-dialog/device-action-dialog.component"
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from "@angular/common"
import { MatCardModule } from "@angular/material/card"
import { TranslateModule } from "@ngx-translate/core"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatTableModule } from "@angular/material/table"
import { CmnIfPermDirective } from "hcl-lib"

enum ScheduleState {
  LOADING,
  NONE,
  SCHEDULE,
  PARENT_SCHEDULE,
  SVD_SCHEDULE
}

@Component({
    selector: 'app-device-details-actions',
    templateUrl: './device-details-actions.component.html',
    styleUrls: ['./device-details-actions.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe, MatTableModule, MatCardModule, MatButtonModule, MatIconModule, TranslateModule, CmnIfPermDirective]
})
export class DeviceDetailsActionsComponent implements OnInit, OnDestroy {

  @Input() device!: Device

  subscriptions: Subscription = new Subscription()

  actions!: DeviceAction[]
  DeviceActionType = DeviceActionType
  TunnelDeviceActionStatus = TunnelDeviceActionStatus
  columnsToDisplay = ['type', 'actions']

  constructor(
    private deviceActionService: DeviceActionService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.retrieveDeviceActions()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  handlePage(): void {
    this.retrieveDeviceActions()
  }

  retrieveDeviceActions(): void {
    this.subscriptions.add(
      this.deviceActionService.getDeviceActions(this.device.id).subscribe(actions => {
        this.actions = actions.data
      })
    )
  }

  doCreate(): void {
    const config = new MatDialogConfig()
    config.data = {
      deviceId: this.device.id
    }
    this.subscriptions.add(
      this.matDialog.open(DeviceActionDialogComponent, config).afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }

  doCancel(action: DeviceAction): void {
    this.subscriptions.add(
      this.deviceActionService.cancelDeviceAction(action).subscribe(_ => {
        this.handlePage()
      })
    )
  }
}
