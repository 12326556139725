import { APP_INITIALIZER, LOCALE_ID, ErrorHandler, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideStore } from '@ngrx/store';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as Sentry from '@sentry/angular-ivy';
import { DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { KeycloakService, ScopeService, ScopeActions, ConfigActions, SnackBarService, rootReducer, HclLibModule, HumecloudCommonDecoratorModule } from 'hcl-lib';
import { TranslationModule } from '../app/translation/translation.module';
import { HumecloudCommonModule } from '../app/common/humecloud-common.module';
import { ThemeOptions } from '../app/theme-options';
import { environment } from '../environments/environment';
import { routes } from './routes';
import { AppGuard } from './common/guards/app-guard.service';
import { AppSimpleAppGuard } from './common/guards/app-simple-app-guard.service';
import { ContactAdministratorGuard } from './common/guards/contact-administrator-guard.service';
import { SelfRegistrationGuard } from './common/guards/self-registration-guard.service';
import { HumecanvaModule } from './humecanva/humecanva.module';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: environment.apiBaseUrl + '/medias',
  maxFilesize: 50,
  acceptedFiles: 'image/*,application/pdf'
};

const customPaginatorDefaults: MatPaginatorDefaultOptions = {};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })),
    provideStore(rootReducer),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    provideCharts(withDefaultRegisterables()),
    SelfRegistrationGuard,
    AppGuard,
    AppSimpleAppGuard,
    ContactAdministratorGuard,
    importProvidersFrom(
      HumecloudCommonModule,
      HumecloudCommonDecoratorModule,
      FontAwesomeModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      HclLibModule.forRoot({
        cmnAppApiBaseUrl: environment.cmnAppApiBaseUrl,
        cmnUserApiBaseUrl: environment.cmnUserApiBaseUrl,
        mediaApiBaseUrl: environment.mediaApiBaseUrl,
        stripePublishableKey: environment.stripePublishableKey,
        institutionApiBaseUrl: environment.hheInstitutionApiBaseUrl
      }),
      HumecanvaModule,
      TranslationModule
    ),
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    KeycloakService,
    ScopeService,
    ScopeActions,
    ConfigActions,
    ThemeOptions,
    SnackBarService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { formFieldAppearance: 'fill' }
    },
  ]
};
