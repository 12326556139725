import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CmnIfPermDirective, EnumToArrayPipe, Tag, TagService, TargetType } from "hcl-lib";
import { Device, DeviceSupportedLanguage, DeviceOrientation, DeviceType } from 'projects/hcl-portal/src/app/common/interfaces/device';
import { DeviceService } from 'projects/hcl-portal/src/app/common/services/device/device.service';
import { Subscription, zip } from 'rxjs';
import { NgFor, NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { MatDividerModule } from "@angular/material/divider";
import { SingleSearchableSelectComponent } from "../../../Layout/Components/widget/single-searchable-select/single-searchable-select.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { SearchableTimeZoneData } from "../../../util/searchable-timezone-data";

@Component({
  selector: 'app-device-create-dialog',
  templateUrl: './device-create-dialog.component.html',
  styleUrls: ['./device-create-dialog.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatDialogModule, MatDividerModule, MatButtonModule, MatIconModule, TranslateModule, CmnIfPermDirective, EnumToArrayPipe, SingleSearchableSelectComponent]
})
export class DeviceCreateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  deviceForm!: UntypedFormGroup
  deviceTypes = DeviceType
  deviceTypesKeys: string[] = Object.keys(DeviceType).filter(value => isNaN(Number(value)))
  deviceOrientationKeys: string[] = Object.keys(DeviceOrientation).filter(value => isNaN(Number(value)))

  deviceTags?: Tag[] = undefined
  filteredDeviceTags?: Tag[]
  mediaTags?: Tag[] = undefined
  filteredMediaTags?: Tag[]

  zoneIdSearchableData = SearchableTimeZoneData

  @ViewChild("searchDeviceTag") searchDeviceTag!: ElementRef
  @ViewChild("searchMediaTag") searchMediaTag!: ElementRef
  DeviceSupportedLanguages = DeviceSupportedLanguage

  constructor(
    public dialogRef: MatDialogRef<DeviceCreateDialogComponent>,
    private deviceService: DeviceService,
    private formBuilder: UntypedFormBuilder,
    private tagService: TagService
  ) { }

  ngOnInit(): void {
    this.deviceForm = this.formBuilder.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      orientation: [null, Validators.required],
      muted: [true],
      comment: [null],
      legacyScreenlabUser: [null],
      addedTags: [[]],
      excludedTags: [[]],
      autoPowerOnOff: [true],
      useUpdatingTimeSlots: [false],
      useRealTime: [false],
      language: [null],
      zoneId: [null]
    })

    this.subscriptions.add(
      zip(
        this.tagService.getAvailableTags([TargetType.DEVICE]),
        this.tagService.getAvailableTags([TargetType.MEDIA])
      ).subscribe(([deviceTags, mediaTags]) => {
        this.deviceTags = deviceTags
        this.filteredDeviceTags = deviceTags
        this.mediaTags = mediaTags
        this.filteredMediaTags = mediaTags
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onSubmit() {
    const device: Device = {
      id: "",
      customerId: "",
      name: this.deviceForm.value.name,
      type: this.deviceForm.value.type,
      orientation: this.deviceForm.value.orientation,
      muted: this.deviceForm.value.muted,
      comment: this.deviceForm.value.comment,
      legacyScreenlabUser: this.deviceForm.value.legacyScreenlabUser,
      tagIds: this.deviceForm.value.addedTags.map((tagAdded: any) => tagAdded.id),
      excludedTagIds: this.deviceForm.value.excludedTags.map((tagExcluded: any) => tagExcluded.id),
      autoPowerOnOff: this.deviceForm.value.autoPowerOnOff,
      remoteControlConfigs: [],
      useUpdatingTimeSlots: this.deviceForm.value.useUpdatingTimeSlots,
      useRealTime: this.deviceForm.value.useRealTime,
      language: this.deviceForm.value.language,
      zoneId: this.deviceForm.value.zoneId
    }
    this.subscriptions.add(
      this.deviceService.createDevice(device).subscribe(_ => {
        this.dialogRef.close()
      })
    )
  }

  onSearchDeviceTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredDeviceTags = value ? this.deviceTags?.filter(deviceTag => deviceTag.name.toLowerCase().includes(value.toLowerCase())) : this.deviceTags?.slice()
    }
  }

  onOpenDeviceTag($event: boolean) {
    let open = $event
    if (open) {
      this.searchDeviceTag.nativeElement.focus()
    } else {
      this.searchDeviceTag.nativeElement.value = ''
      this.onSearchDeviceTag(undefined)
    }
  }

  onSearchMediaTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredMediaTags = value ? this.mediaTags?.filter(mediaTag => mediaTag.name.toLowerCase().includes(value.toLowerCase())) : this.mediaTags?.slice()
    }
  }

  onOpenMediaTag($event: boolean) {
    let open = $event
    if (open) {
      this.searchMediaTag.nativeElement.focus()
    } else {
      this.searchMediaTag.nativeElement.value = ''
      this.onSearchMediaTag(undefined)
    }
  }

  resetLocale() {
    this.deviceForm.controls["language"].setValue(null)
  }

  getDeviceSupportedLanguage(locale: string): DeviceSupportedLanguage {
    return this.DeviceSupportedLanguages[locale as keyof typeof DeviceSupportedLanguage]
  }
}
