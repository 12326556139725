import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription, from, of, zip } from "rxjs"
import { mergeMap, toArray } from "rxjs/operators"
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { NotificationRecipientsService } from "../../../../../services/notifications/notification-recipients.service"
import { NotificationRecipient } from "../../../../../interfaces/notification-recipient"
import { EventService } from "../../../../../services/event/event.service"
import { EventDto } from "../../../../../interfaces/dto/event-dto"
import { NgFor } from "@angular/common";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterModule } from "@angular/router";

interface EventNotification extends EventDto, NotificationRecipient { }

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    standalone: true,
    imports: [RouterModule, NgFor, NgbDropdownModule, FontAwesomeModule, TranslateModule]
})
export class NotificationsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()
  unreadNotifications: EventNotification[] = []
  faBell = faBell

  constructor(
    private notificationRecipientsService: NotificationRecipientsService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.retrieveUnreadNotifications()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveUnreadNotifications() {
    this.subscriptions.add(
      this.notificationRecipientsService.getUnreadNotificationsWithPaging(0, 5).subscribe(notificationRecipients => {
        from(notificationRecipients.data).pipe(
          mergeMap((notificationRecipient: any) => zip(
            of(notificationRecipient as NotificationRecipient),
            this.eventService.getEvent((notificationRecipient as NotificationRecipient).eventId))
          ),
          toArray()
        ).subscribe(unreadNotifications => {
          this.unreadNotifications = unreadNotifications.map(it => ({ ...it[0], ...it[1] }))
        })
      })
    )
  }
}
