import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Customer, CustomerService } from 'hcl-lib';
import { Subscription } from 'rxjs';
import { Device } from '../../../interfaces/device';
import { DeviceService } from '../../../services/device/device.service';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerRecentSearchSelectComponent } from '../../../Layout/Components/widget/customer-recent-search-select/customer-recent-search-select.component';

@Component({
    selector: 'app-device-migrate-dialog',
    templateUrl: './device-migrate-dialog.component.html',
    styleUrls: ['./device-migrate-dialog.component.scss'],
    standalone: true,
    imports: [NgIf,ReactiveFormsModule, MatDialogModule, MatButtonModule, TranslateModule, CustomerRecentSearchSelectComponent]
})
export class DeviceMigrateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  deviceMigrateForm!: UntypedFormGroup

  currentCustomer!: Customer

  constructor(
    private deviceService: DeviceService,
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DeviceMigrateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public device: Device
  ) { }

  ngOnInit(): void {
    this.deviceMigrateForm = this.formBuilder.group({
      customerId: [null, Validators.required]
    })
    this.retrieveCustomer(this.device.customerId)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveCustomer(customerId: string): void {
    this.subscriptions.add(
      this.customerService.getCustomer(customerId).subscribe(customer => {
        this.currentCustomer = customer
      })
    )
  }

  confirm(): void {
    if (this.deviceMigrateForm.valid) {
      this.subscriptions.add(
        this.deviceService.migrateDevice(
          this.device.id,
          this.deviceMigrateForm.value.customerId
        ).subscribe(migratedDevice => {
          this.dialogRef.close(migratedDevice)
        })
      )
    }
  }

  onSelectedCustomer(customer: Customer): void {
    this.deviceMigrateForm.patchValue({ customerId: customer.id })
  }
}
