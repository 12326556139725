<div [formGroup]="stripeReaderForm" class="stripe-reader-dialog">
  <div mat-dialog-title class="stripe-reader-dialog_title">
    {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.TITLE' | translate }}
  </div>
  <div class="stripe-reader-dialog_content">
    <div class="stripe-reader-dialog_content_readers">
      <div class="intel">
        <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.ACTUAL_DEVICE' | translate }} :</div>
        <div>{{ currentStripeReader ? currentStripeReader.label : "---" }}</div>
      </div>
      <div class="intel">
        <div>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.NEW_DEVICE' | translate }} :</div>
        <div>{{ selectedStripeReader ? selectedStripeReader.label : "---" }}</div>
      </div>
    </div>
    <div class="stripe-reader-dialog_content_checkbox">
      <mat-checkbox formControlName="showOnlyNotPairedReader">
        {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.SHOW_ONLY_UNPAIRED' | translate }}
      </mat-checkbox>
    </div>
    <div class="stripe-reader-dialog_content_selector">
      <button type="button" mat-button color="primary" (click)="getStripeReaders(true)">
        <mat-icon>refresh</mat-icon>
      </button>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.SELECT_FIELD' | translate }}</mat-label>
        <mat-select (openedChange)="onOpenStripeReader($event)" (valueChange)="onChangeStripeReader($event)">
          <input #searchStripeReader matInput type="text" (input)="onSearchStripeReader($event)"
                 autocomplete="off">
          <mat-divider></mat-divider>
          <mat-option *ngFor="let reader of stripeReaders" [value]="reader">{{ reader.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!stripeReaderForm.value.showOnlyNotPairedReader && selectedStripeReader && selectedStripeReader.deviceId != ''"
         class="stripe-reader-dialog_content_alert">
      <span>
        {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.ALREADY_IN_USE' | translate : {selectedStripeReader: selectedStripeReader.deviceId} }}
      </span>
    </div>
  </div>
  <div class="stripe-reader-dialog_footer">
    <button class="button" *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" type="button" (click)="validate()"
            mat-raised-button color="primary" [disabled]="selectedStripeReader == null">
      <mat-icon>check</mat-icon>
      <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.STRIPE.MODAL.SAVE' | translate }}</span>
    </button>
  </div>
</div>
