import { NgFor } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CmnIfPermDirective, CustomerService, DeleteDialogComponent, KeycloakService, QueryScope, ScopeService } from 'hcl-lib';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { User } from 'projects/hcl-portal/src/app/common/interfaces/user';
import { UserService } from 'projects/hcl-portal/src/app/common/services/user/user.service';
import { asapScheduler, EMPTY, from, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, expand, map, mergeMap, tap, toArray } from 'rxjs/operators';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    standalone: true,
    imports: [RouterModule, NgFor, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatToolbarModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatPaginatorModule, TranslateModule, CmnIfPermDirective]
})
export class UserListComponent implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  userDataSource = new MatTableDataSource<{ user: User, customerNames: String, managedBy: String }>()
  columnsToDisplay = ['email', 'lastName', 'clients', 'managedBy', 'actions']

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  sortQueryParam!: string

  QueryScopes = [
    QueryScope.CHILDREN_FROM_HIGHEST_ANCESTOR,
    QueryScope.CUSTOMER_AND_CHILDREN
  ]

  selectedScope: QueryScope = QueryScope.CHILDREN_FROM_HIGHEST_ANCESTOR
  highestAncestorCid?: string = undefined

  searchKeyUp = new Subject<string>()

  userSearchForm: UntypedFormGroup = new UntypedFormGroup({
    userSearchField: new UntypedFormControl('')
  })

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private matDialog: MatDialog,
    private customerService: CustomerService,
    private scopeService: ScopeService,
    private keycloakService: KeycloakService
  ) { }

  ngAfterViewInit(): void {
    this.retrieveHighestAncestorCid()
    this.userDataSource.sort = this.sort

    this.subscriptions.add(this.searchKeyUp.pipe(
      debounceTime(800, asapScheduler),
      distinctUntilChanged(),
    ).subscribe(_ => {
      this.resetPagination()
      this.retrieveUsers()
    }))
  }

  retrieveHighestAncestorCid() {
    if (this.keycloakService.getCustomerIds().length == 1) {
      this.highestAncestorCid = this.keycloakService.getCustomerIds()[0]
      this.retrieveUsers()
    } else {
      this.subscriptions.add(this.scopeService.getScopeCustomer().pipe(
        expand(customer => {
          if (this.keycloakService.getCustomerIds().includes(customer?.id as string)) {
            this.highestAncestorCid = customer?.id
          } else if (this.keycloakService.getCustomerIds().includes(customer?.customerId as string)) {
            this.highestAncestorCid = customer?.customerId
          } else if (customer?.customerId == "" || customer?.customerId == undefined) {
            throw new Error("Could not find highest ancestor")
          } else {
            return this.customerService.getCustomer(customer.customerId)
          }
          this.retrieveUsers()
          return EMPTY
        })
      ).subscribe())
    }
  }

  retrieveUsers(): void {
    let cid = undefined
    if (this.selectedScope == QueryScope.CHILDREN_FROM_HIGHEST_ANCESTOR) {
      cid = this.highestAncestorCid
    }
    this.subscriptions.add(
      this.userService.getUsersWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.userSearchForm.value.userSearchField,
        this.sortQueryParam,
        cid
      ).pipe(
        tap(invocationResult => {
          this.paginator.length = invocationResult.pagination?.count as number
          this.userDataSource.data = invocationResult.data.map((user: User) => ({ user: user, customerNames: '' }))
        }),
        mergeMap(invocationResult => from(invocationResult.data) as Observable<User>),
        mergeMap((user: User) => zip(
          of(user),
          this.customerService.getCustomer(user.customerId).pipe(
            map(customer => customer.name)
          ),
          from(user.customers).pipe(
            mergeMap(customerId => this.customerService.getCustomer(customerId)),
            map(customer => customer.name),
            toArray()
          )
        ))
      ).subscribe(([user, managedBy, customerNames]) => {
        const u = this.userDataSource.data.find(el => el.user.id == user.id)
        if (typeof u !== 'undefined') {
          u.customerNames = customerNames.join(', ')
          u.managedBy = managedBy
        }
      })
    )
  }

  doDelete(user: User) {
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("USER.DELETE.TITLE"),
      content: this.translateService.instant("USER.DELETE.CONTENT"),
    }
    config.panelClass = "humecloud-dialog-container"
    const userDeleteDialogRef = this.matDialog.open(DeleteDialogComponent, config)
    this.subscriptions.add(
      userDeleteDialogRef.afterClosed().pipe(
        mergeMap(value => {
          if (value) {
            return this.userService.deleteUser(user.id, user.customerId)
          }
          return of(true)
        })
      ).subscribe(_ => {
        this.retrieveUsers()
      })
    )
  }

  onQueryScopeChange(event: MatSelectChange): void {
    this.selectedScope = event.value
    this.retrieveUsers()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handlePage(e: any) {
    this.retrieveUsers()
  }

  resetPagination() {
    this.paginator.firstPage()
  }

  sortData($event: Sort) {
    this.sortQueryParam = $event.active + ":" + $event.direction
    this.retrieveUsers()
  }

  handleSearchKeyUp(event: KeyboardEvent): void {
    this.searchKeyUp.next((event.target as HTMLInputElement).value)
  }
}
