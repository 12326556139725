import { DeviceActionType, TunnelDeviceActionStatus } from "../device-action"

export abstract class DeviceActionDto {
  id?: string
  readonly type!: DeviceActionType
  deviceId!: string
}

export class ClearDataDeviceActionDto extends DeviceActionDto {
  override readonly type: DeviceActionType = DeviceActionType.CLEAR_DATA
}

export class SendLogsDeviceActionDto extends DeviceActionDto {
  override readonly type: DeviceActionType = DeviceActionType.SEND_LOGS
}

export class TunnelDeviceActionDto extends DeviceActionDto {
  override readonly type: DeviceActionType = DeviceActionType.TUNNEL
  port!: number
  status!: TunnelDeviceActionStatus
}
