import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Device } from '../../../../interfaces/device';
import { DeviceActionService } from '../../../../services/device-action/device-action.service';
import { DeviceActionType, TunnelDeviceAction, TunnelDeviceActionStatus } from '../../../../interfaces/device-action';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CmnIfPermDirective } from 'hcl-lib';

@Component({
    selector: 'app-device-tunnel-status',
    templateUrl: './device-tunnel-status.component.html',
    styleUrls: ['./device-tunnel-status.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, TranslateModule, CmnIfPermDirective]
})
export class DeviceTunnelStatusComponent implements OnInit, OnDestroy {

  @Input() device!: Device

  subscriptions: Subscription = new Subscription()

  action!: TunnelDeviceAction
  TunnelDeviceActionStatus = TunnelDeviceActionStatus

  constructor(
    private deviceActionService: DeviceActionService
  ) { }

  ngOnInit(): void {
    this.retrieveTunnelAction()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveTunnelAction(): void {
    this.subscriptions.add(
      this.deviceActionService.getDeviceAction(this.device.id, DeviceActionType.TUNNEL).subscribe(action => {
        this.action = action as TunnelDeviceAction
      })
    )
  }
}
