<mat-toolbar color="accent">
  <!-- This fills the remaining space of the current row -->
  <span class="fill-remaining-space"></span>
</mat-toolbar>
<div class="mb-3">
  <mat-table [dataSource]="licenseDataSource" matSort>
    <ng-container matColumnDef="licenseType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LICENSE.LABEL.LICENSE_TYPE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <span *ngIf="licenseTypeList[license.licenseTypeId]">
          {{ licenseTypeList[license.licenseTypeId].name }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="start">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LICENSE.LABEL.START' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let license">{{ license.start | date }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="end">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LICENSE.LABEL.END' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let license">{{ license.end | date }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="quota">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'QUOTA' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{ license.quota }}/<span *ngIf="licenseTypeList[license.licenseTypeId]">{{
          licenseTypeList[license.licenseTypeId].quota }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="plugins">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Plugins</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <span *ngIf="license.pluginIds && license.pluginIds.length > 0">{{ getPluginNames(license.pluginIds) }}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="trial">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LICENSE_TYPE.EVALUATION_LICENSE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <span *ngIf="licenseTypeList[license.licenseTypeId]">
          {{ licenseTypeList[license.licenseTypeId].trial === true ?
          ("YES" | translate) :
          ("NO" | translate) }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let license">
        <button mat-button color="primary" matTooltip="Upgrade"
          [routerLink]="['/contact']">
          <mat-icon>upgrade</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage($event)">
  </mat-paginator>
</div>
