<mat-toolbar color="accent">
  <div class="toolbar__left">
  </div>
  <div class="toolbar__right_buttons">
    <button *cmnIfPerm="['cmn:plugin-config:create']" mat-raised-button
            (click)="doCreate()"
            routerLinkActive="router-link-active">
      <mat-icon>add</mat-icon>{{ 'PLUGIN.CONFIG.ADD' | translate }}
    </button>
  </div>
</mat-toolbar>
<div class="mb-3">
  <mat-table [dataSource]="pluginConfigDataSource" matSort>
    <ng-container matColumnDef="plugin">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PLUGIN.LABEL' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data">
        <span *ngIf="data.pluginName">
          {{ data.pluginName }}
        </span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let data"
                class="plugin-configs__config__actions" >
        <button *cmnIfPerm="['cmn:plugin-config:update']" mat-button color="primary"
                (click)="doUpdate(data.config)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button *cmnIfPerm="['cmn:plugin-config:delete']" mat-button color="primary"
                (click)="doDelete(data.config)"
                id="deleteUserButton">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage()">
  </mat-paginator>
</div>
