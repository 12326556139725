<h1 mat-dialog-title>{{'EQUIPMENT_MONITORING' | translate}}</h1>
<mat-dialog-content>
  <div *ngIf="status">
    {{ status.monit }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button">
    {{'CLOSE' | translate}}
  </button>
</mat-dialog-actions>
