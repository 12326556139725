import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { NotificationRecipientsService } from "../../../services/notifications/notification-recipients.service"
import { EventWithCustomerName } from "../event-list/event-list.component"
import { EventTranslation } from "../../../interfaces/event-type"
import { DatePipe } from "@angular/common"
import { MatDialogModule } from "@angular/material/dialog"
import { MatButtonModule } from "@angular/material/button"
import { TranslateModule } from "@ngx-translate/core"

@Component({
    selector: "app-event-modal",
    templateUrl: "./event-modal.component.html",
    styleUrls: ["./event-modal.component.scss"],
    standalone: true,
    imports: [DatePipe, MatDialogModule, MatButtonModule, TranslateModule]
})
export class EventModalComponent implements OnInit {

  event!: EventWithCustomerName
  translation?: EventTranslation

  constructor(
    public dialogRef: MatDialogRef<EventModalComponent>,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(MAT_DIALOG_DATA) private data: EventWithCustomerName,
    private notificationRecipientService: NotificationRecipientsService
  ) { }

  ngOnInit(): void {
    this.event = this.data
    this.translation = this.event.translations.find(t => t.code == this.locale)
  }

  readNotification() {
    this.notificationRecipientService.readNotification(this.event.id).subscribe(it => {
      console.log(it)
    })
  }
}
