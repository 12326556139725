<mat-card>
  <mat-card-header class="device-details-status__header">
    <mat-card-title>
      {{ 'DEVICE.DETAILS_STATUS.TITLE' | translate }}
    </mat-card-title>
    <app-device-status [device]="device"></app-device-status>
  </mat-card-header>
  <mat-card-content class="device-details-status__content">
    <div class="device-details-status__content__data">
      <span>{{ 'DEVICE.DETAILS_STATUS.SCHEDULE' | translate }}</span>
      <div>
        <ng-container [ngSwitch]="scheduleState">
          <a *ngSwitchCase="ScheduleStates.SCHEDULE" href="" [routerLink]="['/screenlab', 'schedules', schedule.id]">
            {{ schedule.name }}
          </a>
          <span *ngSwitchCase="ScheduleStates.PARENT_SCHEDULE">
            <span>{{ schedule.name }}</span>
            ({{ 'DEVICE.DETAILS_STATUS.PARENT_SCHEDULE' | translate }})
          </span>
          <span *ngSwitchCase="ScheduleStates.SVD_SCHEDULE">
            {{ 'DEVICE.DETAILS_STATUS.SVD_SCHEDULE' | translate }}
          </span>
          <span *ngSwitchCase="ScheduleStates.NONE">
            {{ 'DEVICE.DETAILS_STATUS.NO_SCHEDULE' | translate }}
          </span>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="status">
      <div class="device-details-status__content__data">
        <span>{{ 'DEVICE.DETAILS_STATUS.LAST_ACTIVITIY' | translate }}</span>
        <span>{{ status.ts | date:'medium' }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.appVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.APP_VERSION' | translate }}</span>
        <span>{{ status.appVersion }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.publicIp">
        <span>{{ 'DEVICE.DETAILS_STATUS.LAST_IP' | translate }}</span>
        <span>{{ status.publicIp }}</span>
      </div>

      <div class="device-details-status__content__title"
        *ngIf="status.disk || status.memory || status.load || status.temp">
        {{ 'DEVICE.DETAILS_STATUS.SYSTEM' | translate }}
      </div>

      <!-- 123 MB / 128 GB [13%] -->
      <div class="device-details-status__content__data" *ngIf="status.disk">
        <span>{{ 'DEVICE.DETAILS_STATUS.DISK_SPACE' | translate }}</span>
        <span>{{ status.disk }}%</span>
      </div>

      <!-- 1024 MB / 4096 MB [77%] -->
      <div class="device-details-status__content__data" *ngIf="status.memory">
        <span>{{ 'DEVICE.DETAILS_STATUS.MEMORY' | translate }}</span>
        <span>{{ status.memory }}%</span>
      </div>

      <!-- [0.04] [0.04] [0.02] -->
      <div class="device-details-status__content__data" *ngIf="status.load">
        <span>{{ 'DEVICE.DETAILS_STATUS.LOAD_AVERAGE' | translate }}</span>
        <span>{{ status.load }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.temp">
        <span>{{ 'DEVICE.DETAILS_STATUS.TEMPERATURE' | translate }}</span>
        <span>{{ status.temp }}°C</span>
      </div>

      <!-- network -->
      <div class="device-details-status__content__title"
        *ngIf="status.ipAddress || status.macAddress || status.networkType">
        {{ 'DEVICE.DETAILS_STATUS.NETWORK' | translate }}
      </div>

      <div class="device-details-status__content__data" *ngIf="status.ipAddress">
        <span>{{ 'DEVICE.DETAILS_STATUS.IP_ADDRESS' | translate }}</span>
        <span>{{ status.ipAddress }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.macAddress">
        <span>{{ 'DEVICE.DETAILS_STATUS.MAC_ADDRESS' | translate }}</span>
        <span>{{ status.macAddress }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.networkType">
        <span>{{ 'DEVICE.DETAILS_STATUS.NETWORK_TYPE' | translate }}</span>
        <span>{{ status.networkType }}</span>
      </div>

      <!-- platform -->
      <div class="device-details-status__content__title" *ngIf="status.manufacturer
          || status.model
          || status.webosVersion
          || status.sdkVersion
          || status.serialNumber
          || status.firmwareVersion
          || status.hardwareVersion">
        {{ 'DEVICE.DETAILS_STATUS.PLATFORM' | translate }}
      </div>

      <div class="device-details-status__content__data" *ngIf="status.manufacturer">
        <span>{{ 'DEVICE.DETAILS_STATUS.MANUFACTURER' | translate }}</span>
        <span>{{ status.manufacturer }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.model">
        <span>{{ 'DEVICE.DETAILS_STATUS.MODEL' | translate }}</span>
        <span>{{ status.model }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.webosVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.WEBOS_VERSION' | translate }}</span>
        <span>{{ status.webosVersion }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.sdkVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.SDK_VERSION' | translate }}</span>
        <span>{{ status.sdkVersion }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.serialNumber">
        <span>{{ 'DEVICE.DETAILS_STATUS.SERIAL_NUMBER' | translate }}</span>
        <span>{{ status.serialNumber }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.firmwareVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.FIRMWARE_VERSION' | translate }}</span>
        <span>{{ status.firmwareVersion }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.buildVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.BUILD_VERSION' | translate }}</span>
        <span>{{ status.buildVersion }}</span>
      </div>

      <div class="device-details-status__content__data" *ngIf="status.hardwareVersion">
        <span>{{ 'DEVICE.DETAILS_STATUS.HARDWARE_VERSION' | translate }}</span>
        <span>{{ status.hardwareVersion }}</span>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>