import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core"
import {from, Observable, of, Subscription, zip} from "rxjs"
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms"
import {EventUserPreferenceService} from "../../../services/event-user-preference/event-user-preference.service"
import {ChannelType} from "../../../interfaces/enum/channel-type"
import {TranslateModule, TranslateService} from "@ngx-translate/core"
import {Customer, CustomerService, InvocationResult, KeycloakService} from "hcl-lib"
import {MatPaginator} from "@angular/material/paginator"
import {MatSort, MatSortModule} from "@angular/material/sort"
import {MatTableDataSource} from "@angular/material/table"
import {MatDialog} from "@angular/material/dialog"
import {
  UserNotificationPreferenceEditModalComponent
} from "../event-user-notification-preference-edit-modal/user-notification-preference-edit-modal.component"
import {UserPreference} from "../../../interfaces/user-preference"
import {UserNotificationPreference} from "../../../interfaces/user-notification-preference"
import {map, mergeMap, tap, toArray} from "rxjs/operators"
import { RouterModule } from "@angular/router"
import { NgFor, NgIf } from "@angular/common"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatCardModule } from "@angular/material/card"
import { MatTableModule } from "@angular/material/table"
import { SearchableSelectComponent } from "../../../Layout/Components/widget/searchable-select/searchable-select.component"
import { LoaderComponent } from "../../../Layout/Components/widget/loader/loader.component"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatSelectModule } from "@angular/material/select"

export interface PopulatedUserNotificationPreference extends UserNotificationPreference {
  targetCustomers: Customer[]
  targetCustomersName: string
  channelsName: string
}

@Component({
    selector: "app-event-user-preference",
    templateUrl: "./event-user-preference.component.html",
    styleUrls: ["./event-user-preference.component.scss"],
    standalone: true,
    imports: [RouterModule, NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, MatCardModule, MatTableModule, MatSortModule, MatPaginatorModule, MatToolbarModule, MatButtonModule, MatIconModule, TranslateModule, SearchableSelectComponent, LoaderComponent]
})
export class EventUserPreferenceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  subscriptions: Subscription = new Subscription()
  generalFieldsForm!: UntypedFormGroup
  searchableSelectChannels: { id: ChannelType, name: string, selected: boolean}[]
  isLoadingResults = false
  notificationUserPreferenceDataSource = new MatTableDataSource<PopulatedUserNotificationPreference>()
  columnsToDisplay = ["key", "channels", "targetCustomerIds", "actions"]
  eventUserPreference!: UserPreference
  languages = ["fr", "en"]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private eventUserPreferenceService: EventUserPreferenceService,
    private keycloakService: KeycloakService,
    private dialog: MatDialog
) {
    this.searchableSelectChannels = Object.keys(ChannelType).map(
      channel => ({
        id: ChannelType[channel as keyof typeof ChannelType],
        name: this.translateService.instant("EVENT.USER_PREFERENCE.CHANNELS." + channel),
        selected: false
      })
    )
  }

  ngOnInit(): void {
    this.initializeFormBuilder()
  }

  ngAfterViewInit() {
    this.retrieveUserNotificationPreferences()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  initializeFormBuilder() {
    this.createFieldsForm()
    this.subscriptions.add(
      this.eventUserPreferenceService.getEventUserPreference(
        this.keycloakService.getApiUserId()
      ).subscribe(eup => {
        if (eup) {
          this.eventUserPreference = {
            id: eup.id,
            userId: eup.userId,
            language: eup.language as string,
            channels: eup.channels,
            emailOverride: eup.emailOverride
          }
          eup.channels.forEach(channel => {
            for (const item of this.searchableSelectChannels) {
              if (item.id === channel) {
                item.selected = true
              }
            }
          })
          this.generalFieldsForm.patchValue({
            id: eup.id,
            userId: eup.userId,
            language: eup.language,
            emailOverride: eup.emailOverride,
            channels: this.searchableSelectChannels
          })
        }
      })
    )
  }

  retrieveUserNotificationPreferences(): void {
    this.isLoadingResults = true
    this.subscriptions.add(
      this.eventUserPreferenceService.getEventUserNotificationPreferenceWithPaging(
        this.keycloakService.getApiUserId(),
        this.paginator.pageIndex,
        this.paginator.pageSize
      ).pipe(
        tap((invocationResult: InvocationResult) => {
          this.paginator.length = invocationResult.pagination?.count as number
        }),
        mergeMap((invocationResult: InvocationResult) => from(invocationResult.data) as Observable<UserNotificationPreference>),
        mergeMap((userNotificationPreference: UserNotificationPreference) => zip(
          of(userNotificationPreference),
          from(userNotificationPreference.targetCustomerIds as string[]).pipe(
            mergeMap(targetCustomerId => this.customerService.getCustomer(targetCustomerId)),
            toArray()
          )
        )),
        map(([userNotificationPreference, customers]) => ({
          ...userNotificationPreference,
          targetCustomers: customers,
          targetCustomersName: customers.map(it => it.name).join(", "),
          channelsName: userNotificationPreference.channels?.map(it =>
            this.translateService.instant(`EVENT.USER_PREFERENCE.CHANNELS.${it}`)).join(", ")
        }) as PopulatedUserNotificationPreference),
        toArray()
      ).subscribe(populatedUserNotificationPreferences => {
        this.notificationUserPreferenceDataSource.data = populatedUserNotificationPreferences
        this.isLoadingResults = false
      })
    )
  }

  onSubmit() {
    if (this.generalFieldsForm.value.id) {
      this.subscriptions.add(
        this.eventUserPreferenceService.updateEventUserPreference(this.generalFieldsForm.value).subscribe()
      )
    } else {
      this.subscriptions.add(
        this.eventUserPreferenceService.createEventUserPreference(this.generalFieldsForm.value).subscribe()
      )
    }
  }

  createFieldsForm() {
    this.generalFieldsForm = this.formBuilder.group({
      id: [null],
      userId: [this.keycloakService.getApiUserId(), Validators.required],
      language: ["fr"],
      channels: [this.searchableSelectChannels, Validators.required],
      emailOverride: [null],
    })
    this.generalFieldsForm.get("language")?.valueChanges.subscribe(it => {
      this.eventUserPreference.language = it
    })
  }

  onEdit(row?: UserNotificationPreference) {
    const dialogRef = this.dialog.open(UserNotificationPreferenceEditModalComponent, {
      data: { userPreference: this.eventUserPreference, userNotificationPreference: row }
    })
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.retrieveUserNotificationPreferences()
      }
    }))
  }

  onDelete(row?: UserNotificationPreference) {
    if (row && row.id) {
      this.eventUserPreferenceService.deleteEventUserPreference(row.id).subscribe(isDeleted => {
        if (isDeleted) {
          this.notificationUserPreferenceDataSource.data = this.notificationUserPreferenceDataSource.data
            .filter(it => it.id !== row.id)
        }
      })
    }
  }
}
