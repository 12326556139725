import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { InvocationResult } from "hcl-lib";
import { HttpClient, HttpParams } from "@angular/common/http";
import { VerifoneReader } from "../../interfaces/verifone-reader";
import { VerifoneReaderDto } from "../../interfaces/dto/verifone-reader-dto";

@Injectable({
  providedIn: 'root'
})
export class VerifoneReaderService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  createVerifoneReader(verifoneReader: VerifoneReader): Observable<VerifoneReader | null> {
    const url = `${environment.deviceApiBaseUrl}/verifone-readers`

    const verifoneReaderDto: VerifoneReaderDto = {
      serialNumber: verifoneReader.serialNumber,
      description: verifoneReader.description,
      poste: verifoneReader.poste,
      ilotlabel: verifoneReader.ilotlabel,
      isProdInstance: verifoneReader.isProdInstance,
      primaryIp: verifoneReader.primaryIp,
      primaryPort: verifoneReader.primaryPort,
      secondaryIp: verifoneReader.secondaryIp,
      secondaryPort: verifoneReader.secondaryPort,
      deviceId: verifoneReader.deviceId
    }

    return this.httpClient.post(url, verifoneReaderDto).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

  updateVerifoneReader(verifoneReader: VerifoneReader): Observable<VerifoneReader | null> {
    const url = `${environment.deviceApiBaseUrl}/verifone-readers/${verifoneReader.id}`

    const verifoneReaderDto: VerifoneReaderDto = {
      id: verifoneReader.id,
      serialNumber: verifoneReader.serialNumber,
      description: verifoneReader.description,
      poste: verifoneReader.poste,
      ilotlabel: verifoneReader.ilotlabel,
      isProdInstance: verifoneReader.isProdInstance,
      primaryIp: verifoneReader.primaryIp,
      primaryPort: verifoneReader.primaryPort,
      secondaryIp: verifoneReader.secondaryIp,
      secondaryPort: verifoneReader.secondaryPort,
      deviceId: verifoneReader.deviceId
    }

    return this.httpClient.put(url, verifoneReaderDto).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

  getVerifoneReader(id: string): Observable<VerifoneReader | null> {
    const url = `${environment.deviceApiBaseUrl}/verifone-readers/${id}`

    return this.httpClient.get(url).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

  getVerifoneReaderBySerialNumber(serialNumber: string): Observable<VerifoneReader | null> {
    const url = `${environment.deviceApiBaseUrl}/verifone-readers/search`

    let queryParams = new HttpParams()
      .set('property', 'serialNumber')
      .set('value', serialNumber)

    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => (res as InvocationResult).data),
      catchError(_ => of(null))
    )
  }

}
