import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgIf, NgxLoadingModule]
})
export class LoaderDecoratorComponent implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes

  loaderEnabled = false
  blockingLoaderEnabled = false

  constructor(
    private cdr: ChangeDetectorRef,
    public loaderService: LoaderService
  ) { }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.loaderService.loaderEnabledObservable.subscribe(loaderEnabled => {
        this.loaderEnabled = loaderEnabled
        this.cdr.detectChanges()
      })
    )
    this.subscriptions.add(
      this.loaderService.blockingLoaderEnabledObservable.subscribe(blockingLoaderEnabled => {
        this.blockingLoaderEnabled = blockingLoaderEnabled
        this.cdr.detectChanges()
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
