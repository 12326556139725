<div class="customer-roles" *ngIf="customerRolesSelect && roles">
  <mat-chip-set>
    <mat-chip [disabled]="true" class="customer-chip">
      {{customerRolesSelect.customer.name}}
    </mat-chip>
  </mat-chip-set>
  <ng-multiselect-dropdown class="roles-dropdown" name="role" placeholder="{{'SELECT_ROLES' | translate}}"
    [settings]="dropdownRolesSettings" [data]="roles" (onDeSelect)="onRolesModified($event)" [(ngModel)]="selectedRoles"
    (onSelect)="onRolesModified($event)" (onSelectAll)="onSelectAllRoles($event)"
    (onDeSelectAll)="onDeSelectAllRoles($event)">
  </ng-multiselect-dropdown>
</div>
