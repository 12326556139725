import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription, zip} from "rxjs";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {
  Location,
  LocationBedroom,
  LocationFloor,
  LocationHotel, LocationMeetingRoom,
  LocationRoom,
  LocationType
} from "../../../interfaces/location";
import {LocationService} from "../../../services/location/location.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {map, mergeMap} from "rxjs/operators";
import {NgFor, NgForOf, NgIf, NgSwitch, NgSwitchCase, UpperCasePipe} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogConfig} from "@angular/material/dialog";
import {MatDivider} from "@angular/material/divider";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CmnIfPermDirective, SnackBarService, Tag, TagService, TargetType} from "hcl-lib";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {LocationCreateDialogComponent} from "../location-create-dialog/location-create-dialog.component";

@Component({
  selector: 'app-location-details',
  standalone: true,
  imports: [
    NgFor, UpperCasePipe, MatButton, MatCheckbox, MatDialogActions, MatDialogClose, MatDivider, MatFormField, MatIcon, MatInput, MatLabel, MatOption, MatSelect, NgIf, ReactiveFormsModule, TranslateModule, MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatIconButton, MatRow, MatRowDef, MatSort, MatSortHeader, MatTable, NgSwitchCase, NgSwitch, MatHeaderCellDef, RouterLink, MatPaginator, MatPaginatorModule, CmnIfPermDirective
  ],
  templateUrl: './location-details.component.html',
  styleUrl: './location-details.component.scss'
})
export class LocationDetailsComponent implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  locationTypes: LocationType[] = Object.values(LocationType);

  locationId: string = ""
  location: Location | undefined
  childLocations: Location[] = []
  ancestorLocations: Location[] = []

  @ViewChild("searchLocationTag") searchLocationTag!: ElementRef
  locationTags: Tag[] = []
  filteredLocationTags: Tag[] = []

  locationForm: FormGroup = this.formBuilder.group({
    type: [null, Validators.required],
    name: [null, Validators.required],
    description: [null],
    locationId: [null],
    tagIds: [[]]
  })

  LocationType: typeof LocationType = LocationType

  locationDataSource = new MatTableDataSource<Location>()
  columnsToDisplay = ["type", "name", "actions"]

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: false }) sort!: MatSort

  constructor(
    private locationService: LocationService,
    private tagService: TagService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private matDialog: MatDialog,
    private snackService: SnackBarService,
    private router: Router
  ) {
    // do nothing
  }

  ngAfterViewInit(): void {
    this.route.params.subscribe((params) => {
      this.locationId = params['id'];
      this.retrieveLocationInformations()
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  retrieveLocationInformations() {
    this.subscriptions.add(
      this.locationService.getLocation(this.locationId).pipe(
        mergeMap(location =>
          zip(
            this.locationService.getLocationsWithPaging(
              this.paginator.pageIndex,
              this.paginator.pageSize,
              undefined, undefined, undefined, undefined,
              this.locationId
            ),
            this.tagService.getAvailableTags([TargetType.LOCATION]),
            this.locationService.getLocationsByIds(location.ancestorLocationIds ?? []).pipe(
              map(ancestorLocations => ({
                location,
                ancestorLocations
              }))
            )
          ).pipe(
            map(([childLocations, locationTags, { location, ancestorLocations }]) => ({
              location,
              childLocations,
              ancestorLocations,
              locationTags
            }))
          )
        )
      ).subscribe(({ location, childLocations, ancestorLocations, locationTags }) => {
        this.location = location;
        this.childLocations = childLocations.data;

        this.ancestorLocations = location.ancestorLocationIds
          ? location.ancestorLocationIds
            .map(id => ancestorLocations.find(location => location.id === id))
            .filter((location): location is Location => !!location)
          : [];

        this.locationTags = locationTags;
        this.filteredLocationTags = locationTags;

        this.locationDataSource.data = childLocations.data;
        this.paginator.length = childLocations.pagination?.count as number;

        this.locationForm = this.formBuilder.group({
          type: [this.location.type, Validators.required],
          name: [this.location.name, Validators.required],
          description: [this.location.description],
          locationId: [this.location.locationId],
          tagIds: [this.location.tagIds]
        });

        this.locationForm.get('type')?.valueChanges.subscribe((selectedType) => {
          this.updateFormFields(selectedType);
        });
        this.updateFormFields(location.type);
      })
    );
  }

  doSave() {
    const location: Location = this.buildLocationFromForm();

    this.subscriptions.add(
      this.locationService.updateLocation(location).subscribe(() => {
        this.retrieveLocationInformations();
        this.snackService.snackBarSuccess(this.translateService.instant('LOCATION.DETAILS.UPDATE_SUCCESSFUL'));
      })
    );
  }

  updateFormFields(selectedType: LocationType) {
    this.resetConditionalFields();

    const location = this.location;

    switch (selectedType) {
      case LocationType.HOTEL:
        this.addHotelFields(location as LocationHotel);
        break;
      case LocationType.FLOOR:
        this.addFloorFields(location as LocationFloor);
        break;
      case LocationType.ROOM:
      case LocationType.MEETING_ROOM:
      case LocationType.BEDROOM:
        this.addRoomFields(location as LocationRoom | LocationBedroom | LocationMeetingRoom);
        break;
      case LocationType.COMMON_AREA:
      case LocationType.OTHER:
      default:
        break;
    }
  }

  private addHotelFields(hotelLocation: LocationHotel) {
    this.locationForm.addControl('address', this.formBuilder.control(hotelLocation.address, Validators.required));
    this.locationForm.addControl('city', this.formBuilder.control(hotelLocation.city));
    this.locationForm.addControl('postalCode', this.formBuilder.control(hotelLocation.postalCode));
    this.locationForm.addControl('country', this.formBuilder.control(hotelLocation.country));
  }

  private addFloorFields(floorLocation: LocationFloor) {
    this.locationForm.addControl('number', this.formBuilder.control(floorLocation.number));
  }

  private addRoomFields(roomLocation: LocationRoom | LocationBedroom | LocationMeetingRoom) {
    this.locationForm.addControl('number', this.formBuilder.control(roomLocation.number));
    this.locationForm.addControl('available', this.formBuilder.control(roomLocation.available));
  }

  resetConditionalFields() {
    const controlsToRemove = ['address', 'city', 'postalCode', 'country', 'floorNbr', 'number', 'available'];
    controlsToRemove.forEach(control => {
      if (this.locationForm.contains(control)) {
        this.locationForm.removeControl(control);
      }

    });
  }

  handlePage(): void {
    this.retrieveLocationInformations()
  }

  onOpenLocationTag($open: boolean) {
    if ($open) {
      this.searchLocationTag.nativeElement.focus()
    } else {
      this.searchLocationTag.nativeElement.value = ''
      this.onSearchLocationTag(undefined)
    }
  }

  doOpenCreate(): void {
    const config = new MatDialogConfig()
    config.data = {
      locationId: this.location!!.id
    }

    const locationCreateDialogRef = this.matDialog.open(LocationCreateDialogComponent, config)

    this.subscriptions.add(
      locationCreateDialogRef.afterClosed().subscribe(_ => {
        this.retrieveLocationInformations()
      })
    )
  }

  doDelete(): void {
    if (this.childLocations.length > 0) {
      this.snackService.snackBarError(this.translateService.instant('LOCATION.DELETE.DENIED'))
      return
    }
    this.subscriptions.add(this.locationService.deleteLocation(this.locationId).subscribe(_ => {
      this.doGoBack()
    }))
  }

  doGoBack(): void {
    this.router.navigate(["locations"]);
  }

  onSearchLocationTag(event?: Event) {
    if (typeof event !== 'undefined') {
      const value = (event.target as HTMLInputElement).value
      this.filteredLocationTags = value ? this.locationTags?.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase())) : this.locationTags?.slice()
    }
  }

  private buildLocationFromForm(): Location {
    const baseLocation: Location = {
      id: this.locationId,
      type: this.locationForm.value.type,
      name: this.locationForm.value.name,
      description: this.locationForm.value.description,
      locationId: this.locationForm.value.locationId,
      tagIds: this.locationForm.value.tagIds.map((tags: any) => tags.id),
    };

    switch (this.locationForm.value.type) {
      case LocationType.HOTEL:
        return {
          ...baseLocation,
          address: this.locationForm.value.address,
          city: this.locationForm.value.city,
          country: this.locationForm.value.country,
          postalCode: this.locationForm.value.postalCode,
        } as LocationHotel;

      case LocationType.FLOOR:
        return {
          ...baseLocation,
          number: this.locationForm.value.number,
        } as LocationFloor;

      case LocationType.ROOM:
      case LocationType.BEDROOM:
      case LocationType.MEETING_ROOM:
        return {
          ...baseLocation,
          number: this.locationForm.value.number,
          available: this.locationForm.value.available,
        } as LocationRoom | LocationBedroom | LocationMeetingRoom;

      default:
        return baseLocation;
    }
  }
}
