<div class="verifone-reader-dialog">
  <div mat-dialog-title class="verifone-reader-dialog_title">
    {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.TITLE' | translate }}
  </div>
  <form [formGroup]="verifoneForm" class="verifone-reader-dialog_content">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SERIAL_NUMBER' | translate }}
      </mat-label>
      <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SERIAL_NUMBER' | translate"
        formControlName="serialNumber">
    </mat-form-field>
    <button type="button" color="primary" (click)="search()" mat-raised-button>
      <span [ngSwitch]="state">
        <span *ngSwitchCase="VerifoneReaderAction.SEARCHING">
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SEARCH' | translate }}
        </span>
        <span *ngSwitchCase="VerifoneReaderAction.EDIT">
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SEARCH_EDIT' | translate }}
        </span>
        <span *ngSwitchCase="VerifoneReaderAction.CREATE">
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SEARCH_NEW' | translate }}
        </span>
      </span>
    </button>
    <div *ngIf="state != VerifoneReaderAction.SEARCHING">
      <div *ngIf="state == VerifoneReaderAction.CREATE" class="verifone-reader-dialog_content_result">
        {{'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.CREATE_NEW' | translate}}
      </div>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.DESCRIPTION' | translate }}
        </mat-label>
        <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.DESCRIPTION' | translate"
          formControlName="description">
      </mat-form-field>
      <div class="verifone-reader-dialog_content_double">
        <mat-form-field class="verifone-reader-dialog_content_double--left" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.POSTE' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.POSTE' | translate"
            formControlName="poste">
        </mat-form-field>
        <mat-form-field class="verifone-reader-dialog_content_double--right" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.ILOT_LABEL' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.ILOT_LABEL' | translate"
            formControlName="ilotlabel">
        </mat-form-field>
      </div>
      <mat-checkbox formControlName="isProdInstance" (change)="updateInstanceValues()">
        {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.PROD_INSTANCE' | translate }}
      </mat-checkbox>
      <div class="verifone-reader-dialog_content_double">
        <mat-form-field class="verifone-reader-dialog_content_double--left" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.PRIMARY_IP' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.PRIMARY_IP' | translate"
            formControlName="primaryIp">
        </mat-form-field>
        <mat-form-field class="verifone-reader-dialog_content_double--right" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.PRIMARY_PORT' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.PRIMARY_PORT' | translate"
            formControlName="primaryPort">
        </mat-form-field>
      </div>
      <div class="verifone-reader-dialog_content_double">
        <mat-form-field class="verifone-reader-dialog_content_double--left" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SECONDARY_IP' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SECONDARY_IP' | translate"
            formControlName="secondaryIp">
        </mat-form-field>
        <mat-form-field class="verifone-reader-dialog_content_double--right" appearance="outline">
          <mat-label>
            {{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SECONDARY_PORT' | translate }}
          </mat-label>
          <input matInput [placeholder]="'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SECONDARY_PORT' | translate"
            formControlName="secondaryPort">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="verifone-reader-dialog_footer">
    <ng-container *ngIf="state != VerifoneReaderAction.SEARCHING">
      <button class="button" *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" type="button" (click)="validate()"
        mat-raised-button color="primary" [disabled]="!verifoneForm.valid">
        <mat-icon>check</mat-icon>
        <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.SAVE' | translate }}</span>
      </button>
    </ng-container>
    <button class="button" *cmnIfPerm="['scr:device:update:hardware'];op:'OR'" type="button" (click)="back()"
      mat-raised-button>
      <span>{{ 'DEVICE.HARDWARE.PAYMENT_TERMINALS.VERIFONE.MODAL.CANCEL' | translate }}</span>
    </button>
  </div>
</div>