<form [formGroup]="deviceForm" (ngSubmit)="onSubmit()" *ngIf="device">

  <mat-toolbar color="accent">
    <button type="button" mat-raised-button [routerLink]="['..']">
      <mat-icon>arrow_back</mat-icon>
      {{ 'BACK' | translate }}
    </button>
    <button type="button" class="device-details__migrate-btn" mat-raised-button (click)="openDeviceMigrateDialog()"
      *cmnIfPerm="['scr:device:update:owner']">
      {{ "DEVICE.MIGRATE.BUTTON" | translate }}
    </button>
    <span class="fill-remaining-space"></span>
    <button mat-raised-button [disabled]="!deviceForm.valid" type="submit">
      <mat-icon>done</mat-icon>
      {{ 'VALIDATE' | translate }}
    </button>
  </mat-toolbar>

  <div class="hcl-header">
    <span class="scr-header-title">
      {{ "DEVICE_DETAILS.TITLE" | translate : { name: device.name } }}
    </span>
  </div>

  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab>
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.DETAILS" | translate }}</ng-template>
      <div class="device-details__details">
        <mat-card class="device-details__details__block">
          <mat-card-header>
            <mat-card-title>
              {{ "DEVICE_DETAILS.GENERAL_CONFIGURATION" | translate }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <!-- name -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.NAME.LABEL' | translate }}</mat-label>
              <input matInput formControlName="name" [placeholder]="'DEVICE.FIELDS.NAME.PLACEHOLDER' | translate"
                required>
            </mat-form-field>
            <!-- type -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.TYPE.LABEL' | translate }}</mat-label>
              <mat-select formControlName="type" matNativeControl (selectionChange)="onDeviceTypeChanged($event)"
                required>
                <mat-option *ngFor="let type of deviceTypesKeys" [value]="type">
                  {{ "DEVICE.TYPE." + type | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- orientation -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.ORIENTATION.LABEL' | translate }}</mat-label>
              <mat-select formControlName="orientation" matNativeControl required>
                <mat-option *ngFor="let orientation of deviceOrientationKeys" [value]="orientation">
                  {{ "DEVICE.ORIENTATION." + orientation | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- muted -->
            <mat-checkbox formControlName="muted">
              {{ 'DEVICE.FIELDS.MUTED.LABEL' | translate }}
            </mat-checkbox>
            <!-- autoPowerOnOff -->
            <mat-checkbox formControlName="autoPowerOnOff">
              {{ 'DEVICE.FIELDS.AUTO_POWER_ON_OFF.LABEL' | translate }}
            </mat-checkbox>
            <!-- useRealTime -->
            <mat-checkbox formControlName="useRealTime" *cmnIfPerm="['scr:device:update:use-real-time']">
              {{ "DEVICE.FIELDS.USE_REAL_TIME.LABEL" | translate }}
            </mat-checkbox>
            <!-- tags -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.TAGS.LABEL' | translate }}</mat-label>
              <mat-select formControlName="addedTags" multiple (openedChange)="onOpenDeviceTag($event)">
                <input #searchDeviceTag matInput type="text" (input)="onSearchDeviceTag($event)" autocomplete="off">
                <mat-divider></mat-divider>
                <mat-option *ngFor="let deviceTag of filteredDeviceTags" [value]="deviceTag">
                  {{ deviceTag.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- excluded tags -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.EXCLUDED_TAGS.LABEL' | translate }}</mat-label>
              <mat-select formControlName="excludedTags" multiple (openedChange)="onOpenMediaTag($event)">
                <input #searchMediaTag matInput type="text" (input)="onSearchMediaTag($event)" autocomplete="off">
                <mat-divider></mat-divider>
                <mat-option *ngFor="let mediaTag of filteredMediaTags" [value]="mediaTag">
                  {{ mediaTag.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- legacyScreenlabUser -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.LEGACY_USER.LABEL' | translate }}</mat-label>
              <input matInput formControlName="legacyScreenlabUser"
                [placeholder]="'DEVICE.FIELDS.LEGACY_USER.PLACEHOLDER' | translate">
            </mat-form-field>
            <!-- language -->
            <div class="device-details__language-container">
              <mat-form-field class="device-details__language-container__mat-form-field">
                <mat-label>{{ 'DEVICE.FIELDS.LANGUAGE.LABEL' | translate }}</mat-label>
                <mat-select formControlName="language">
                  <mat-option *ngFor="let locale of DeviceSupportedLanguages | enumToArray"
                    [value]="getDeviceSupportedLanguage(locale)">
                    {{ 'DEVICE.FIELDS.LANGUAGE.SELECT.' + locale | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-icon class="device-details__language-container__cancel" *ngIf="deviceForm.value.language"
                (click)="resetLocale()">close</mat-icon>
            </div>
            <!-- zoneId -->
            <mat-label class="device-details__timezone-label">
              {{ 'DEVICE.FIELDS.TIMEZONE.LABEL' | translate }}
            </mat-label>
            <div>
              <single-searchable-select class="device-details__zoneid-select" formControlName="zoneId"
                [data]="zoneIdSearchableData" [showSelectArrow]="true"
                [placeholder]="'DEVICE.FIELDS.TIMEZONE.LABEL_IN_SELECT' | translate" [stickyPlaceholder]="false">
              </single-searchable-select>
            </div>
            <!-- comment -->
            <mat-form-field>
              <mat-label>{{ 'DEVICE.FIELDS.COMMENT.LABEL' | translate }}</mat-label>
              <textarea matInput formControlName="comment"
                [placeholder]="'DEVICE.FIELDS.COMMENT.PLACEHOLDER' | translate"></textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
        <app-device-details-status class="device-details__details__block" [device]="device">
        </app-device-details-status>
      </div>
    </mat-tab>
    <mat-tab *cmnIfPerm="['scr:device-action:read']">
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.ACTIONS" | translate }}</ng-template>
      <div class="device-details__tab">
        <app-device-details-actions [device]="device"></app-device-details-actions>
      </div>
    </mat-tab>
    <mat-tab *ngIf="deviceForm.value.type == 'KIOSK'">
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.KIOSK" | translate }}</ng-template>
      <div class="device-details__tab">
        <mat-card>
          <mat-card-content>
            <app-device-kiosk [device]="device" (formGroup)="doAddKioskFormGroup($event)"></app-device-kiosk>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab *cmnIfPlugin="['svd']">
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.SVD" | translate }}</ng-template>
      <div class="device-details__tab">
        <mat-card>
          <mat-card-content>
            <app-device-svd [device]="device" (formGroup)="doAddSvdFormGroup($event)"></app-device-svd>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.REMOTE_CONTROL" | translate }}</ng-template>
      <div class="device-details__tab">
        <mat-card>
          <mat-card-content>
            <!-- type -->
            <mat-form-field appearance="outline">
              <mat-icon>add</mat-icon>
              <mat-label>{{ "DEVICE_DETAILS.REMOTE_CONTROL.TITLE" | translate }}</mat-label>
              <mat-select #remoteControlConfigTypeSelect (valueChange)="addConfig($event)">
                <mat-option value="teamviewer">
                  {{ "DEVICE_DETAILS.REMOTE_CONTROL.TEAMVIEWER" | translate }}
                </mat-option>
                <mat-option value="vpn">
                  {{ "DEVICE_DETAILS.REMOTE_CONTROL.VPN" | translate }}
                </mat-option>
                <mat-option value="windows">
                  {{ "DEVICE_DETAILS.REMOTE_CONTROL.WINDOWS" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container formArrayName="remoteControlConfigs">
              <div *ngFor="let configForm of remoteControlConfigs.controls; let i = index"
                class="device-details__remote__row">
                <ng-container [formGroup]="configForm | formControlToFormGroup" [ngSwitch]="configForm.value.type">
                  <div *ngSwitchCase="'teamviewer'" class="device-details__remote__row__form">
                    <div class="device-details__remote__row__form__title">
                      {{ "DEVICE_DETAILS.REMOTE_CONTROL.TEAMVIEWER" | translate }}
                    </div>
                    <!-- description -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION" | translate }}
                      </mat-label>
                      <input matInput formControlName="description"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION' | translate">
                    </mat-form-field>
                    <!-- id -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.ID" | translate }}
                      </mat-label>
                      <input matInput formControlName="id"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.ID' | translate" required>
                    </mat-form-field>
                    <!-- password -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD" | translate }}
                      </mat-label>
                      <input matInput formControlName="password"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD' | translate" required>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'vpn'" class="device-details__remote__row__form">
                    <div class="device-details__remote__row__form__title">
                      {{ "DEVICE_DETAILS.REMOTE_CONTROL.VPN" | translate }}
                    </div>
                    <!-- description -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION" | translate }}
                      </mat-label>
                      <input matInput formControlName="description"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION' | translate">
                    </mat-form-field>
                    <!-- hostname -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.DOMAIN_NAME" | translate }}
                      </mat-label>
                      <input matInput formControlName="hostname"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.DOMAIN_NAME' | translate" required>
                    </mat-form-field>
                    <!-- login -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.ID" | translate }}
                      </mat-label>
                      <input matInput formControlName="login"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.ID' | translate" required>
                    </mat-form-field>
                    <!-- password -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD" | translate }}
                      </mat-label>
                      <input matInput formControlName="password"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD' | translate" required>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'windows'" class="device-details__remote__row__form">
                    <div class="device-details__remote__row__form__title">
                      {{ "DEVICE_DETAILS.REMOTE_CONTROL.WINDOWS" | translate }}
                    </div>
                    <!-- description -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION" | translate }}
                      </mat-label>
                      <input matInput formControlName="description"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.DESCRIPTION' | translate">
                    </mat-form-field>
                    <!-- login -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.LOGIN" | translate }}
                      </mat-label>
                      <input matInput formControlName="login"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.LOGIN' | translate" required>
                    </mat-form-field>
                    <!-- password -->
                    <mat-form-field>
                      <mat-label>
                        {{ "DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD" | translate }}
                      </mat-label>
                      <input matInput formControlName="password"
                        [placeholder]="'DEVICE_DETAILS.REMOTE_CONTROL.PASSWORD' | translate" required>
                    </mat-form-field>
                  </div>
                  <button mat-icon-button (click)="removeConfig(i)">
                    <img src='/assets/images/screenlab/bin-icon.svg' alt="delete">
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab *ngIf="deviceForm.value.type == 'KIOSK'">
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.HARDWARE" | translate }}</ng-template>
      <div class="device-details__tab">
        <mat-card>
          <mat-card-content>
            <app-device-peripheral [device]="device" (formGroup)="doAddPeripheralFormGroup($event)">
            </app-device-peripheral>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{ "DEVICE_DETAILS.TABS.OPENING_HOURS" | translate }}</ng-template>
      <mat-card class="device-details__calendar">
        <mat-card-header class="device-details__calendar__header">
          <mat-card-title>
            {{ "OPENING_HOURS.TITLE" | translate }}
          </mat-card-title>
          <div class="device-details__calendar__header__caption">
            <mat-checkbox [(ngModel)]="useCustomerOpeningTimeSlots" [ngModelOptions]="{standalone: true}">
              {{ 'OPENING_HOURS.USE_CUSTOMER' | translate }}
            </mat-checkbox>
            <mat-icon>square</mat-icon>
          </div>
        </mat-card-header>
        <mat-card-content>
          <app-calendar #openingHoursCalendar [disabled]="useCustomerOpeningTimeSlots"
            [timeSlots]="device.openingTimeSlots!" [backgroundTimeSlots]="customerOpeningTimeSlots"></app-calendar>
        </mat-card-content>
      </mat-card>
      <mat-card class="device-details__calendar">
        <mat-card-header class="device-details__calendar__header">
          <mat-card-title>
            {{ "UPDATING_TIME_SLOTS.TITLE_DEVICE" | translate }}
          </mat-card-title>
          <div class="device-details__calendar__header__caption">
            <mat-checkbox [(ngModel)]="useUpdatingTimeSlots" [ngModelOptions]="{standalone: true}">
              {{ 'UPDATING_TIME_SLOTS.USE' | translate }}
            </mat-checkbox>
          </div>
          <div class="device-details__calendar__header__caption">
            <mat-checkbox [(ngModel)]="useCustomerUpdatingTimeSlots" [ngModelOptions]="{standalone: true}">
              {{ 'UPDATING_TIME_SLOTS.USE_CUSTOMER' | translate }}
            </mat-checkbox>
            <mat-icon>square</mat-icon>
          </div>
        </mat-card-header>
        <mat-card-content>
          <app-calendar #updatingHoursCalendar [disabled]="useCustomerUpdatingTimeSlots"
            [timeSlots]="device.updatingTimeSlots!" [backgroundTimeSlots]="customerUpdatingTimeSlots"></app-calendar>
        </mat-card-content>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</form>