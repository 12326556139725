<div id="license-status" *ngIf="!loading && licenseRequired">
  <div class="license-status-desc" *ngIf="license && licenseType && isLicenseTypeQuota">
    <div>{{ 'APPS.' + appName + '.QUOTA_DESCRIPTION' | uppercase | translate }}</div>
    <div [ngSwitch]="license.quota">
      <div *ngSwitchCase="0"><span class="ls-danger">{{"EXPIRED" | translate}}</span></div>
      <div *ngSwitchDefault>{{ license.quota }}/{{ licenseType.quota }}</div>
    </div>
  </div>
  <div class="license-status-desc" *ngIf="license && licenseType && !isLicenseTypeQuota">
    <div>{{"LICENSE.LICENSE_ACTIVE_UNTIL" | translate}} {{ license.end | date }}</div>
  </div>
  <div class="license-status-quota-outer" *ngIf="license && licenseType && isLicenseTypeQuota" [ngClass]="{
      'quota-ok': license.quota >= licenseType.quota / 2,
      'quota-warning': license.quota < licenseType.quota / 2 && license.quota >= licenseType.quota / 4,
      'quota-danger': license.quota < licenseType.quota / 4 && license.quota > 0,
      'quota-consumed': license.quota <= 0
    }">
    <div class="license-status-quota-inner">{{ license.quota }}</div>
  </div>
  <div class="ls-danger" *ngIf="!license">{{"NO_LICENSE" | translate}}</div>
  <a class="license-status-button" href="#">{{"GET" | translate}} +</a>
</div>
