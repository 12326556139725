<div class="hcl-header">
  <span class="scr-header-title">{{ 'TAG.LIST_COMPONENT.TITLE' | translate }}</span>
</div>

<div class="body">
  <div class="sidebar">
    <div class="sidebar__content">
      <div class="sidebar__actions">
        <button mat-button class="scr-button-primary" *cmnIfPerm="['scr:tag:create']" (click)="doCreate()"
          id="createButton">
          <mat-icon>add</mat-icon>
          {{ 'TAG.ACTIONS.CREATE' | translate }}
        </button>
      </div>
      <mat-divider></mat-divider>
      <form class="sidebar__filter" [formGroup]="filterForm">
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>{{ "TAG.FILTER.SEARCH" | translate }}</mat-label>
          <input type="text" matInput formControlName="search">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "TAG.FILTER.QUERY_SCOPE" | translate }}</mat-label>
          <mat-select formControlName="queryScope">
            <mat-option *ngFor="let scope of QueryScopes" [value]="scope">
              {{ "TAG.QUERY_SCOPE." + scope | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-multiselect-dropdown class="sidebar__filter__targetTypes" name="targetTypes"
          [placeholder]="'TAG.SELECT_TARGETS' | translate" [settings]="dropdownTargetTypesSettings" [data]="TargetTypes"
          formControlName="targetTypes">
        </ng-multiselect-dropdown>
        <mat-checkbox class="sidebar__filter__system" formControlName="system" *cmnIfPerm="['scr:tag:update:system']">
          {{ "TAG.SYSTEM" | translate }}
        </mat-checkbox>
      </form>
    </div>
  </div>
  <div class="content">
    <mat-table [dataSource]="tagDataSource" matSort>
      <ng-container matColumnDef="system">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let tag">
          <ng-container *ngIf="tag.system">
            <mat-icon>visibility_off</mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'NAME' | translate }}</mat-header-cell>
        <mat-cell class="content__name" *matCellDef="let tag">
          <span class="content__name__name" [matTooltip]="tag.name" [matTooltipShowDelay]="500">
            {{ tag.name }}
          </span>
          <span class="content__name__customer">{{ customerNames[tag.customerId] }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="targetTypes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TAG.TARGET_TYPES' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let tag">
          <span *ngFor="let targetType of tag.targetTypes" class="">
            {{ 'TAG.TARGET_TYPE.' + targetType | translate }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let tag">
          @if (isEditable(tag)) {
          <button *cmnIfPerm="['scr:tag:update:unrestricted']" mat-icon-button color="primary" (click)="doUpdate(tag)"
            id="{{ 'updateTagButton_' + tag.name }}">
            <mat-icon>edit</mat-icon>
          </button>
          }
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" (page)="handlePage()"></mat-paginator>
  </div>
</div>