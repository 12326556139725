import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpShowError, InvocationResult } from 'hcl-lib';
import { environment } from 'projects/hcl-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceEventsSummary } from '../../interfaces/device';
import { Granularity } from '../../interfaces/stats';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError()
  getDeviceEvents(deviceId: string, granularity: Granularity, start: string, end: string): Observable<DeviceEventsSummary> {
    const url = `${environment.statsApiBaseUrl}/stats/devices/${deviceId}/kiosk/events`
    let queryParams = new HttpParams()
      .set('granularity', granularity)
      .set('start', start)
      .set('end', end)
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
