<form>
  <div class="passwordFields">
    <mat-checkbox id="generatePassword" [(ngModel)]="generateRandomPassword" [ngModelOptions]="{standalone: true}">
      {{ 'USER_FIELDS_COMPONENT.FORM.GENERATE_RANDOM_CHECKBOX' | translate }}
    </mat-checkbox>
    <mat-form-field>
      <mat-label>{{ 'USER_FIELDS_COMPONENT.FORM.PASSWORD' | translate }}</mat-label>
      <input id="passwordInput" matInput [(ngModel)]="password" type="password" required
        [ngModelOptions]="{standalone: true}" autocomplete="off" [disabled]="generateRandomPassword">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'USER_FIELDS_COMPONENT.FORM.REPEAT_PASSWORD' | translate }}</mat-label>
      <input matInput class="confirmPasswordInput" [(ngModel)]="confirmPassword" type="password" required
        [ngModelOptions]="{standalone: true}" autocomplete="off" [disabled]="generateRandomPassword">
    </mat-form-field>
  </div>
</form>
