export interface Location {
  id?: string;
  customerId?: string;
  ancestorLocationIds?: string[];

  name: string;
  type: LocationType;
  description?: string;
  locationId?: string;
  tagIds?: string[];
}

export interface LocationHotel extends Location {
  address?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

export interface LocationFloor extends Location {
  number?: number;
}

export interface LocationRoom extends Location {
  number?: number;
  available?: boolean;
}

export interface LocationMeetingRoom extends Location {
  number?: number;
  available?: boolean;
}

export interface LocationBedroom extends Location {
  number?: number;
  available?: boolean;
}

export interface LocationCommonArea extends Location {}

export interface LocationOther extends Location {}

export enum LocationType {
  HOTEL = "HOTEL",
  FLOOR = "FLOOR",
  ROOM = "ROOM",
  MEETING_ROOM = "MEETING_ROOM",
  BEDROOM = "BEDROOM",
  COMMON_AREA = "COMMON_AREA",
  OTHER = "OTHER",
}
