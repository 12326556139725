import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material/snack-bar"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { Customer, CustomerService, KeycloakService, Role, ScopeService, Permission, CustomerNames } from "hcl-lib"
import { CustomerRolesSelect } from "projects/hcl-portal/src/app/common/interfaces/customer-roles-select"
import { User } from "projects/hcl-portal/src/app/common/interfaces/user"
import { PasswordCreateComponent } from "projects/hcl-portal/src/app/common/Layout/Components/widget/password-create/password-create.component"
import { RoleService } from "projects/hcl-portal/src/app/common/services/role/role.service"
import { PermissionService } from "projects/hcl-portal/src/app/common/services/permission/permission.service"
import { concat, from, Observable, Subscription, zip, of, pipe } from "rxjs"
import { distinct, map, mergeMap, toArray, tap, filter, last } from "rxjs/operators"
import { NgFor, NgIf } from "@angular/common"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { RecentSearchSelectComponent } from "../../recent-search-select/recent-search-select.component"
import { MatCardModule } from "@angular/material/card"
import { CustomerRolesSelectComponent } from "../../customer-roles-select/customer-roles-select.component"
import { MatButtonModule } from "@angular/material/button"
import { MatIconModule } from "@angular/material/icon"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { CustomerRoles } from "projects/hcl-portal/src/app/common/interfaces/customer-roles"

@Component({
    selector: "user-fields",
    templateUrl: "./user-fields.component.html",
    styleUrls: ["./user-fields.component.scss"],
    standalone: true,
    imports: [NgFor, NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatCardModule, MatButtonModule, MatIconModule, TranslateModule, PasswordCreateComponent, RecentSearchSelectComponent, CustomerRolesSelectComponent]
})
export class UserFieldsComponent implements OnInit, OnDestroy {

  @ViewChild(PasswordCreateComponent) passwordCreateComponent!: PasswordCreateComponent

  @Input() user!: User
  @Input() title!: string
  @Input() action!: string
  @Input() showPasswordInput = false
  @Input() showEmailInput = false
  @Input() disableEmailInput = false
  @Input() showCustomersInput = false

  roles: Role[] = []
  allPermissions: Permission[] = []

  @Output() validate: EventEmitter<User> = new EventEmitter()

  userForm!: UntypedFormGroup

  subscription: Subscription = new Subscription()
  selectCustomerPlaceholder!: string
  recentCustomers: any[] = []
  foundCustomers: any[] = []
  selectedCustomers: Customer[] = []
  customersRolesSelectData: CustomerRolesSelect[] = []
  sendEmail = false
  customer!: any

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private scopeService: ScopeService,
    private roleService: RoleService,
    private permissionService: PermissionService,
    private customerService: CustomerService,
    private snackBar: MatSnackBar,
    private keycloakService: KeycloakService
  ) {
  }

  ngOnInit(): void {
    this.selectCustomerPlaceholder = this.translateService.instant("USER_FIELDS_COMPONENT.SELECT_CUSTOMER_PLACEHOLDER")
    this.subscription.add(this.initializeUserData().subscribe())
  }

  initializeUserData(): Observable<void> {
    if (!this.user) {
      this.user = {
        id: "",
        email: "",
        firstName: "",
        lastName: "",
        customerId: this.scopeService.getScope(),
        customers: [],
        permissions: [],
        roles: [],
        phoneNumber: "",
        ownerdId: this.keycloakService.getApiUserId()
      }
    }

    this.userForm = this.formBuilder.group({
      email: new UntypedFormControl({ value: this.user.email, disabled: this.disableEmailInput }, [Validators.required, Validators.email]),
      firstName: new UntypedFormControl(this.user.firstName),
      lastName: new UntypedFormControl(this.user.lastName),
      phoneNumber: new UntypedFormControl(this.user.phoneNumber),
      customerId: new UntypedFormControl(this.user.customerId !== "" ? this.user.customerId : this.scopeService.getScope(),
      [Validators.required, Validators.nullValidator])
    })

    return zip(
        this.scopeService.getRecentCustomers(),
        this.retrieveSelectedCustomers(),
        this.roleService.getRoles(),
        this.permissionService.getPermissions(),
        this.customerService.getCustomer(this.user.customerId)
      ).pipe(
        map(([recentCustomers, selectedCustomers, roles, allPermissions, customer]) => {
          this.recentCustomers = recentCustomers
          this.selectedCustomers = selectedCustomers
          this.allPermissions = allPermissions
          if (roles) {
            this.roles = roles
            this.subscription.add(this.initializeCustomersRolesData().subscribe())
          }
          this.customer = customer
        })
      )
  }

  retrieveSelectedCustomers(): Observable<Customer[]> {
    return concat(
      from(this.user.roles as CustomerRoles[]).pipe(map(role => role.customerId)),
      from(this.user.customers)
    ).pipe(
      distinct(),
      mergeMap(customerId => this.customerService.getCustomer(customerId)),
      toArray()
    )
  }

  getRolePermissions(role: Role, allPermissions: Permission[], allRoles: Role[]): Permission[] {
    const permissions = role.permissionIds.map(permId => allPermissions.find(curPerm => permId === curPerm.id) ?? []) as Permission[]
    const roles = role.inheritedRoleIds.map(roleId => allRoles.find(curRole => roleId === curRole.id) ?? []) as Role[]

    roles.forEach(curRole => {
      permissions.push(...this.getRolePermissions(curRole, allPermissions, allRoles))
    })
    return permissions
  }

  initializeCustomersRolesData(): Observable<void> {
    this.customersRolesSelectData = []
    return from(this.user.roles as CustomerRoles[]).pipe(
      mergeMap(customerRoles => {
        const customer = this.selectedCustomers.find(customerIt => customerRoles.customerId === customerIt.id)
        return zip(of(customer), of(customerRoles.roleIds))
      }),
      filter(([customer, _]) => (!!customer)),
      mergeMap(([customer, roleIds]) => zip(of(customer), of(this.roles.filter(role => roleIds.includes(role.id))))),
      mergeMap(([customer, roles]) => zip(of(customer), of(roles), from(this.roles).pipe(
        mergeMap(role => zip(of(role), of(this.getRolePermissions(role, this.allPermissions, this.roles)))),
        mergeMap(([role, rolePermissions]) => zip(of(role), this.scopeService.hasPermissions(
          rolePermissions.map( perm => perm.value), customer?.id))),
        filter(([_, hasAllPermissions]) => hasAllPermissions),
        map(([role, _]) => role),
        toArray()
      ))),
      tap(([customer, rolesAssociatedToCustomer, filteredRoles]) => {
        if (typeof customer !== 'undefined') {
          this.customersRolesSelectData.push({ customer, rolesAssociatedToCustomer, roles: filteredRoles })
        }
      }),
      tap((_) => {
        this.user.customers.forEach(customerId => {
          if (this.user.roles?.find(customerRole => customerRole.customerId === customerId) == null) {
            const customer = this.selectedCustomers.find(customer => customer.id === customerId)
            if (typeof customer !== 'undefined') {
              this.customersRolesSelectData.push({ customer, rolesAssociatedToCustomer: [], roles: this.roles })
            }
            this.user.roles?.push({ customerId, roleIds: [] })
          }
        })
      }),
      last(),
      map(_ => {return})
    )
  }

  selectCustomer(customerNames: CustomerNames): void {
    if (typeof customerNames.id !== 'undefined' && !this.customersRolesSelectData.some(data => data.customer.id === customerNames.id)) {
      this.user.customers.push(customerNames.id)
      this.user.roles?.push({ customerId: customerNames.id, roleIds: [] })
      this.subscription.add(
        this.customerService.getCustomer(customerNames.id as string).subscribe(customer => {
          this.customersRolesSelectData.push({ customer, rolesAssociatedToCustomer: [], roles: this.roles })
        })
      )
    }
  }

  updateUserCustomer(customer: any): void {
    if (typeof customer.id !== 'undefined') {
      this.user.customerId = customer.id
      this.customer = customer
      this.userForm.controls['customerId'].setValue(this.user.customerId)
    }
  }

  removeCustomer(customer: Customer): void {
    this.customersRolesSelectData = this.customersRolesSelectData.filter(
      customerRoleWithData => customerRoleWithData.customer.id !== customer.id)
    this.user.customers = this.user.customers.filter(customerId => customerId !== customer.id)
    this.user.roles = this.user.roles?.filter(customersRole => customersRole.customerId !== customer.id)
    this.user.customers = this.user.roles?.map(customerRole => customerRole.customerId) ?? []
  }

  searchCustomer(search: string): void {
    this.subscription.add(
      this.customerService.getAvailableCustomers(0, 5, search).subscribe(result => {
        this.foundCustomers = result
      })
    )
  }

  doValidate() {
    const user: User = {
      id: this.user.id,
      customerId: this.user.customerId,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      phoneNumber: this.userForm.value.phoneNumber,
      email: this.userForm.value.email,
      ownerdId: this.user.ownerdId,
      customers: this.user.customers,
      roles: this.user.roles?.filter(role => role.roleIds.length > 0),
      permissions: this.user.permissions?.filter(perm => perm.permissionValues.length > 0)
    }
    if (this.showCustomersInput && user.customers.length === 0) {
      this.showSnackbar(this.translateService.instant("USER_FIELDS_COMPONENT.NOT_SAVED"))
    } else if (this.showPasswordInput && !this.passwordCreateComponent.isPasswordAcceptable() &&
      !this.passwordCreateComponent.isRandomGeneratedPassword()) {
      this.showSnackbar(this.translateService.instant("USER_FIELDS_COMPONENT.PASSWORDS_NOT_IDENTICAL"))
    } else {
      this.user.roles = this.user.roles?.filter(role => role.roleIds.length > 0)
      this.validate.emit(user)
    }
  }

  updateUserRole($event: CustomerRoles) {
    this.user.roles = this.user.roles?.filter(customerRoles => customerRoles.customerId !== $event.customerId)
    this.user.roles?.push($event)
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
