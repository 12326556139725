import { Component, Inject, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { CmnIfPermDirective, Tag, TargetType } from 'hcl-lib'
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'

@Component({
  selector: 'tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss'],
  standalone: true,
  imports: [CmnIfPermDirective, ReactiveFormsModule, MatFormFieldModule, MatCheckboxModule, MatInputModule, MatButtonModule, MatDialogModule, TranslateModule, NgMultiSelectDropDownModule]
})
export class TagDialogComponent implements OnInit {

  tagForm!: UntypedFormGroup

  TargetTypes = Object.keys(TargetType).map(
    targetType => ({
      id: TargetType[targetType as keyof typeof TargetType],
      name: this.translateService.instant('TAG.TARGET_TYPE.' + targetType)
    })
  )

  dropdownTargetTypesSettings: IDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: 'id',
    textField: 'name',
    enableCheckAll: false,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
  }

  constructor(
    private dialogRef: MatDialogRef<TagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.tagForm = this.formBuilder.group({
      name: [(this.data.tag || {}).name, Validators.required],
      targetTypes: [((this.data.tag || {}).targetTypes || []).map((targetType: any) => this.TargetTypes.find(x => x.id === targetType)), Validators.nullValidator],
      system: [(this.data.tag || {}).system]
    })
  }

  doSubmit() {
    const tag: Tag = {
      name: this.tagForm.value.name,
      targetTypes: this.tagForm.value.targetTypes.map((targetType: any) => targetType.id),
      system: this.tagForm.value.system
    }
    this.dialogRef.close(tag)
  }
}
