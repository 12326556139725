<mat-card>
  <mat-card-header class="device-details-actions__header">
    <mat-card-title>
      {{ "DEVICE.DETAILS_ACTION.TITLE" | translate }}
    </mat-card-title>
    <button *cmnIfPerm="['scr:device-action:update']" mat-button type="button" color="primary" (click)="doCreate()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="device-details-actions__content" *ngIf="actions">
    <ng-container *ngIf="actions.length > 0; else noActions">
      <span class="device-details-actions__content__title">
        {{ "DEVICE.DETAILS_ACTION.CURRENT_ACTIONS" | translate }}
      </span>
      <mat-table [dataSource]="actions">
        <!-- type -->
        <ng-container matColumnDef="type">
          <mat-cell *matCellDef="let action">
            <ng-container [ngSwitch]="action.type">
              <ng-container *ngSwitchCase="DeviceActionType.TUNNEL" [ngSwitch]="action.status">
                <ng-container *ngSwitchCase="TunnelDeviceActionStatus.CLOSE">
                  <span class="device-details-actions__danger-color">
                    {{ "DEVICE.DEVICE_ACTION.TUNNEL.CLOSE" | translate }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="TunnelDeviceActionStatus.OPEN">
                  <span class="device-details-actions__warning-color">
                    {{ "DEVICE.DEVICE_ACTION.TUNNEL.OPEN" | translate }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="TunnelDeviceActionStatus.OPENED">
                  <span class="device-details-actions__success-color">
                    {{ "DEVICE.DEVICE_ACTION.TUNNEL.OPENED" | translate : { port: action.port } }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span>{{ "DEVICE.DEVICE_ACTION.TYPE." + action.type | uppercase | translate }}</span>
              </ng-container>
            </ng-container>
          </mat-cell>
        </ng-container>
        <!-- actions -->
        <ng-container matColumnDef="actions">
          <mat-cell *matCellDef="let action">
            <button mat-button type="button" color="primary" (click)="doCancel(action)">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
      </mat-table>
    </ng-container>
    <ng-template #noActions>
      <span class="device-details-actions__content__title">
        {{ "DEVICE.DETAILS_ACTION.NO_ACTIONS" | translate }}
      </span>
    </ng-template>

  </mat-card-content>
</mat-card>
