<div class="contact">
  <div class="contact_info">
    <button mat-raised-button routerLink=".." class="contact_back">
      <mat-icon>arrow_back</mat-icon>{{ 'BACK' | translate }}
    </button>
    <div class="contact_info_contac-us">
      <div class="contact_info_contac-us_card">
        <img class="contact_info_contac-us_card_logo"
             src="/assets/images/icon-humecloud.svg">
        <span class="contact_info_contac-us_card_title">{{ 'CONTACT_US' | translate }}</span>
        <span class="contact_info_contac-us_card_subtitle">
          {{ 'CONTACT_US_MSG' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="contact_email">
    <form [formGroup]="emailForm" class="contact_email_form">
      <div class="contact_email_form_simple-fields">
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'COMPANY' | translate }}</mat-label>
          <input class="contact_email_form_input" id="customerInput" matInput
                 [placeholder]="'COMPANY' | translate" formControlName="customer"
                 required/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'NAME' | translate }}</mat-label>
          <input class="contact_email_form_input" id="nameInput" matInput
                 [placeholder]="'NAME' | translate" formControlName="name"
                 required/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'EMAIL.LABEL' | translate }}</mat-label>
          <input class="contact_email_form_input" id="emailInput" matInput
                 [placeholder]="'EMAIL.LABEL' | translate" formControlName="email"
                 required/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'PHONE' | translate }}</mat-label>
          <input class="contact_email_form_input" id="phoneNumberInput" matInput
                 [placeholder]="'PHONE' | translate" formControlName="phoneNumber"/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'PRODUCT_SERIAL_NUMBER' | translate }}</mat-label>
          <input class="contact_email_form_input" matInput
                 [placeholder]="'PRODUCT_SERIAL_NUMBER' | translate"
                 formControlName="serialNumber"/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'BOUGHT_DATE' | translate }}</mat-label>
          <input class="contact_email_form_input" matInput [placeholder]="'BOUGHT_DATE' | translate"
                 formControlName="boughtDate"/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'CUSTOMER_DEMAND.TYPE' | translate }}</mat-label>
          <mat-select class="contact_email_form_input" formControlName="subjectType" required>
            <mat-option value="support">{{ 'CUSTOMER_DEMAND.SUPPORT' | translate }}</mat-option>
            <mat-option value="commercial">{{ 'CUSTOMER_DEMAND.SALES' | translate }}</mat-option>
            <mat-option value="creation">{{ 'CUSTOMER_DEMAND.CREATION' | translate }}</mat-option>
            <mat-option value="other">{{ 'CUSTOMER_DEMAND.OTHER' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'OBJECT' | translate }}</mat-label>
          <input class="contact_email_form_input" matInput [placeholder]="'OBJECT' | translate"
                 formControlName="subject"
                 required/>
        </mat-form-field>
        <mat-form-field class="contact_email_form_field contact_email_form_big-field" floatLabel="always">
          <mat-label class="contact_email_form_label">{{ 'DESCRIPTION' | translate }}</mat-label>
          <textarea class="contact_email_form_input contact_email_form_big-text-input" matInput
                    [placeholder]="'DESCRIPTION' | translate" formControlName="description"
                    required>
          </textarea>
        </mat-form-field>
      </div>
      <div class="contact_email_form_submit-fields">
        <button mat-raised-button color="accent" class="contact_email_form_send-btn"
                [disabled]="!emailForm.valid"
                (click)="sendMessage()"
                >
          <mat-icon>email</mat-icon>
          {{ 'SEND' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
